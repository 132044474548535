import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { services } from "../services";
import { computeOrderSummary } from "../utils/responseUtils";
import { KeyValuePairs } from "../types";

interface Order {
	id: string;
	buy_shares: number;
	sell_shares: number;
	buy_amount_in_millions: number;
	sell_amount_in_millions: number;
	buy_percentage: number;
	sell_percentage: number;
}

interface OrderSummary {
	total_buy_shares: number;
	total_sell_shares: number;
	total_buy_amount_in_millions: number;
	total_sell_amount_in_millions: number;
	total_buy_pct: number;
	total_sell_pct: number;
	orderListArray: string[];
}

interface OrderState {
	data: KeyValuePairs[] | null;
	summary: OrderSummary | null;
	loading: boolean;
	error: string | null;
}

const initialState: OrderState = {
	data: null,
	summary: null,
	loading: false,
	error: null,
};

export const fetchOrderData = createAsyncThunk<
	OrderState,
	{
		fund_id: string;
		target_date: string;
		target_mode: string;
		calc_date: string;
		fund_state?: string;
		transaction_id?: string;
	}
>(
	"orders/fetchOrderData",
	async (
		{ fund_id, target_date, target_mode, calc_date, fund_state, transaction_id },
		{ rejectWithValue },
	) => {
		try {
			const response = await axios.get(services.ORDER_LIST, {
				params: {
					fund_id,
					target_date,
					target_mode,
					calc_date,
					...(fund_state === "Completed"
						? { order_list_status: "ORDER_LIST_STATUS_APPROVED", transaction_id }
						: {}),
				},
			});

			const orderData = response.data.data;

			if (orderData && orderData.length > 0) {
				const summary = computeOrderSummary(response);

				return { data: orderData, summary, loading: false, error: null };
			}
			return rejectWithValue("NO_DATA_FOUND");
		} catch (error: any) {
			const status = error.response?.status ?? "Unknown Error";
			return rejectWithValue(status);
		}
	},
);

const orderSlice = createSlice({
	name: "orders",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderData.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchOrderData.fulfilled, (state, action) => {
				state.loading = false;
				state.data = action.payload.data;
				state.summary = action.payload.summary;
			})
			.addCase(fetchOrderData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
			});
	},
});

export const orderReducer = orderSlice.reducer;
