import React, { useEffect } from "react";
import { AgTableComponent } from "../Agtablehomepage/AgTableComponent";
import ErrorMessage from "../Common/ErrorMessage";
import TabsComponent from "../Common/TabsComponent";

interface FundsTabProps {
	t: (key: string) => string;
	selectedMarketState: string;
	setSelectedMarketState: (state: string) => void;
	computedCounts: { marketOnOpenCount: number; marketOnCloseCount: number };
	fundsData: any[];
	fundsError: string | null;
	fetchDataForTable: any
}

const FundsTab: React.FC<FundsTabProps> = ({
	t,
	selectedMarketState,
	setSelectedMarketState,
	computedCounts,
	fundsData,
	fundsError,
	fetchDataForTable
}) => {
	const tabs = [
		computedCounts.marketOnOpenCount > 0 && {
			label: `${t("MARKET_ON_OPEN")} (${computedCounts.marketOnOpenCount})`,
			value: "MARKET_ON_OPEN",
		},
		{
			label: `${t("MARKET_ON_CLOSE")} (${computedCounts.marketOnCloseCount})`,
			value: "MARKET_ON_CLOSE",
		},
	].filter(Boolean) as { label: string; value: string }[];

	useEffect(() => {
		if (selectedMarketState === "MARKET_ON_OPEN" && computedCounts.marketOnOpenCount === 0) {
			setSelectedMarketState("MARKET_ON_CLOSE");
		}
	}, [selectedMarketState, computedCounts.marketOnOpenCount, setSelectedMarketState]);

	return (
		<>
			<TabsComponent
				value={selectedMarketState}
				onChange={(value) => setSelectedMarketState(value || "MARKET_ON_CLOSE")}
				tabs={tabs}
			/>

			<div className='main-content-all-fund-status'>
				{fundsError ? (
					<ErrorMessage errorMessage={fundsError} />
				) : (
					<AgTableComponent rowData={fundsData || []} target_modes={selectedMarketState} fetchDataForTable={fetchDataForTable}/>
				)}
			</div>
		</>
	);
};

export default FundsTab;
