import React from 'react';
import { Select } from '@mantine/core';
import { rightSection } from './AssetUtils';


interface AssetsSelectFieldsProps {
  label: string;
  data: string[];
  value: string | undefined | null;
  onChange: (value: string | null) => void;
  className?: string;
  searchable?: boolean;
  disabled?: boolean;
  onSearchChange?: (query: string) => void;
}

const AssetsSelectFields: React.FC<AssetsSelectFieldsProps> = ({
  label,
  data,
  value,
  onChange,
  searchable = false,
  disabled = false,
}) => {
  return (
    <Select
      label={label}
      data={data}
      value={value}
      onChange={onChange}
      searchable={searchable}
      disabled={disabled}
      className="asset-input"
      rightSection={rightSection}
      comboboxProps={{ offset: 0 }}
      checkIconPosition="right"
      rightSectionPointerEvents="none"
    />
  );
};

export default AssetsSelectFields;