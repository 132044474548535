import React, { useEffect, useState } from "react";
import { Box, Text } from "@mantine/core";
import IconWrapper from "../../components/Common/IconWrapper";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../types";
import { t } from "i18next";
import ReactMarkdown from "react-markdown";

export const ChatBubble: React.FC<{ message: { user: string; text: any; id: string } }> = ({
	message,
}) => {
	const { symbol } = useParams<RouteParams>();
	const [isFirstMessage, setIsFirstMessage] = useState(true);

	useEffect(() => {
		if (isFirstMessage) {
			setIsFirstMessage(false);
		}
	}, [message]);

	const renderContent = (data: any) => {
		if (data) {
			return (
				<Box mb='lg'>
					<ReactMarkdown>{data}</ReactMarkdown>
				</Box>
			);
		}
		return <Text>{t("NO_DATA_ERROR")}</Text>;
	};

	return (
		<Box className={`chat-bubble ${message.user}`} ta='left'>
			{message.user === "assistant" && <IconWrapper name='Logo-icon' className='ai-logo' />}
			<Box className='message'>
				{isFirstMessage && message.user === "user" ? (
					<Text>{symbol}</Text>
				) : message.user === "assistant" ? (
					renderContent(message.text)
				) : (
					<Text>{message.text}</Text>
				)}
			</Box>
		</Box>
	);
};
