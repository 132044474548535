import { MismatchData } from "../../../types";

export const formatMismatchData = (data: MismatchData[]): any[] => {
    return data.map((item: MismatchData) => {
        const { work_item_status, ...rest } = item;
        return {
            id: item.id,
            fund_id: item.alert?.alert_detail?.fund_id || '',
            fund_name: item.alert?.alert_detail?.fund_name || '',
            work_item_status: work_item_status || 'Unknown Status',
            asset_name: item.alert?.alert_detail?.asset_name || '',
            benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
            asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
            our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
            custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
            explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
            unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
            recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
            start_date_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.start_date || item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.start_date,
            end_date_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.end_date || item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.end_date,
            apply_to_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs
                ? 'Atlantis'
                : item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs
                    ? 'Custodian'
                    : '',
            ...rest,
        };
    });
};
