import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { defaultColDef, rowHeight } from '../../../components/CustomTable/utilsAgTable';
import { Box } from '@mantine/core';
import { getColumnDefs } from '../../../components/CustomTable/columnDefs';
import { services } from '../../../services';
import { LoaderIcon } from '../../../components/Common/LoaderIcon';

const Attributes: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetching API data
  useEffect(() => {
    axios.get(services.FUND_ATTRIBUTES)
      .then((response) => {
        setRowData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <> {loading ? (
      <Box h='90vh'>
        <LoaderIcon name='loading-large' alt='loading icon' />
      </Box>
    ) : (
      <Box className='fund-table'>
        <CustomTable rowData={rowData} columnDefs={getColumnDefs('ATTRIBUTES')} rowHeight={rowHeight} defaultColDef={defaultColDef} className='fund-query' emptyMsg='NO_FUND_ATTRIBUTES' />
      </Box>
    )}
    </>

  );
};

export default Attributes;
