import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "../Agtablehomepage/Agstyles.css";
import "./PositionSummary.css";
import { useTranslation } from "react-i18next";
import { PositionSummaryTableData } from "../../types";
import { getTableData } from "../../utils/conversions";
import CustomTable from "../CustomTable/CustomTable";
import { Box } from "@mantine/core";

export const PositionTable = ({ data, TITLE }: { data: PositionSummaryTableData[], TITLE: string }) => {
    const { t, i18n } = useTranslation();
    const tableData = getTableData("POSITION");

    const colDefs: ColDef[] = [
        ...tableData.map(({ colHeader, key, decorators }) => ({
            headerName: t(colHeader),
            field: key,
            cellRenderer: null,
        })),
    ]

    return (
        <Box w="100%"> 
            <CustomTable rowData={data.map((row, index) => ({ index: index + 1, ...row }))} columnDefs={colDefs} className="fund-query"/>
        </Box>
    );
};
