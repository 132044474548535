import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Flex, Text } from '@mantine/core';
import CustomButton from '../components/Common/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../components/Common/PageTitle';
import { t } from 'i18next';
import { ReconResultTypes, TableName } from '../constants';
import { services } from '../services';
import { MismatchData } from '../types';
import {LoaderIcon} from '../components/Common/LoaderIcon';
import SodReconTableComplete from '../components/SODRecon/SodReconTableComplete';

const SODRecon: React.FC = () => {
    const [COACTableData, setCOACTableData] = useState<MismatchData[]>([]);
    const [OrderTableData, setOrderTableData] = useState<MismatchData[]>([]);
    const [OthersTableData, setOthersTableData] = useState<MismatchData[]>([]);
    const [selectedRows, setSelectedRows] = useState<MismatchData[]>([]);
    const [loading, setLoading] = useState({ COAC: true, Order: true, Others: true });
    const [isPollingPaused, setIsPollingPaused] = useState(false);
    const { fund_group } = useParams();
    const navigate = useNavigate();

    const fetchData = async (
        resultType: string,
        setData: React.Dispatch<React.SetStateAction<MismatchData[]>>,
        loadingKey: keyof typeof loading,
        isPolling: boolean = false
    ) => {
        if (!isPolling) {
            setLoading(prev => ({ ...prev, [loadingKey]: true }));
        }

        try {
            const response = await axios.get(
                `${services.FUND_HOLDINGS}?fund_recon_result_type=${resultType}&fund_group=${fund_group}`
            );

            const formattedData = response.data.data.map((item: MismatchData) => {
                const { work_item_status, ...rest } = item;
                return {
                    id: item._id,
                    fund_id: item.alert?.alert_detail?.fund_id || '',
                    fund_name: item.alert?.alert_detail?.fund_name || '',
                    work_item_status: work_item_status || 'Unknown Status',
                    asset_name: item.alert?.alert_detail?.asset_name || '',
                    benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
                    asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
                    our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
                    custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
                    explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
                    unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
                    recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
                    start_date_atlantis: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.start_date,
                    end_date_atlantis: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.end_date,
                    start_date_custodian: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.start_date,
                    end_date_custodian: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.end_date,
                    ...rest,
                };
            });

            setData((prevData) => {
                const updatedData = prevData.map((item) => {
                    const newItem = formattedData.find((newItem: { id: string | number }) => newItem.id === item.id);
                    return newItem ? { ...item, ...newItem } : item;
                });
                const newItems = formattedData.filter((newItem: { id: string | number }) =>
                    !prevData.some((item) => item.id === newItem.id)
                );
                return [...updatedData, ...newItems];
            });
        } catch (error) {
            console.error(`Error fetching data for result type: ${resultType}`, error);
        } finally {
            if (!isPolling) {
                setLoading(prev => ({ ...prev, [loadingKey]: false }));
            }
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchAllData = (isPolling = false) => {
            if (isMounted && !isPollingPaused) {
                fetchData(ReconResultTypes.HOLDINGS_COAC_MISMATCH, setCOACTableData, 'COAC', isPolling);
                fetchData(ReconResultTypes.HOLDINGS_ORDERS_MISMATCH, setOrderTableData, 'Order', isPolling);
                fetchData(ReconResultTypes.HOLDINGS_OTHERS_MISMATCH, setOthersTableData, 'Others', isPolling);
            }
        };

        fetchAllData();
        const intervalId = setInterval(() => fetchAllData(true), 10000);

        return () => {
            isMounted = false;
            clearInterval(intervalId);
        };
    }, [isPollingPaused]);

    const allTablesLoaded = !Object.values(loading).some((isLoading) => isLoading);

    const handleCreateAdjustment = () => {
        if (selectedRows.length > 0) {
            const selectedAssetId = selectedRows[0]?.asset_id;
            const fundId = selectedRows[0]?.fund_id;
            const resultTypeMap = {
                'COAC': 'FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH',
                'Order': 'FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH',
                'Others': 'FUND_RECON_RESULT_TYPE_HOLDINGS_OTHERS_MISMATCH',
            };

            const resultType =
                COACTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['COAC'] :
                    OrderTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['Order'] :
                        OthersTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['Others'] : '';


            if (selectedAssetId) {
                navigate(`/adjustment/${selectedAssetId}/${fund_group}/${resultType}/${fundId}`, { state: { selectedRows } });
            } else {
                console.error('Asset ID not found in selected row');
            }
        } else {
            console.error('No rows selected');
        }
    };

    const handleIgnore = async () => {
        if (selectedRows.length === 0) {
            console.error('No rows selected to ignore');
            return;
        }

        const allData = [...COACTableData, ...OrderTableData, ...OthersTableData];
        const rowsToSend = selectedRows.map((selectedRow) => {
            const row = allData.find(item => item.id === selectedRow.id);
            return row ? {
                id: row.id,
            } : null;
        }).filter((item) => item !== null);

        try {
            await axios.post('/api/work-items/no-issue', rowsToSend);
            fetchData(ReconResultTypes.HOLDINGS_COAC_MISMATCH, setCOACTableData, 'COAC');
            fetchData(ReconResultTypes.HOLDINGS_ORDERS_MISMATCH, setOrderTableData, 'Order');
            fetchData(ReconResultTypes.HOLDINGS_OTHERS_MISMATCH, setOthersTableData, 'Others');
        } catch (error) {
            console.error('Error ignoring work items:', error);
        }
    };

    const handleSelectionChange = (selected: MismatchData[], tableName: TableName) => {
        setSelectedRows(selected);
        setIsPollingPaused(selected.length > 0);
    };

    const buttonDisable = selectedRows.length > 0 ? false : true

    return (
        <Box>
            <PageTitle TITLE={t('SOD_RECON')} subtitle="HOLDING_CHECK" back full_report="FULL_REPORT" />
                <Flex direction="column" gap="sm" p="2rem" >
                    {allTablesLoaded ? (
                        <>
                            <Flex justify="flex-end">
                                <CustomButton variant={selectedRows.length > 0 ? "discard" : "discard-disable"} onClick={handleIgnore} disabled={buttonDisable}>
                                    {t('IGNORE')}
                                </CustomButton>
                                <CustomButton variant={selectedRows.length > 0 ? "create-workitems" : "create-workitems-disable"} onClick={handleCreateAdjustment} disabled={buttonDisable}>
                                    {t('CREATE_ADJUSTMENT')}
                                </CustomButton>
                            </Flex>

                            <Text size="lg" fw="600">
                                {t('COAC_RELATED')} ({COACTableData.length || 0})
                            </Text>
                            <SodReconTableComplete data={COACTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'COACTable')} allowMultipleSelection={false} resultType={ReconResultTypes.HOLDINGS_COAC_MISMATCH} withoutAdditionalRows />

                            <Text size="lg" fw="600" mt="xl">
                                {t('ORDER_RELATED')} ({OrderTableData.length || 0})
                            </Text>
                            <SodReconTableComplete data={OrderTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'OrderTable')} allowMultipleSelection={true} resultType={ReconResultTypes.HOLDINGS_ORDERS_MISMATCH} withoutAdditionalRows />

                            <Text size="lg" fw="600" mt="xl">
                                {t('OTHERS')} ({OthersTableData.length || 0})
                            </Text>
                            <SodReconTableComplete data={OthersTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'OthersTable')} allowMultipleSelection={true} resultType={ReconResultTypes.HOLDINGS_OTHERS_MISMATCH} withoutAdditionalRows />
                        </>
                    ) : (
                        <Box h={'80vh'}><LoaderIcon name='loading-large' alt='loading icon' /></Box>
                    )}
                </Flex>
            </Box>
    );
};

export default SODRecon;
