import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { t } from "i18next";
import { Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import AdminTableHeader from "../../../../components/AdminPage/AdminTableHeader";
import { routes } from "../../../../routes";
import { services } from "../../../../services";
import { FundPayload } from "../../../../types";
import { getTableData } from "../../../../utils/conversions";
import '../Investment.css';
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { defaultColDef, rowHeight, rowSelectionTypeSingle } from "../../../../components/CustomTable/utilsAgTable";
import { LoaderIcon } from "../../../../components/Common/LoaderIcon";
import { DeleteModal } from "../../../../components/Common/Modals/DeleteModal";

const FundsInvestment: React.FC = () => {
	const [rowData, setRowData] = useState<FundPayload[]>([]);
	const [loading, setLoading] = useState(true);
	const [editRowData, setEditRowData] = useState({});
	const [opened, setOpened] = useState(false);
	const navigate = useNavigate();

	const navigateToCreateFundPage = () => {
		navigate(routes.CREATE_FUND);
	};
	const handleRowSelection = (event: any) => {
		setEditRowData(() => event.api.getSelectedRows()[0]);
	};
	const handleEditFund = (editRowData: FundPayload) => {
		navigate(`${routes.CREATE_FUND}?fundId=${editRowData.fund_id}`);
	};
	const handleDeleteConfirm = async (editRowData: FundPayload) => {
		try {
			const response = await axios.delete(`${services.FUND_ATTRIBUTES}/${editRowData.fund_id}`);

			if (response.status === 200) {
				console.log("Fund deleted successfully");
				setOpened(false)
				fetchData();
			}
		} catch (error) {
			console.error('Error deleting fund attribute:', error);
		}
	};

	const fetchData = useCallback(async() => {
		try {
			setLoading(true)
			const response = await axios.get(services.FUND_ATTRIBUTES);
			setRowData(response.data.data)
		} catch (error) {
			console.error("Error fetching data:", error);			
		} finally {
			setLoading(false)
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const columnDefs: ColDef[] = useMemo(() => {
		const tableData = getTableData("FundsInvestment");
		const columns = [
			{
				headerName: t("SR_NO"),
				field: "srNo",
				width: 100,
				valueGetter: (params: ValueGetterParams) =>
					params.node?.rowIndex != null ? params.node.rowIndex + 1 : "",
				cellStyle: { textAlign: "center" },
			},
			...tableData.map(({ colHeader, key, decorators }) => ({
				headerName: t(colHeader),
				field: key,
				valueFormatter: (params: ValueFormatterParams) => {
					if (key === "forty_act_fund") {
						return params.value === true ? "Y" : "N";
					}
					return params.value;
				},
				cellRenderer: null,
			})),
		];
		return columns;
	}, []);

	const handleDeleteClick = () => {
		if (editRowData) {
			setOpened(true);
		}
	};


	return (
		<Box>
			<Box p={"lg"} mr={"-1.7rem"}>
				<AdminTableHeader
					isRowSelected={Object.keys(editRowData).length > 0}
					headerTitle='show_search'
					createTitle='CREATE_A_FUND'
					handleEditUser={() => handleEditFund(editRowData as FundPayload)}
					handleDeleteClick={handleDeleteClick}
					resetCreateUser={() => navigateToCreateFundPage()}
				/>
			</Box>
			{loading ? (
				<Box h={'80vh'}><LoaderIcon name={"loading-large"} alt={"loading icon"} /></Box>
			) : (
				<div>
					<CustomTable rowData={rowData} columnDefs={columnDefs} rowHeight={rowHeight} rowSelection={rowSelectionTypeSingle} onSelectionChanged={(event) => handleRowSelection(event)} defaultColDef={defaultColDef} className="fund-query"/>

					<DeleteModal
						opened={opened}
						onClose={() => setOpened(false)}
						TITLE="DELETE_FUND"
						message="DELETE_FUND_MESSAGE"
						onSubmit={() => handleDeleteConfirm(editRowData as FundPayload)}
					/>
				</div>
			)}
		</Box>
	);
};

export default FundsInvestment;
