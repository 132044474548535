import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { jwtDecode } from "jwt-decode";
import { I18nextProvider, useTranslation } from "react-i18next";
import { useAppDispatch } from "./store/hooks";
import { setUser } from "./store/userSlice";
import theme from "./constants/theme";
import { getConfig } from "./utils/conversions";
import axios from "./axios/axiosConfig";
import AppRoutes from "./routes/AppRoutes";
import InactivityWrapper from "./components/InactivityWrapper";
import { handleLogout } from "./utils/handleLogOut";
import { inactivityInterval } from "./utils/authConstants";

const token = localStorage.getItem("athena_A");

const App: React.FC = () => {
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const decodedToken: any = token ? jwtDecode(token) : null;
	const fundQueryConfig = getConfig("fund_query");
	const isFundQueryOption1 = +(fundQueryConfig?.DISPLAY_OPTION?.value || 1) === 1;

	useEffect(() => {
		const language = sessionStorage.getItem("lang");
		if (language) i18n.changeLanguage(language);
	}, [])

	useEffect(() => {
		if (token) {
			dispatch(
				setUser({
					username: decodedToken?.preferred_username,
					givenName: decodedToken?.given_name,
					fullName: decodedToken?.name,
					roles: decodedToken?.permissions[0].role,
				}),
			);
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
		} 
	}, []);

	return (
		<MantineProvider defaultColorScheme='light' theme={theme}>
			<I18nextProvider i18n={i18n}>
				<InactivityWrapper onTimeout={handleLogout} timeout={inactivityInterval}>
				<React.StrictMode>
					<Router>
						<AppRoutes decodedToken={decodedToken} isFundQueryOption1={isFundQueryOption1} />
					</Router>
					</React.StrictMode>
				</InactivityWrapper>

			</I18nextProvider>
		</MantineProvider>
	);
};

export default App;
