import React from "react";
import { Button, Text, useMantineTheme } from "@mantine/core";

interface OrderButtonProps {
	buttonText: string;
	isButtonDisabled: boolean;
	handleButtonClick: () => void;
	t: (key: string) => string; // Translation function, adjust if needed
}

const OrderButton: React.FC<OrderButtonProps> = ({
	buttonText,
	isButtonDisabled,
	handleButtonClick,
	t,
}) => {
	const theme = useMantineTheme();

	const variant = buttonText === "I_M_STARTING" && isButtonDisabled ? "default" : "filled";
	const bgColor =
		buttonText === "I_M_STARTING" && !isButtonDisabled
			? theme.colors.violet[2]
			: isButtonDisabled
			? theme.colors.yellow[0]
			: theme.colors.violet[8];

	const borderColor =
		buttonText === "I_M_STARTING" && !isButtonDisabled
			? theme.colors.violet[8]
			: theme.colors.gray[3];

	const boxShadow =
		buttonText === "I_M_STARTING" && !isButtonDisabled
			? `0 0 1.2px ${theme.colors.violet[8]}`
			: `0 0 1.2px ${theme.colors.gray[3]}`;

	const textColor =
		buttonText === "I_M_STARTING" && !isButtonDisabled
			? theme.colors.violet[8]
			: buttonText === "CANNOT_APPROVE" || isButtonDisabled
			? theme.colors.gray[7]
			: theme.colors.yellow[0];

	return (
		<Button
			variant={variant}
			bg={bgColor}
			className='approvebutton'
			onClick={handleButtonClick}
			disabled={buttonText === "CANNOT_APPROVE" || isButtonDisabled}
			style={{
				border: `0.5px solid ${borderColor}`,
				boxShadow,
			}}
		>
			<Text c={textColor} fw={600}>
				{t(buttonText)}
			</Text>
		</Button>
	);
};

export default OrderButton;
