import React from "react";
import { TextInput, Box, Flex } from "@mantine/core";
import { IconPaperclip} from "@tabler/icons-react";
import { t } from "i18next";

interface ChatTextInputProps {
	input: string;
	setInput: (value: string) => void;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	onSendMessage?: () => void;
	disabled?: boolean;
}

export const ChatTextInput: React.FC<ChatTextInputProps> = ({
	input,
	setInput,
	handleKeyPress,
	onSendMessage,
	disabled = false,
}) => {
	return (
		<Box>
			<Box className='text-input'>
				<TextInput
					type='text'
					placeholder={t("MESSAGE_ATLANTIS_AI")}
					value={input}
					onChange={(e) => setInput(e.target.value)}
					onKeyDown={handleKeyPress}
					className='chat-input'
				/>
				<Flex justify='space-between' pl={5}>
					<IconPaperclip size='1rem' />
				</Flex>
			</Box>
		</Box>
	);
};
