import React from "react";
import { Grid } from "@mantine/core";
import SidebarItem from "../Leftnavbar/SidebarItem";
import styles from "./AIAssistant.module.css";
import { t } from "i18next";

interface AIChatNavControlsProps {
	selectedItem: string;
	onNavItemClick: (label: string) => void;
}

const NAV_ITEMS = [
	{ label: t("NEW_CHAT"), id: "new chat", icon: "new-chat-icon" },
	{ label: t("EXPLORE"), id: "explore", icon: "explore-icon 2" },
] as const;

export const AIChatNavControls: React.FC<AIChatNavControlsProps> = ({
	selectedItem,
	onNavItemClick,
}) => {
	return (
		<Grid.Col className={styles.navigationSection}>
			{NAV_ITEMS.map(({ label, id, icon }) => (
				<SidebarItem
					key={id}
					icon={icon} 
					label={label}
					path=''
					isSelected={selectedItem === id}
					onClick={() => onNavItemClick(label)}
				/>
			))}
		</Grid.Col>
	);
};
