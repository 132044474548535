import React from "react";
import { Grid } from "@mantine/core";
import { ChatSection } from "./ChatSection";
import { useAppSelector } from "../../store/hooks";
import { categorizeChats } from "../../utils/chatUtils";
import { CategorizedChats, ChatHistoryItem } from "../../types";

interface AIChatHistoryProps {
 categorizedChats: CategorizedChats; 

 selectedItem: string;
 onChatSelect: (label: string, id: string) => void;
 searchQuery: string;
}

export const AIChatHistory: React.FC<AIChatHistoryProps> = ({
 
 selectedItem,
 onChatSelect,
 searchQuery,
}) => {
 const { chatHistory } = useAppSelector((state) => state.chat);

 const categorizedChats = categorizeChats(chatHistory);

 const filterChats = (chats: ChatHistoryItem[]) =>
  searchQuery
   ? chats.filter((chat) => (chat.label || "").toLowerCase().includes(searchQuery.toLowerCase()))
   : chats;

 return (
  <Grid.Col span={10} offset={1}>
   {[
    { title: "Today", chats: filterChats(categorizedChats.today) },
    { title: "Yesterday", chats: filterChats(categorizedChats.yesterday) },
    { title: "Last 7 Days", chats: filterChats(categorizedChats.last7Days) },
   ].map(({ title, chats }) => (
    <ChatSection
     key={title}
     title={title}
     chats={chats}
     selectedItem={selectedItem}
     onChatSelect={onChatSelect}
    />
   ))}
  </Grid.Col>
 );
};
