import React from "react";
import { Flex, Text, Box } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type PageHeaderProps = {
	TITLE?: string;
	subtitle?: string;
	subtitle2?: string;
	subtitle3?: string;
	back?: boolean;
	fund_id?: string;
	fund_state?: string;
	target_date?: string;
	target_mode?: string;
};

const PageHeader = ({
	TITLE,
	subtitle,
	subtitle2,
	subtitle3,
	back = false,
	fund_id,
	fund_state,
	target_date,
	target_mode,
}: PageHeaderProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation(); // Ensure translation function is available

	return (
		<Flex align='center'>
			{back && <IconChevronLeft color='gray' onClick={() => navigate(-1)} cursor='pointer' />}
			<Flex ml='1.25rem' flex='1'>
				<Text fw='700' size='1.25rem'>
					{TITLE && t(TITLE)} {/* Apply translation here */}
				</Text>
				{subtitle && (
					<Text span ml='1rem' color='gray' fw='700' size='1rem'>
						{t(subtitle)} {/* Translate subtitle */}
					</Text>
				)}
				{subtitle2 && (
					<Text span ml='1rem' color='gray' fw='700' size='1rem'>
						{t(subtitle2)} {/* Translate subtitle2 */}
					</Text>
				)}
				{subtitle3 && (
					<Text span ml='1rem' color='gray' fw='700' size='1rem'>
						{t(subtitle3)} {/* Translate subtitle3 */}
					</Text>
				)}
			</Flex>

			{TITLE === fund_id && (
				<Flex ml='0.9375rem'>
					<Text pr='0.6875rem' fw='520' size='0.9375rem' color='gray'>
						{target_mode &&
							target_mode
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								.join(" ")}
					</Text>
					<Box h='md' w='1px' bg='gray' mr='0.625rem' />
					<Text pr='0.625rem' fw='520' size='0.9375rem' color='gray'>
						{fund_state}
					</Text>
					<Box h='md' w='1px' bg='gray' mr='0.625rem' />
					<Text pr='sm' fw='520' size='0.9375rem' color='gray'>
						{target_date}
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default PageHeader;
