import React from "react";
import { Badge, Text, BadgeProps } from "@mantine/core";

interface CustomBadgeProps extends Omit<BadgeProps, "children"> {
	count: number; 
	id?: string
}

const CustomBadge: React.FC<CustomBadgeProps> = ({ id, count, className, ...props }) => {
	return (
		<Badge
			className={`${className}`} 
			size={props.size || "md"} 
			bg='transparent' 
			c={props.c || "#651fff"} 
			{...props}
		>
			<Text fw={600} size='11px'>
				{count > 0 ? count : "0"}
			</Text>
		</Badge>
	);
};

export default CustomBadge;
