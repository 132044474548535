import { createTheme } from '@mantine/core';

const theme = createTheme({
	colors: {
		grey: [
			"#F0F0F0", // 4
			"#FAFAFA", // 20
			"#F7F9F9", // 21
			"#EEEEEE", // 22 light grey
			"#EBEBEB", // 7
			"#7D7D7D", // 3 & 8     5
			"#848484", // 11
			"#B4B4B4", // 19
			"#5b5b5b", // 8
			"#cecece", // 5
			"#E0E0E0", //normally 10
			"#f7f9f9",
		],

		violet: [
			"#EEE7FC", // light purple 6
			"#ece3ff80", //I_M_STARTING BUTTON COLOR
			"#F9F7FF",
			"#DDA0DD",
			"#9370DB",
			"#D8BFD8",
			"#DED0FF", // mid purple 15
			"#5d25f5",
			"#651FFF", // 0
			"#D1c5f4", //13
		],

		green: [
			"#ACEDB6", // 1
			"#98FB98",
			"#90EE90",
			"#7CFC00",
			"#7FFF00",
			"#00FA9A",
			"#ADFF2F",
			"#32CD32",
			"#00FF7F",
			"#008000",
		],

		red: [
			"#FFB4B0", // 2
			"#ffb4b080",
			"#F08080",
			"#E9967A",
			"#FF6347",
			"#FF7F50",
			"#FF4500",
			"#CD5C5C",
			"#DC143C",
			"#ff0000", // 18
		],

		yellow: [
			"#FFFFFF", //white
			"#FFFFFFE8", // 14
			"#FFFF00",
			"#FFFFE0",
			"#FFFACD",
			"#FAFAD2",
			"#FFD700",
			"#FFEF00",
			"#F0E68C",
			"#FFDAB9",
			"#FFE4B5",
		],
	},
	spacing: {
		negativeXss: "-0.1rem",
		negativeXs: "-0.3rem",
		negativeSm: "-0.5rem",
		negativeMd: "-0.6rem",
		negativeXl: "-1rem",
		xxs: "0.1rem",
		xs: "0.6rem",
		sm: "0.813rem",
		md: "1rem",
		lg: "1.3rem",
		xl: "1.5rem",
		xxl: "1.75rem",
		xxxl: "2rem",
	},
	fontSizes: {
		xxs: "0.3125rem",
		xs: "0.68rem",
		sm: "0.7rem",
		md: "0.8125rem",
		lg: "1rem",
		xl: "1.25rem",
	},
	components: {
		Checkbox: {
			styles: (theme: any) => ({
				input: {
					backgroundColor: "transparent",
					borderColor: "#000000d9",
					"&:checked": {
						backgroundColor: "transparent",
						borderColor: "#000000d9",
						color: "#000000d9",
					},
					svg: {
						fill: "#000000", 
					},
				},
			}),
		},
	},
});

export default theme;
