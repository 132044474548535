import React from "react";
import { Box } from "@mantine/core";
import AccountInfo from "../../pages/Account/AccountInfo";
import SystemConfig from "../../pages/Account/SystemConfig";
import TableConfig from "../../pages/Account/TableConfig";
import Config from "../../pages/Account/FundConfig/Config";

interface ContentAreaProps {
	activeIndex: number;
}

const ContentArea: React.FC<ContentAreaProps> = ({ activeIndex }) => {
	const renderContent = () => {
		switch (activeIndex) {
			case 0:
				return <Config />;
			case 1:
				return <TableConfig />;
			case 2:
				return <AccountInfo />;
      /********   Temp Commenting, Dont delete **********/
			// case 3: 
			//   return <SystemConfig />;
			default:
				return null;
		}
	};

	return <Box flex={1}>{renderContent()}</Box>;
};

export default ContentArea;
