import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group } from "@mantine/core";
import PageTitle from "../../components/Common/PageTitle";

import { useAppSelector } from "../../store/hooks";
import { Sidebar } from "../../components/Account/SideBar";
import ContentArea from "../../components/Account/ContentArea";

const AccountPage: React.FC = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const { user } = useAppSelector((state) => state.user);

	return (
		<Box h={"100%"}>
			<PageTitle TITLE='SETTINGS' />
			<Flex h={"93.5vh"}>
				<Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex} user={user} />
				<ContentArea activeIndex={activeIndex} />
			</Flex>
		</Box>
	);
};

export default AccountPage;
