import { FundPayload } from "../../../../types";

export const inputList = [
	{ label: "Fund ID", apiKey: "fund_id" },
	{ label: "Fund Name", apiKey: "fund_name" },
	{ label: "Fund Currency", apiKey: "fund_currency" },
	{ label: "Fund Domicile", apiKey: "fund_domicile" },
	{ label: "Legal Jurisdiction", apiKey: "legal_jurisdiction" },
	{ label: "40-act", apiKey: "forty_act_fund" },
	{ label: "Benchmark ID", apiKey: "benchmark_id" },
	{ label: "Primary Fund Manager", apiKey: "primary_fund_manager" },
	{ label: "Secondary Fund Manager (optional)", apiKey: "fund_managers" },
];

 export const initialFormData: FundPayload = {
        fund_id: "", // req
        fund_name: "", //req
        fund_currency: "", // req
        fund_domicile: "",
        legal_jurisdiction: "",
        forty_act_fund: false,
        primary_fund_manager: "", //req
        fund_managers: [],
        benchmark_id: "", //req
        fund_structure: "MASTER",
        valuation_type: "MARKET_ON_OPEN", // req
        optimal_futures_basket_id: "",
        qualified_institutional_investor: false,
        qualified_sophisticated_investor: false,
        eligible_under_rule_144a: false,
        parent_fund_id: "",
        market_on_open_activity_allowed: false,
    };