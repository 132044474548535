import React from "react";
import { Box, Text } from "@mantine/core";
import { t } from "i18next";
import { ChatTextInput } from "./ChatTextInput";

interface WelcomeScreenProps {
	input: string;
	setInput: (value: string) => void;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	onSendMessage?: () => void;
	isLoading?: boolean;
}

export const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
	input,
	setInput,
	handleKeyPress,
	onSendMessage,
	isLoading = false,
}) => {
	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			onSendMessage?.();
		}
	};

	return (
		<Box className='landing-screen'>
			<Text size='1.25rem' fw='800'>
				{t("HOW_CAN_I_HELP_YOU")}
			</Text>
			<ChatTextInput
				input={input}
				setInput={setInput}
				handleKeyPress={handleEnterPress}
				onSendMessage={onSendMessage} 
				disabled={isLoading}
			/>
		</Box>
	);
};
