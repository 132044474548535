import React from "react";
import { Grid, TextInput } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";
import FormField from "./FormField"; 

interface SearchSectionProps {
	totalGrid?: number;
	centerGrid: number;
	rightGrid: number;
	searchQuery: string;
	handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	placeholder?: string;
	label?: string;
	onChange?: (value: string) => void;
}

const SearchSection: React.FC<SearchSectionProps> = ({
	totalGrid,
	centerGrid,
	rightGrid,
	searchQuery,
	handleSearch,
	placeholder,
	onKeyDown,
	label = "",
}) => {
	return (
		<Grid columns={totalGrid}>
			<Grid.Col
				span={centerGrid + rightGrid}
				style={{
					marginLeft: "25px",
					marginTop: "-3px",
					zIndex: "5",
				}}
			>
				<Grid columns={10}>
					<FormField
						label={label}
						input={
							<TextInput
								size='xs'
								radius='sm'
								variant='filled'
								placeholder={placeholder}
								value={searchQuery}
								onChange={handleSearch}
								onKeyDown={onKeyDown} 
								leftSection={<IconWrapper name='search' />}
								className="search-input"
							/>
						}
						inputProps={{}}
					/>
				</Grid>
			</Grid.Col>
		</Grid>
	);
};

export default SearchSection;
