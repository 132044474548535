import React, { useState, useEffect } from "react";
import { Accordion, TextInput, Text, Flex } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";
import { Document } from "../../../types";
import "../Preset.css";
import "./PresetsOption2.css";

interface Folder {
	id: string;
	name: string;
	documents: Document[];
}

interface FolderListProps {
	folders: Folder[];
	expandedFolders: string[];
	highlightedPreset: string | null;
	setHighlightedPreset: React.Dispatch<React.SetStateAction<string | null>>;
	renamingDocId: string | null;
	renamingFolderId: string | null;
	newNameFolder: string;
	setNewNameFolder: React.Dispatch<React.SetStateAction<string>>;
	newName: string;
	setNewName: React.Dispatch<React.SetStateAction<string>>;
	onDocumentDragStart: (doc: Document, sourceFolderId: string) => void;
	onFolderDrop: (targetFolderId: string) => void;

	onRenameDoc: (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		docId: string,
	) => void;
	onRenameFolder: (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		folderId: string,
	) => void;
	handleRenameFolderEvent: (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		folderId: string,
	) => void;
	handleRenamePresetEvent: (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		docId: string,
	) => void;
	onDocumentClick: (uuid: string) => void;
	onFolderRightClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		folderId: string,
		folderName: string,
	) => void;
	onDocumentRightClick: (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		docId: string,
	) => void;
}

const FolderList: React.FC<FolderListProps> = ({
	folders,
	expandedFolders,
	highlightedPreset,
	setHighlightedPreset,
	renamingDocId,
	renamingFolderId,
	newNameFolder,
	setNewNameFolder,
	handleRenameFolderEvent,
	handleRenamePresetEvent,
	onRenameDoc,
	onRenameFolder,
	onDocumentClick,
	onFolderRightClick,
	onDocumentRightClick,
	newName,
	setNewName,
	onDocumentDragStart,
	onFolderDrop,
}) => {
	const [openedFolders, setOpenedFolders] = useState<string[]>(expandedFolders); 

	useEffect(() => {
		setOpenedFolders(expandedFolders);
	}, [expandedFolders]);

	useEffect(() => {
		if (highlightedPreset) {
			const element = document.querySelector(`.document-wrapper.highlighted`);
			if (element) {
				element.scrollIntoView({ behavior: "smooth", block: "center" });
				element.classList.add("highlight");
			}

			const timer = setTimeout(() => setHighlightedPreset(null), 2000);
			return () => clearTimeout(timer);
		}
	}, [highlightedPreset, setHighlightedPreset]);

	return (
		<Accordion
			radius='sm'
			chevronPosition='left'
			variant='filled'
			value={openedFolders}
			onChange={(values) => setOpenedFolders(values)}
			multiple
			className='folder-list-acc'
		>
			{folders.map((folder) => (
				<Accordion.Item key={folder.id} value={folder.name}>
					<Accordion.Control>
						<Flex
							align='center'
							className='folder-wrapper'
							onContextMenu={
								folder.name !== "Favorites"
									? (e) => onFolderRightClick(e, folder.id, folder.name)
									: undefined
							}
							onDragOver={(e) => e.preventDefault()}
							onDrop={() => onFolderDrop(folder.id)}
						>
							<IconWrapper
								name={folder.name === "Favorites" ? "favourites" : "fund-query-preset-folder"}
								className={folder.name === "Favorites" ? "favorites-icon" : "folder-icon"}
							/>
							{renamingFolderId === folder.id ? (
								<TextInput
									autoFocus
									value={newNameFolder}
									onChange={(e) => setNewNameFolder(e.target.value)}
									onKeyPress={(e) => {
										if (e.key === "Enter") handleRenameFolderEvent(e, folder.id);
									}}
									onBlur={(e) => handleRenameFolderEvent(e, folder.id)}
									radius='md'
									size='xs'
									className='inputfolder'
								/>
							) : (
								<Text>{folder.name}</Text>
							)}
						</Flex>
					</Accordion.Control>
					<Accordion.Panel>
						{folder.documents.map((doc) => (
							<Flex
								key={doc.uuid}
								align='center'
								className={`document-wrapper ${
									highlightedPreset === doc.uuid ? "highlighted" : ""
								}`}
								onClick={() => onDocumentClick(doc.uuid)}
								onContextMenu={(e) => onDocumentRightClick(e, doc.uuid)}
								draggable 
								onDragStart={() => onDocumentDragStart(doc, folder.id)} 
							>
								<Text className='document-name'>
									{renamingDocId === doc.uuid ? (
										<TextInput
											autoFocus
											value={newName}
											onChange={(e) => setNewName(e.target.value)}
											onKeyPress={(e) => {
												if (e.key === "Enter") handleRenamePresetEvent(e, doc.uuid);
											}}
											onBlur={(e) => handleRenamePresetEvent(e, doc.uuid)}
											radius='md'
											size='xs'
											className='inputpreset'
										/>
									) : (
										doc.name
									)}
								</Text>
							</Flex>
						))}
					</Accordion.Panel>
				</Accordion.Item>
			))}
		</Accordion>
	);
};

export default FolderList;
