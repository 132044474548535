import React, { useState, useEffect, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { services } from '../../../services';
import EditToggleButton from '../../../components/FundConfig/EditToggleButton';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { RowDataTOL } from '../../../types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FundConfig.css'
import CustomTable from '../../../components/CustomTable/CustomTable';
import { defaultColDef, rowHeight } from '../../../components/CustomTable/utilsAgTable';
import { getColumnDefs } from '../../../components/CustomTable/columnDefs';
import { LoaderIcon } from '../../../components/Common/LoaderIcon';
import { CellClassParams, CellValueChangedEvent } from 'ag-grid-community';

const TOLTemplates: React.FC = () => {
  const [rowData, setRowData] = useState<RowDataTOL[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCells, setEditedCells] = useState<Map<string, string>>(new Map());
  const [originalValues, setOriginalValues] = useState<Map<string, any>>(new Map());
  const theme = useMantineTheme();

  const fetchData = () => {
    return axios.get(services.GET_TEMPLATES)
      .then((response) => {
        const fundsWithTargets: RowDataTOL[] = response.data.data.map((template: { template_name: string; targets: RowDataTOL[] }) => {
          return template.targets.map((target: RowDataTOL) => ({
            template_name: template.template_name,
            type: target.type,
            target_category: target.target_category,
            target_value: target.target_value,
            lower_threshold: target.lower_threshold,
            upper_threshold: target.upper_threshold,
          }));
        }).flat();
        setRowData(fundsWithTargets);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };


  useEffect(() => {
    fetchData();
  }, []);

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    if (!isEditing) return;

    const { data, colDef } = params;
    const cellKey = `${params.rowIndex}-${colDef.field}`;
    setEditedCells((prev) => new Map(prev).set(cellKey, theme.colors.violet[1]));

    if (!originalValues.has(cellKey)) {
      originalValues.set(cellKey, data[colDef.field as keyof RowDataTOL]);
    }
  };

  const handleUpdate = async () => {
    const changesByTemplate: Record<string, RowDataTOL[]> = {};
    rowData.forEach(row => {
      const { template_name, type, target_category, lower_threshold, upper_threshold, target_name, target_value } = row;

      if (!changesByTemplate[template_name]) {
        changesByTemplate[template_name] = [];
      }

      changesByTemplate[template_name].push({
        type,
        target_category,
        lower_threshold,
        upper_threshold,
        target_name,
        target_value,
        template_name,
        template: ''
      });
    });

    const modifiedTemplates: Record<string, RowDataTOL[]> = {};

    editedCells.forEach((_, cellKey) => {
      const [rowIndex, field] = cellKey.split('-');
      const row = rowData[parseInt(rowIndex)];
      const { template_name, type } = row;

      if (!modifiedTemplates[template_name]) {
        modifiedTemplates[template_name] = [...changesByTemplate[template_name]];
      }

      const targetToUpdate = modifiedTemplates[template_name].find(target => target.type === type);
      if (targetToUpdate) {
        targetToUpdate[field as keyof RowDataTOL] = row[field as keyof RowDataTOL];
      }
    });

    const payload = Object.keys(modifiedTemplates).map(template_name => ({
      template_name,
      targets: modifiedTemplates[template_name],
    }));

    if (payload.length === 0) {
      console.log('No changes to update.');
      return;
    }

    try {
      const response = await axios.post(services.GET_TEMPLATES, payload);
      console.log('Update successful:', response.data);
      setEditedCells(new Map());

      const latestDataResponse = await axios.get(services.GET_TEMPLATES);
      if (Array.isArray(latestDataResponse.data)) {
        setRowData(latestDataResponse.data);
      } else {
        console.error('Expected latest data to be an array:', latestDataResponse.data);
      }
      setIsEditing(false);
    } catch (error: unknown) {
      let errorMessage = 'An unknown error occurred';
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      }
      toast.error(errorMessage);
      console.error('Error updating:', errorMessage);
    }
  };


  const handleDiscard = () => {
    setIsEditing(false);
    setEditedCells(new Map());
    fetchData();
  };

  const getCellStyle = (params: CellClassParams) => {
    const cellKey = `${params.rowIndex}-${params.colDef.field}`;
    const bgColor = editedCells.get(cellKey) || '';

    if (params.colDef.field === 'target_value' && isEditing) {
      const { lower_threshold, upper_threshold, target_value } = params.data;
      if (target_value < lower_threshold || target_value > upper_threshold) {
        return {
          className: 'cell-edited',
          backgroundColor: bgColor,
        };
      }
    }
    return {
      className: 'cell-normal',
      backgroundColor: bgColor,
    };
  };

  const columnDefs = useMemo(() => getColumnDefs('TOL_TEMPLATES', {
    isEditing,
    getCellStyle
  }), [isEditing, editedCells]);

  const defaultColDefs = { ...defaultColDef, editable: isEditing }

  return (
    <>{loading ? (
      <Box h='90vh'>
        <LoaderIcon name='loading-large' alt='loading icon' />
      </Box>
    ) : (<Box mah={'85vh'} className='fund-table'>
      <EditToggleButton
        isEditing={isEditing}
        onToggle={() => setIsEditing(!isEditing)}
        onUpdate={handleUpdate}
        onDiscard={handleDiscard}
      />
      <CustomTable rowData={rowData} columnDefs={columnDefs} rowHeight={rowHeight} defaultColDef={defaultColDefs} onCellValueChanged={onCellValueChanged} className='fund-query' emptyMsg='NO_FUND_TEMPLATES' />
      <ToastContainer />
    </Box>)}
    </>);
};

export default TOLTemplates;
