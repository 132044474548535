import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@mantine/core';
import CustomButton from '../../Common/Buttons/CustomButton';
import { t } from 'i18next';
import axios from 'axios';
import PageTitle from '../../Common/PageTitle';
import { getCurrentUTCDateformat } from '../../../utils/dateUtils';
import { COACPayload, MismatchData } from '../../../types';
import { services } from '../../../services';
import CustomAddModal from '../../Common/CustomAddModal';
import SodReconTableComplete from '../SodReconTableComplete';
import { routes } from '../../../routes';
import { ADJ_SUB_TYPE, ReconResultTypes } from '../../../constants';
import { formatMismatchData } from './AdjustmentUtils';

const AdjustmentPage: React.FC = () => {
    const location = useLocation();
    const { asset_id, fund_group, result_type, fund_id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mismatchTableData, setMismatchTableData] = useState<MismatchData[]>([]);
    const [selectedRows, setSelectedRows] = useState<MismatchData[]>(location.state?.selectedRows || []);
    const [selectedMismatchRows, setSelectedMismatchRows] = useState<MismatchData[]>([]);
    const [currentSelectedRows, setCurrentSelectedRows] = useState<MismatchData[]>([]);
    const resultCoacParams = result_type === ReconResultTypes.HOLDINGS_COAC_MISMATCH

    type ApplyToValues = { [key: number]: string };
    const [applyToValues, setApplyToValues] = useState<ApplyToValues>(Object.fromEntries(selectedRows.map((_: MismatchData, index: number) => [index, 'Custodian'])));

    const defaultDate = new Date();
    const [startDates, setStartDates] = useState<{ [key: number]: Date | null }>(
        Object.fromEntries(selectedRows.map((_: MismatchData, index: Number) => [index, defaultDate]))
    );
    const [endDates, setEndDates] = useState<{ [key: number]: Date | null }>(
        Object.fromEntries(selectedRows.map((_: MismatchData, index: Number) => [index, defaultDate]))
    );

    useEffect(() => {
        if (selectedRows.length) {
            setApplyToValues(
                Object.fromEntries(selectedRows.map((_: MismatchData, index: Number) => [index, 'Custodian']))
            );
        }
    }, [selectedRows]);

    useEffect(() => {
        const fetchMismatchData = async () => {
            try {
                const response = await axios.get(`${services.FUND_HOLDINGS}?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`);
                const selectedIds = new Set(selectedRows.map((row) => row.id));
                const filteredData = response.data.data.filter((item: MismatchData) => !selectedIds.has(item.id));
                const formattedData = formatMismatchData(filteredData);
                setMismatchTableData(formattedData || []);
            } catch (error) {
                console.error('Error fetching mismatch data:', error);
            }
        };

        if (selectedRows.length > 0) {
            fetchMismatchData();
        }
    }, [selectedRows, asset_id, fund_group, result_type]);


    useEffect(() => {
        const isUpdateCompleteAdjustment = location.pathname.includes("/update_complete_adjustment");

        if (isUpdateCompleteAdjustment) {
            const fetchCompleteUpdateAdjustment = async () => {
                try {
                    const response = await axios.get(
                        `${services.FUND_HOLDINGS}?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`
                    );

                    const selectedIds = new Set(selectedRows.map((row: MismatchData) => row.id));
                    const filteredData = response.data.data.filter(
                        (item: MismatchData) => !selectedIds.has(item.id)
                    );
                    const formattedData = formatMismatchData(filteredData);
                    const firstTableData = formattedData.filter((item: MismatchData) => item.fund_id === fund_id);
                    const secondTableData = formattedData.filter((item: MismatchData) => item.fund_id !== fund_id);
                    setSelectedRows(firstTableData);
                    setMismatchTableData(secondTableData);

                } catch (error) {
                    console.error('Error fetching complete adjustment data:', error);
                }
            };

            fetchCompleteUpdateAdjustment();
        }
    }, [location.pathname, asset_id, fund_group, result_type, fund_id]);

    const handleDateChange = (rowId: string, date: Date | null, isStartDate: boolean) => {
        if (isStartDate) {
            setStartDates((prev) => ({ ...prev, [rowId]: date }));
        } else {
            setEndDates((prev) => ({ ...prev, [rowId]: date }));
        }
    };

    const createPayload = (rows: typeof currentSelectedRows, startDate?: string, endDate?: string) => {
        const defaultApplyTo = applyToValues[0] || 'Custodian';

        return rows.map((row, index) => {
            const applyTo = applyToValues[index] !== undefined ? applyToValues[index] : defaultApplyTo;
            let quantity = 0;
            if (applyTo === 'Custodian') {
                quantity = (row.our_total_quantity || 0) - (row.custodian_total_quantity || 0);
            } else if (applyTo === 'Atlantis') {
                quantity = (row.custodian_total_quantity || 0) - (row.our_total_quantity || 0);
            }
            return {
                fund_id: row.fund_id || '',
                "asset_key.asset_id": row.asset_id || index.toString(),
                adj_sub_type: row.adj_sub_type || ADJ_SUB_TYPE,
                start_date: resultCoacParams ? startDate || getCurrentUTCDateformat(defaultDate) : getCurrentUTCDateformat(startDates[row.id]),
                end_date: resultCoacParams ? endDate || getCurrentUTCDateformat(defaultDate) : getCurrentUTCDateformat(endDates[row.id]),
                target_asset_id: row.asset_id || index.toString(),
                quantity,
            };
        });
    };

    const COACcall = async (url: string, payload: COACPayload[], description: string) => {
        const response = await axios.post(url, payload, {
            headers: { 'Content-Type': 'application/json' },
        });
        return response;
    };

    const handleApiCallsAndNavigation = async (apiCalls: Promise<any>[], allSelectedRows: typeof currentSelectedRows) => {
        if (allSelectedRows.length === 0) {
            console.error('No rows selected to fix');
            return;
        }
        const rowsToSend = allSelectedRows.map((row) => ({
            id: row.id,
        }));

        try {
            const response = await axios.post(services.FIXED_ISSUE, rowsToSend, {
                headers: { 'Content-Type': 'application/json' },
            });
            
            const selectedIds = rowsToSend.map(row => row.id);
            navigate(`${routes.SODRECON}/${fund_group}`, {
                state: {
                    adjustmentData: rowsToSend,
                    selectedIds,
                    startDates,
                    endDates,
                    applyToValues,
                },
            });
        } catch (error) {
            console.error('Error fixing work items');
        }
    };

    const createPayloadForHoldingsMismatch = async (isCustodian: boolean) => {
        const allSelectedRows = [...selectedRows, ...currentSelectedRows, ...selectedMismatchRows];
        const startDate = getCurrentUTCDateformat(startDates[selectedRows[0].id] || null) || "";
        const endDate = getCurrentUTCDateformat(endDates[selectedRows[0].id] || null) || "";
        const payload = createPayload(allSelectedRows, startDate, endDate);

        try {
            const apiUrl = `${services.FUND_ADJUSTMENT}?custodian=${isCustodian}`;
            const apiCalls = [COACcall(apiUrl, payload, isCustodian ? 'Custodian' : 'Atlantis')];
            await handleApiCallsAndNavigation(apiCalls, allSelectedRows);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.response?.data || error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        }
    };

    const createPayloadForHoldingsOrdersMismatch = async () => {
        const allSelectedRows = currentSelectedRows.length > 0
            ? [...currentSelectedRows, ...selectedMismatchRows]
            : [...selectedRows];

        const custodianRows = currentSelectedRows.filter((_, index) => applyToValues[index] === 'Custodian');
        const atlantisRows = currentSelectedRows.filter((_, index) => applyToValues[index] === 'Atlantis');

        const custodianRowsForNotSelected = selectedRows.filter((_, index) => applyToValues[index] === 'Custodian');
        const atlantisRowsForNotSelected = selectedRows.filter((_, index) => applyToValues[index] === 'Atlantis');

        const apiCalls: Promise<any>[] = [];

        const addApiCall = (rows: MismatchData[], url: string, type: string) => {
            if (rows.length > 0) {
                const payload = createPayload(rows);
                apiCalls.push(COACcall(url, payload, type));
            }
        };

        addApiCall(custodianRows, `${services.FUND_ADJUSTMENT}?custodian=true`, 'custodian=true');
        addApiCall(atlantisRows, `${services.FUND_ADJUSTMENT}?custodian=false`, 'custodian=false');
        addApiCall(selectedMismatchRows, services.FUND_ADJUSTMENT, 'additional rows');

        if (custodianRows.length === 0 && atlantisRows.length === 0) {
            addApiCall(custodianRowsForNotSelected, `${services.FUND_ADJUSTMENT}?custodian=true`, 'custodian=true (not selected)');
            addApiCall(atlantisRowsForNotSelected, `${services.FUND_ADJUSTMENT}?custodian=false`, 'custodian=false (not selected)');
        }

        try {
            await handleApiCallsAndNavigation(apiCalls, allSelectedRows);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.response?.data || error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        }
    };

    const handleSaveAdjustment = async () => {
        if (resultCoacParams) {
            const isCustodian = Object.values(applyToValues).some(value => value === 'Custodian');
            await createPayloadForHoldingsMismatch(isCustodian);
        }
        else {
            await createPayloadForHoldingsOrdersMismatch();
        }
    };

    return (
        <Box>
            <PageTitle
                TITLE='SOD_RECON'
                subtitle='HOLDING_CHECK'
                subtitle2='ADJUSTING_QUANTITY'
                back
                full_report='FULL_REPORT'
            />
            <Flex direction="column" gap="sm" p="2rem">
                <Flex justify="flex-end">
                    <CustomButton variant="discard-recon" onClick={() => navigate(`${routes.SODRECON}/${fund_group}`)}>
                        {t("DISCARD_RECON")}
                    </CustomButton>
                    <Box mt={'0.5rem'}>
                        <CustomButton
                            variant="create-workitems"
                            onClick={() => {
                                if (resultCoacParams || currentSelectedRows.length > 0) {
                                    handleSaveAdjustment();
                                } else {
                                    setIsModalOpen(true);
                                }
                            }}
                        >
                            {t("SAVE_ADJUSTMENT")}
                        </CustomButton>

                    </Box>
                </Flex>

                <SodReconTableComplete
                    data={selectedRows}
                    onSelectionChange={setCurrentSelectedRows}
                    isAdjustmentPage={true}
                    onDateChange={handleDateChange}
                    onApplyToChange={setApplyToValues}
                    applyToValues={applyToValues}
                    noCheck={selectedRows.length > 1 ? false : true}
                />

                {mismatchTableData.length > 0 && resultCoacParams && (
                    <>
                        <Text size='lg' fw={600} mt={'lg'}>{t("SELECT_OTHER_FUND_COAC")}</Text>
                        <SodReconTableComplete
                            data={mismatchTableData}
                            onSelectionChange={setSelectedMismatchRows}
                            allowMultipleSelection={true}
                            withoutAdditionalRows
                        />
                    </>
                )}
            </Flex>

            <CustomAddModal
                opened={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleSaveAdjustment}
                title={selectedRows.length > 1 ? "SAVE_ADJUSTMENT_TITLE" : "SAVE_ADJUSTMENT_TITLE_SINGLE"}
                message={selectedRows.length > 1 ? "SAVE_ADJUSTMENT_MESSAGE" : "SAVE_ADJUSTMENT_MESSAGE_SINGLE"}
                submitButtonText={"SAVE"}
                cancelButtonText={"CANCEL"}
            />
        </Box>
    );
};

export default AdjustmentPage;