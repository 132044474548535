import { Box } from "@mantine/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"; 
import SegmentedControl from "../../AlgoJournal/SegmentedControl";
import FundsInvestment from "./FundInvestment/FundsInvestment";
import AssetTable from "../../../components/AssetsInvestments/AssetTable";

const InvestmentTabs: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    
    const initialTab = new URLSearchParams(location.search).get('tab') || 'funds';
    const [activeTab, setActiveTab] = useState<string>(initialTab);

    const tabs = [
        {
            label: t('CAMELCASE_FUNDS'),
            value: 'funds',
            content: <FundsInvestment />
        },
        {
            label: t('ASSETS'),
            value: 'assets',
            content: <AssetTable />
        }
    ];

    const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));

    const controlRef = useRef<HTMLDivElement>(null);

    const handleTabChange = (value: string) => {
        setActiveTab(value);
        navigate(`?tab=${value}`, { replace: true });
    };

    useEffect(() => {
        const queryParam = new URLSearchParams(location.search).get('tab');
        if (queryParam && (queryParam === 'funds' || queryParam === 'assets')) {
            setActiveTab(queryParam); 
        }
    }, [location.search]); 

    useEffect(() => {
        const activeIndex = tabs.findIndex(tab => tab.value === activeTab);
        const activeSegmentRef = tabRefs.current[activeIndex]?.current;
        const { offsetWidth, offsetLeft } = activeSegmentRef || {};
        const { style } = controlRef.current || {};

        if (style && offsetWidth !== undefined && offsetLeft !== undefined) {
            style.setProperty("--highlight-width", `${offsetWidth}px`);
            style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
        }
    }, [activeTab, tabs]);

    return (
        <>
            <Box>
                <SegmentedControl
                    name="investment-tabs"
                    callback={handleTabChange}
                    controlRef={controlRef}
                    defaultIndex={tabs.findIndex(tab => tab.value === activeTab)}
                    segments={tabs.map((tab, index) => ({
                        label: tab.label,
                        value: tab.value,
                        ref: tabRefs.current[index]
                    }))}
                />
            </Box>
            <Box ta="start" mt={'-3.5rem'} p={'xs'}>
                {tabs.find(tab => tab.value === activeTab)?.content}
            </Box>
        </>
    );
};

export default InvestmentTabs;
