import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridOptions } from 'ag-grid-community';
import { t } from 'i18next';
import { services } from '../../services';
import axios from 'axios';
import { Box, Card, Flex, Modal, TextInput, Text, Checkbox, useMantineTheme } from '@mantine/core';
import { Role, RoleTableProps } from '../../types';
import "../../pages/AdminPages/Users/AdminPage.css"
import { useDisclosure } from '@mantine/hooks';
import SelectionHandler from './SelectionHandler';
import AdminTableHeader from './AdminTableHeader';
import CustomButton from '../Common/Buttons/CustomButton';
import { handleMainContentClickUtil } from '../../utils/handleMainContentClick';
import { useDeselectAllOnRowUnselect } from '../../utils/useDeselectAllOnRowUnselect';
import CustomTable from '../CustomTable/CustomTable';
import { getTableData } from '../../utils/conversions';
import { rowHeight, rowSelectionTypeSingle } from '../CustomTable/utilsAgTable';
import { DeleteModal } from '../Common/Modals/DeleteModal';

const RoleTable: React.FC<RoleTableProps> = ({ apiData, roleCounts, onUniqueDescriptionsChange }) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState(true);
    const theme = useMantineTheme();
    const [modalType, setModalType] = useState<null | 'create' | 'update' | 'delete'>(null);
    const [modalOpened, { toggle: toggleModal }] = useDisclosure(false);
    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    const [roleName, setRoleName] = useState('');
    const [checkedCanApprove, setCheckedCanApprove] = useState(false);
    const [checkedCoac, setCheckedCoac] = useState(false);
    const [ordersLimit, setOrdersLimit] = useState('');
    const [permissions, setPermissions] = useState<string[]>([]);
    const [userToDelete, setUserToDelete] = useState<{ name: string } | null>(null);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleModalToggle = (type: 'create' | 'update') => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            toggleModal();
        } else {
            setModalType(type);
            if (!modalOpened) toggleModal();
        }
    };

    const createRole = () => {
        const data = {
            description: roleName,
            permissions: permissions,
            can_approve_orders: checkedCanApprove ? 'true' : 'false',
            can_approve_coac: checkedCoac ? 'true' : 'false',
            orders_limit: ordersLimit,
        };

        axios.post(services.ROLES, data)
            .then(response => {
                console.log('Role created successfully:', response.data);
                handleModalToggle('create')
                fetchRoles();
            })
            .catch(error => {
                console.error('Error creating role:', error);
            });
    };

    const handleCreateRoleClick = () => {
        setRoleName('');
        setCheckedCanApprove(false);
        setCheckedCoac(false);
        setOrdersLimit('');
        setPermissions([]);
        setModalType('create');
        handleModalToggle('create')
    };

    const openEditModal = (role: Role) => {
        setSelectedRole(role);
        setRoleName(role.description);
        setCheckedCanApprove(role.can_approve_orders);
        setCheckedCoac(role.can_approve_coac);
        setOrdersLimit(role.orders_limit);
        setPermissions(role.permissions);
        setModalType('update');
        handleModalToggle('update')
    };

    const fetchRoles = () => {
        axios.get(services.ROLES)
            .then(response => {
                setRoles(response.data);
                setLoading(false);

                const uniqueDescriptions: Role[] = Array.from(new Set(response.data.map((role: Role) => role.role)))
                    .map((roleName) => {
                        // Find the first role object with this roleName
                        const roleObject = response.data.find((role: Role) => role.role === roleName);
                        if (roleObject) {
                            return {
                                role: roleName,
                                description: roleObject.description
                            };
                        }
                        return null;
                    })
                    .filter((role): role is Role => role !== null);

                // const uniqueDescriptions = Array.from(new Set(response.data.map((role: Role) => role.description)));
                onUniqueDescriptionsChange(uniqueDescriptions);


            })
            .catch(error => {
                console.error('Error fetching roles:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchRoles();
    }, []);

        const columnDefs: ColDef[] = useMemo(() => {
            const tableData = getTableData("ROLES");
            const columns = [
                ...tableData.map(({ colHeader, key, decorators }) => ({
                    headerName: t(colHeader),
                    field: key,
                    cellRenderer: null,
                })),
                {
                    headerName: t('CAN_APPROVE'),
                    field: 'can_approve',
                    width: 239,
                    cellRenderer: (params: { data: any; }) => {
                        const { can_approve_orders, can_approve_coac, orders_limit } = params.data;
                        return (() => {
                            if (can_approve_orders && can_approve_coac) {
                                return `< $${orders_limit} ${t("LOWERCASE_ORDERS")}, ${t("COAC_DEALS")}`;
                            }
                            if (can_approve_orders) {
                                return `< $${orders_limit} ${t("LOWERCASE_ORDERS")}`;
                            }
                            if (can_approve_coac) {
                                return `< $${orders_limit} ${t("COAC_DEALS")}`;
                            }
                            return "-";
                        })();
                    }
                },
                {
                    headerName: t("USER_COUNT"),
                    field: 'roleCount',
                    width: 200,
                    valueGetter: (params: { data: any; }) => roleCounts[params.data.description] || 0
                },
            ];
            return columns;
        }, []);

    const gridApiRef = useRef<any>(null);

    const onGridReady = (params: { api: any; }) => {
        gridApiRef.current = params.api;
    };

    const handleSet = () => {
        if (selectedRole) {
            const data = {
                role: selectedRole.role,
                description: roleName,
                can_approve_orders: checkedCanApprove,
                can_approve_coac: checkedCoac,
                orders_limit: ordersLimit,
                permissions: permissions
            };

            axios.put(services.ROLES, data)
                .then(response => {
                    console.log('Role updated successfully:', response.data);
                    handleModalToggle('update')
                    setTimeout(() => {
                        fetchRoles()
                        setIsRowSelected(false); // Fetch updated data after a delay
                    }, 500);

                })
                .catch(error => {
                    console.error('Error updating role:', error);
                });
        }
    };

    const handleDeleteClick = () => {
        const gridApi = gridApiRef.current;
        const selectedNodes = gridApi.getSelectedNodes();
        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const name = selectedNode.data.role;
            setUserToDelete({ name: name });
            setIsDeleteModalOpen(true)

        }
    };

    const handleDeleteUser = () => {
        if (userToDelete) {
            const data = {
                name: userToDelete.name,
            };
            axios
                .delete(services.ROLES, { data })
                .then(response => {
                    console.log('User deleted successfully:', response.data);
                    setIsDeleteModalOpen(false)
                    fetchRoles()
                    setTimeout(() => {
                        setIsRowSelected(false);
                    }, 500);
                })
                .catch(error => {
                    console.error('Error deleting user:', error);
                });
        } else {
            console.error('No user selected for deletion.');
        }
    };

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_ROLES_HERE")} -`,
        };
    }, []);

    const gridOptions: GridOptions = {
        suppressRowHoverHighlight: true,
    };
    const handleEditClick = () => {
        const selectedNodes = gridApiRef.current.getSelectedNodes();
        if (selectedNodes.length > 0) {
            openEditModal(selectedNodes[0].data);
        }
    };

    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    const mainContentRef = useRef<HTMLDivElement>(null);
    const handleMainContentClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        handleMainContentClickUtil(e, mainContentRef, setIsRowSelected);
    };

    return (
        <Box ref={mainContentRef}
            onClick={handleMainContentClick}>
            <Box maw={"36.8125rem"}>

                <Box mr={'2rem'}>
                    <AdminTableHeader isRowSelected={isRowSelected} handleEditUser={handleEditClick} handleDeleteClick={handleDeleteClick} resetCreateUser={handleCreateRoleClick} headerTitle="ROLES" createTitle='CREATE_USER_ROLE' />
                </Box>
                <Modal
                    opened={modalOpened && modalType === 'update'}
                    onClose={() => handleModalToggle('update')}
                    centered
                    withCloseButton={false}
                    radius={'1.875rem'} className='role-modal' size={'38.0625rem'}
                >
                    {selectedRole && (
                        <Card bg={theme.colors.grey[2]} pt={"0.7rem"} pl={'3.1rem'} >
                            <Text fw={700} size='lg' ta={'center'} ml={'-4rem'}>{t("EDIT_ROLE")}</Text>
                            <TextInput
                                value={roleName}
                                onChange={(event) => setRoleName(event.currentTarget.value)}
                                mt={'1.5rem'}
                                label={t("ROLE")}
                                className='role-input'
                            />
                            <Text mt="1.5rem">{t("CAN_APPROVE")}</Text>
                            <Flex align='center' mt={'-0.7rem'}>
                                <Checkbox
                                    checked={checkedCanApprove}
                                    onChange={(event) => setCheckedCanApprove(event.currentTarget.checked)}
                                    color="gray"
                                    variant="outline"
                                />
                                <Text size={theme.fontSizes.md} ml={'0.375rem'} c={theme.colors.grey[8]}>{t("ORDERS")}</Text>
                                <Flex mt={'1rem'}>

                                    <TextInput
                                        value={0}
                                        placeholder={t("MIN")} ml={'3.625rem'} className='min-max-input'
                                    />
                                    <div className="line"></div>
                                    <TextInput
                                        value={ordersLimit}
                                        onChange={(event) => setOrdersLimit(event.currentTarget.value)}
                                        placeholder={t("MAX")}
                                        className='min-max-input'
                                    />
                                </Flex>
                            </Flex>

                            <Flex mt="1rem" align='center'>
                                <Checkbox
                                    checked={checkedCoac}
                                    onChange={(event) => setCheckedCoac(event.currentTarget.checked)}
                                    color="gray"
                                    variant="outline"
                                />

                                <Text size={theme.fontSizes.md} c={theme.colors.grey[8]} ml={'0.375rem'}>{t("COAC_DEALS")}</Text>
                            </Flex>

                            <Flex justify="flex-end" mt="1.5rem" mb={"2.1rem"} pr={'3.85rem'}>
                                <CustomButton variant={'cancel'} onClick={() => handleModalToggle('update')} children='CANCEL' />
                                <CustomButton variant={'submit'} onClick={handleSet} children='SET' />
                            </Flex>
                        </Card>
                    )}
                </Modal>

                <Modal opened={modalOpened && modalType === 'create'} onClose={() => handleModalToggle('create')} centered
                    withCloseButton={false} radius={'1.875rem'} className='role-modal' size={'38.0625rem'}>
                    <Card bg={theme.colors.grey[2]} pt={"0.7rem"} pl={'3.1rem'} >

                        <Text fw={700} size='lg' ta={'center'} ml={'-4rem'}>{t("CREATE_NEW_ROLE")}</Text>
                        <TextInput value={roleName} mt={'1.5rem'} label={t("ROLE")} onChange={event => setRoleName(event.currentTarget.value)} className='role-input' />
                        <Text mt="1.5rem">{t("CAN_APPROVE")}</Text>

                        <Flex align='center' mt={'-0.7rem'}>
                            <Checkbox
                                checked={checkedCanApprove}
                                onChange={event => setCheckedCanApprove(event.currentTarget.checked)}
                                color="gray"
                                variant="outline"
                            />
                            <Text size={theme.fontSizes.md} ml={'0.375rem'} c={theme.colors.grey[8]}>{t("ORDERS")}</Text>
                            <Flex mt={'1rem'}>
                                <TextInput placeholder={t("MIN")} ml={'3.625rem'} className='min-max-input' />
                                <div className="line"></div>
                                <TextInput placeholder={t("MAX")} value={ordersLimit} onChange={event => setOrdersLimit(event.currentTarget.value)} className='min-max-input' />
                            </Flex>
                        </Flex>

                        <Flex mt="1rem" align='center'>
                            <Checkbox
                                checked={checkedCoac}
                                onChange={event => setCheckedCoac(event.currentTarget.checked)}
                                color="gray"
                                variant="outline"
                            />
                            <Text size={theme.fontSizes.md} c={theme.colors.grey[8]} ml={'0.375rem'}>{t("COAC_DEALS")}</Text>
                        </Flex>

                        <Flex justify="flex-end" mt="1.5rem" mb={"2.1rem"} pr={'3.85rem'}>
                            <CustomButton variant={'cancel'} onClick={() => handleModalToggle('create')} children='CANCEL' />
                            <CustomButton variant={'submit'} onClick={createRole} children='CREATE' />
                        </Flex>
                    </Card>
                </Modal>

                <SelectionHandler gridApiRef={gridApiRef} setIsRowSelected={setIsRowSelected} />
                <DeleteModal
                    opened={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    TITLE='DELETE_ROLE'
                    message='DELETE_ROLE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />

                <CustomTable rowData={roles} columnDefs={columnDefs} rowSelection={rowSelectionTypeSingle} rowHeight={rowHeight} onGridReady={onGridReady} onSelectionChanged={handleSelectionChanged} className='action-item' emptyMsg='NO_ROLES_HERE'/>
            </Box>
        </Box>
    );
};

export default RoleTable;