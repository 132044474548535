import React, { useState, useRef } from "react";
import { Box } from "@mantine/core";
import SegmentedControl from "../../pages/AlgoJournal/SegmentedControl";
import { t } from "i18next";
import InfoForMyFundsTab from "./InfoForMyFundsTab ";

const ExploreTab = () => {
	const [activeTab, setActiveTab] = useState<string>("INFO");
	const controlRef = useRef<HTMLDivElement>(null);

	const segmentRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

	const renderContent = () => {
		if (activeTab === "INFO") {
			return <InfoForMyFundsTab />;
		}
		if (activeTab === "REPORTS") {
			return (
				<div style={{ textAlign: "center", margin: "1rem", color: "#6c757d" }}>
					{t("No reports available for investors.")}
				</div>
			);
		}
		return null;
	};

	return (
		<Box>
			<div className='margin-segment chat-tabs'>
				<SegmentedControl
					name='explore-tabs'
					segments={[
						{ label: t("INFO_FOR_MY_FUNDS"), value: "INFO", ref: segmentRefs[0] },
						{ label: t("REPORTS_FOR_INVESTORS"), value: "REPORTS", ref: segmentRefs[1] },
					]}
					defaultIndex={0}
					callback={setActiveTab}
					controlRef={controlRef}
				/>

				<Box mt='md'>{renderContent()}</Box>
			</div>
		</Box>
	);
};

export default ExploreTab;
