import { useState, useEffect } from 'react';
import axios from 'axios';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { routes } from '../../routes';
import { CashForecast, RowDataForeCast } from '../../types';
import './CashExposure.css'
import { Box, Center, Flex } from '@mantine/core';
import { toast, ToastContainer } from 'react-toastify';
import { applyDecorators } from '../../utils/conversions';
import CustomTable from '../CustomTable/CustomTable';

export const ForeCast = () => {
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [rowData, setRowData] = useState<RowDataForeCast[]>([]);
    const { fund_id, target_mode, target_date, calc_date } = useParams();

    const fetchCashExposureData = async () => {
        try {
            const response = await axios.get(routes.CASHEXPOSURE, {
                params: { fund_id, calc_date, target_date, target_mode }
            });
            const filteredCashForeCast: CashForecast[] = response.data.data[0].cash_forecasts;
            filterAndSetColDefs(filteredCashForeCast)
            filterAndSetRowData(filteredCashForeCast)
        } catch (error: any) {
            console.log('error fetching cash exposure data : ', error)
            toast.error(error.message)
        }
    }
    const filterAndSetRowData = (foreCastData: CashForecast[]) => {
        const rowData: RowDataForeCast[] = foreCastData.map((el) => {
            let rowObj: RowDataForeCast = { currency_code: el.currency_code };
            let cash_breakdown = el.cash_breakdown
            for (let key in cash_breakdown) {
                let { spendable_cash, spendable_cash_pct } = cash_breakdown[key];
                rowObj[key] = { spendable_cash, spendable_cash_pct }
            }
            return rowObj
        })
        setRowData(() => rowData)
    }
    const filterAndSetColDefs = (foreCastData: CashForecast[]) => {
        const columnHeaders: ColDef[] = [
            { headerName: 'Currency', field: 'currency_code', width: 100, cellRenderer: (params: RowDataForeCast) => <Center>{params.value as string}</Center> },
        ];
        const cashBreakdown = foreCastData[0].cash_breakdown;
        for (let key in cashBreakdown) {
            columnHeaders.push({
                field: key,
                width: 250,
                cellRenderer: (params: RowDataForeCast) => {
                    let dataObj: unknown = params.value;
                    let { spendable_cash, spendable_cash_pct } = dataObj as RowDataForeCast;
                    spendable_cash = applyDecorators(spendable_cash ? spendable_cash : 0, ["decimal_3", "currency_3_coma"]);
                    spendable_cash_pct = applyDecorators(spendable_cash_pct ? spendable_cash_pct : 0, ["decimal_3"]);
                    return (
                        <Center>
                            <Flex flex={3} justify="flex-end" pr={'xs'}>
                                {spendable_cash}
                            </Flex>
                            <Flex flex={2} justify="flex-end" pr={'xs'}>
                                {spendable_cash_pct} %
                            </Flex>
                        </Center>
                    );
                },
            });
        }
        setColumnDefs(() => columnHeaders);
    };

    const customWidth = () => {
        let numberOfColumns = columnDefs.length;
        return `${6.25 + (numberOfColumns - 1) * 15.625 + 2}rem`
    }

    const defaultColDef: ColDef = {
        headerClass: "forecast-header",
    };

    useEffect(() => {
        fetchCashExposureData()
    }, [])
    return (<>
        <Box pt='lg' m='auto' mih="4.4rem" w={customWidth()} maw='100%'>
            <CustomTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} className='fund-query'/>
        </Box>
        <ToastContainer />
    </>
    );
};

