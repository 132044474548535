import React from "react";
import { Flex, TextInput } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";
import "../Preset.css";
import "./PresetsOption2.css";

interface FolderCreationInputProps {
	folderTitle: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	placeholder: string;
	theme: any;
}

const FolderCreationInput: React.FC<FolderCreationInputProps> = ({
	folderTitle,
	onChange,
	onKeyPress,
	onBlur,
	placeholder,
	theme,
}) => {
	return (
        <Flex align='center' mt=
        "15px">
			<IconWrapper name='fund-query-preset-folder' className='allfundqueryicon' />
			<TextInput
				autoFocus
				value={folderTitle}
				onChange={onChange}
				onKeyPress={onKeyPress}
				onBlur={onBlur}
				mt='0.2rem'
				size={theme.fontSizes.md}
				c={theme.colors.dark[8]}
				placeholder={placeholder}
				className='inputfolder'
			/>
		</Flex>
	);
};

export default FolderCreationInput;
