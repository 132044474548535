import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { createSession } from "./aiService";
import "./AIAssistantPage.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchInitialData, loadChatHistory } from "../../store/features/chat/chatSlice";
import { ChatLayout } from "../../components/AIAssistant/ChatLayout";
import { categorizeChats } from "../../utils/chatUtils";
import { ChatProvider } from "../../contexts/ChatContext";

export const AIAssistant: React.FC = () => {
	const { symbol } = useParams();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { currentSession, chatHistory, input, error, isLoading } = useAppSelector(
		(state) => state.chat,
	);

	const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);
	const [selectedItem, setSelectedItem] = useState<string>("new chat");
	const [hasFetchedData, setHasFetchedData] = useState(false); 

	useEffect(() => {
		dispatch(loadChatHistory());
	}, [dispatch]);

useEffect(() => {
	if (!hasFetchedData && symbol && user?.username) {
		setHasFetchedData(true);

		const initializeChat = async () => {
			try {
				await createSession(user.username || "guest");
				dispatch(fetchInitialData({ query: symbol || "", createNewSession: true }));
			} catch (err) {
				console.error("Error initializing chat:", err);
			}
		};

		initializeChat();
	}
}, [symbol, user, hasFetchedData, dispatch]);
	useEffect(() => {
		console.log("AIAssistant Component Mounted");
		return () => console.log("AIAssistant Component Unmounted");
	}, []);
	const categorizedChats = useMemo(() => categorizeChats(chatHistory), [chatHistory]);

	return (
		<ChatProvider
			selectedItem={selectedItem}
			setSelectedItem={setSelectedItem}
			isLeftNavOpen={isLeftNavOpen}
			setIsLeftNavOpen={setIsLeftNavOpen}
			input={input}
			symbol={symbol}
		>
			<ChatLayout
				currentSession={currentSession}
				chatHistory={chatHistory}
				input={input}
				categorizedChats={categorizedChats}
				isLoading={isLoading}
				error={error}
			/>
		</ChatProvider>
	);
};
