// useFundActivityStatus.ts
import { useState } from 'react';
import axios from 'axios';
import { services } from '../services';

export const useFundActivityStatus = () => {
    const [error, setError] = useState<string | null>(null);
    
    const fetchFundActivityStatus = async () => {
        try {
            const response = await axios.get(services.ACTIVITY_STATUS);
            return response.data.data;
        } catch (error: any) {
            console.error("Error making API request:", error);
            setError(error?.response?.status || "UNKNOWN_ERROR");
        }
    };

    return { fetchFundActivityStatus, error };
};
