import React from "react";
import { Flex, Box, Text } from "@mantine/core";
import { StackedBar } from "./StackedBar";
import styles from "./SectionRenderer.module.css";

interface SectionRendererProps {
	sections: { label: string; values: number[]; labels: string[] }[];
}

export const SectionRenderer = ({ sections }: SectionRendererProps) => {
	const LABEL_WIDTH = "2.5rem";
	const SPACING = "0rem";

	return (
		<>
			{sections.map((section, index) => (
				<Flex key={index} h='2.5rem' align='center' ml='0.5rem'>
					<Box
						className={styles.barLabel}
						style={{
							width: LABEL_WIDTH,
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						{section.label === "Not Ready" ? (
							<div className={styles.notReadyContainer}>
								<Text className={styles.notReadyText}>Not</Text>
								<Text className={styles.notReadyText}>Ready</Text>
							</div>
						) : (
							<Text>{section.label}</Text>
						)}
					</Box>

					<Box style={{ width: SPACING }} />

					<Box style={{ flex: 1 }}>
						<StackedBar values={section.values} labels={section.labels} />
					</Box>
				</Flex>
			))}
		</>
	);
};
