import { ColDef, ValueGetterParams, CellStyleFunc, CellStyle } from 'ag-grid-community';
import { t } from 'i18next';
import { getTableData } from '../../utils/conversions';

type ColumnDefCase = 'ATTRIBUTES' | 'TOL_TEMPLATES' | 'TARGETS_AND_TOLS';

interface AdditionalParams {
  isEditing?: boolean;
  editedCells?: Set<string>;
  getCellStyle?: CellStyleFunc<any, any>;
}

const getMappedTableData = (tableData: any[]): ColDef[] =>
  tableData.map(({ colHeader, key }) => ({
    headerName: t(colHeader),
    field: key,
    cellRenderer: null,
  }));

const yesNoRenderer = (value: any) => (value ? 'Yes' : 'No');

export const getColumnDefs = (
  caseType: ColumnDefCase,
  additionalParams: AdditionalParams = {}
): ColDef[] => {
  const tableData = getTableData(caseType);

  switch (caseType) {
    case 'ATTRIBUTES':
      return [
        {
          headerName: t('SR_NO'),
          field: 'srNo',
          width: 100,
          valueGetter: (params: ValueGetterParams) =>
            params.node?.rowIndex != null ? params.node.rowIndex + 1 : '',
          cellStyle: { textAlign: 'center' } as CellStyle, // Explicitly cast to CellStyle
        },
        ...getMappedTableData(tableData),
        {
          headerName: t('FORTY_ACT_FUND'),
          field: 'forty_act_fund',
          cellRenderer: ({ value }: { value: boolean }) => yesNoRenderer(value),
        },
        {
          headerName: t('QUALIFIED_INSTITUTIONAL_INVESTOR'),
          field: 'qualified_institutional_investor',
          cellRenderer: ({ value }: { value: boolean }) => yesNoRenderer(value),
        },
        {
          headerName: t('QUALIFIED_SOPHISTICATED_INVESTOR'),
          field: 'qualified_sophisticated_investor',
          cellRenderer: ({ value }: { value: boolean }) => yesNoRenderer(value),
        },
        {
          headerName: t('ELIGIBLE_UNDER_RULE_144A'),
          field: 'eligible_under_rule_144a',
          cellRenderer: ({ value }: { value: boolean }) => yesNoRenderer(value),
        },
      ];

    case 'TOL_TEMPLATES':
    case 'TARGETS_AND_TOLS': {
      const { isEditing = false, getCellStyle } = additionalParams;

      const editableField = (params: any) =>
        caseType === 'TARGETS_AND_TOLS'
          ? !params.data.disabled && isEditing
          : isEditing;

      return [
        ...getMappedTableData(tableData),
        {
          headerName: t('TARGET_VALUE'),
          field: 'target_value',
          editable: editableField,
          cellStyle: getCellStyle as CellStyleFunc<any, any>, 
        },
        {
          headerName: t('LOWER_THRESHOLD'),
          field: 'lower_threshold',
          editable: editableField,
          cellStyle: getCellStyle as CellStyleFunc<any, any>, 
        },
        {
          headerName: t('UPPER_THRESHOLD'),
          field: 'upper_threshold',
          editable: editableField,
          cellStyle: getCellStyle as CellStyleFunc<any, any>, 
        },
      ];
    }

    default:
      return [];
  }
};
