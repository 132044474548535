import { useState, useEffect, useRef, useMemo } from "react";
import {
	useMantineTheme,
	Text,
	Box,
	Flex,
	Collapse,
	ActionIcon,
	Divider,
	ScrollArea,
	Card,
} from "@mantine/core";
import AllCountersCard from "../../components/Dashboard/AllCountersCard/AllCountersCard";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { ActionItemsRow, DashData, ProcessedData } from "../../types";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import PageTitle from "../../components/Common/PageTitle";
import axios from "axios";
import { services } from "../../services";
import { useDispatch } from "react-redux";
import { setWorkItemsLength } from "../../store/userSlice";
import "./Dashboard.css";
import { t } from "i18next";
import { formatDueDate, getCurrentUTCDate } from "../../utils/dateUtils";
import { useAppSelector } from "../../store/hooks";
import CustomButton from "../../components/Common/Buttons/CustomButton";
import CollapsibleSectionGroup from "../../components/Dashboard/CollapsibleSectionGroup";
import { cards } from "../../constants/dashboardCards";
import { WORK_ITEM_NAVIGATION } from "../../constants";
import { filterRelevantRows } from "./DashboardUtils";
import { LoaderIcon } from "../../components/Common/LoaderIcon";

const Dashboard = () => {
	const theme = useMantineTheme();
	const [opened, { toggle }] = useDisclosure(false);
	const [selectedRows, setSelectedRows] = useState<ActionItemsRow[]>([]);
	const [workItems, setWorkItems] = useState<any[]>([]);
	const [workItemsTomorrow, setWorkItemsTomorrow] = useState<any[]>([]);
	const [workItemsLater, setWorkItemsLater] = useState<any[]>([]);
	const { user } = useAppSelector((state) => state.user);
	const [loading, setLoading] = useState<boolean>(true);
	const [errors, setError] = useState<string | null>(null);
	const [workType, setWorkType] = useState<string | null>(null);
	const { counters } = useAppSelector((state) => state.market);
	const showAllCards = false;

	const [selectedRowCount, setSelectedRowCount] = useState(0);
	const [selectedRowCountTomorrow, setSelectedRowCountTomorrow] = useState(0);
	const [selectedRowCountLater, setSelectedRowCountLater] = useState(0);
	const isDisabled =
		workItems.length === 0 && workItemsTomorrow.length === 0 && workItemsLater.length === 0;

	const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

	const [selectedComments, setSelectedComments] = useState<any[]>([]);

	const handleCommentsChange = (comments: any[]) => {
		setSelectedComments(comments);
	};

	const workTypeDisable = useMemo(() => {
		if (selectedRowCount === 0) return true;

		const relevantRows = filterRelevantRows(selectedRows);
		return relevantRows.length > 0;
	}, [selectedRowCount, selectedRows]);

	const handleRowSelection = (selection: ActionItemsRow[]) => {
		setSelectedRows(selection);
		if (selection.length > 0 && !opened) toggle();
		if (selection.length === 0 && opened) toggle();
	};
	const dispatch = useDispatch();

	const fetchData = async () => {
		try {
			const response = await axios.get(services.WORK_ITEM);
			setLoading(false);

			if (response.data) {
				const { today, tomorrow, later } = response.data.data;

				setWorkItems(processData(today));
				setWorkItemsTomorrow(processData(tomorrow));
				setWorkItemsLater(processData(later));
				setError(null);

				const targetStatuses = ["WORK_ITEM_STATUS_ACTION_NEEDED", "WORK_ITEM_STATUS_ACTION_TAKEN"];
				const filteredTodayItems = today.filter((item: { work_item_status: string }) =>
					targetStatuses.includes(item.work_item_status),
				);
				dispatch(setWorkItemsLength(filteredTodayItems.length));
			}
			if (!response.data) {
				setLoading(false);
				setError("API returned an error status: " + response.data.api_status);
			}
		} catch (error: any) {
			setLoading(false);
			const status = error.response?.status;
			const errorMessage = status
				? `Error ${status}: ${error.response.statusText}`
				: "An unexpected error occurred.";
			dispatch(setWorkItemsLength(0));
			setError(errorMessage);
			console.error("Error fetching data:", errorMessage);
		}
	};

	const processData = (data: DashData[]): ProcessedData[] => {
		const formatComments = (comments?: { comment: string }[]): string => {
			if (!comments || !Array.isArray(comments)) {
				return "";
			}
			return comments
				.slice()
				.reverse()
				.map((c) => c.comment)
				.join(", ");
		};

		return data.map((dash) => {
			const processedData: ProcessedData = {
				id: dash.id || "-",
				due_time: dash.due_date.due_time || "-",
				work_type: dash.alert.display_name || t(dash.alert.category) || "-",
				status: t(dash.work_item_status) || "-",
				copilot_status: t("WAITING_FOR_ACTION") || "-",
				fund_id: dash.alert.alert_detail?.fund_id || "-",
				fund_name: dash.alert.alert_detail?.fund_name || "-",
				fund_group:
					dash.alert.alert_detail?.sod_fund_recon_aggregated_alert_detail?.fund_group || "-",
				description: dash.alert.description || "-",
				primary_fm_name: user?.fullName || user?.username || "-",
				comment: formatComments(dash.comments) || "-",
				creation_time: dash.alert.creation_time || "-",
				completed_time: dash.comments?.[0]?.completed_time || "-",
				completed_by: dash.comments?.[0]?.completed_by || "-",
				comments: dash.comments || [] || "-",
				due_date: dash.due_date ? formatDueDate(dash.due_date.due_date) : "-",
				symbol: dash.alert?.alert_detail?.news_reports_alert_detail?.metadata?.ticker_symbol,
				alert_category: dash.alert.category,
			};

			setWorkType(dash.alert.category);

			return processedData;
		});
	};

	const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);
	useEffect(() => {
		if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		}
		if (!opened || (opened && selectedRows.length === 0)) {
			fetchData();
			intervalIdRef.current = setInterval(fetchData, 6000);
		} else if (opened && selectedRows.length > 0) {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
				intervalIdRef.current = null;
			}
		}
		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
			}
		};
	}, [opened, selectedRows.length]);

	const [comment, setComment] = useState("");

	const handleKeyDown = async (event: { key: string; preventDefault: () => void }) => {
		if (event.key === "Enter") {
			event.preventDefault();

			try {
				const flatten = selectedComments.flat();
				const newComment = {
					comment,
					creation_time: getCurrentUTCDate(),
					completed_time: getCurrentUTCDate(),
					completed_by: user?.fullName || user?.username,
				};
				console.log("flatten", flatten);
				const filteredFlatten = (flatten || []).filter(
					(comment) => comment !== null && comment !== undefined,
				);
				const payload = {
					comments: [...filteredFlatten, newComment],
				};

				const postResponse = await axios.post(`${services.WORK_ITEM}/${selectedRowIds}`, payload);

				if (postResponse.status === 200) {
					setComment("");
					fetchData();
					toggle();
				} else {
					console.error("Failed to post comment");
				}
			} catch (error) {
				console.error("Error fetching or posting comment:", error);
			}
		}
	};

	const onIssueClick = async (urlSuffix: string) => {
		const flatten = selectedComments.flat();

		const newComment = {
			comment,
			creation_time: getCurrentUTCDate(),
			completed_time: getCurrentUTCDate(),
			completed_by: user?.fullName || user?.username,
		};
		const filteredFlatten = (flatten || []).filter(
			(comment) => comment !== null && comment !== undefined,
		);
		const payload = {
			comments: [...filteredFlatten, newComment],
			id: String(selectedRowIds),
		};

		try {
			const response = await axios.post(`${services.WORK_ITEM}/${urlSuffix}`, [payload]);

			if (response.status === 200) {
				setComment("");
				fetchData();
				toggle();
				setError(null);
			} else {
				console.error("Failed to post comment");
			}
		} catch (error: any) {
			setError(error.response.status);
		}
	};

	const handleSelectionChange = (selectedIds: string[]) => {
		setSelectedRowIds(selectedIds);
	};

	return (
		<>
			<PageTitle TITLE={t("MY_DASHBOARD")} />
			<div className='dashboard-top'>
				<ScrollArea type='auto'>
					<Flex
						justify='space-between'
						wrap='wrap'
						className='counters-grid'
						style={{ gap: "1rem" }}
					>
						{cards
							.filter(({ param }) => {
								if (!showAllCards && counters) {
									if (param === "market_on_open_counters") {
										return counters[param]?.total_funds_in_aggregate_count !== 0;
									}
								}
								return true;
							})
							.map(({ param, title, route }, index) => (
								<Box key={index} className='card-box'>
									<Link to={route} aria-label={`Navigate to ${title}`}>
										<AllCountersCard param={param} TITLE={title} showAllCards={showAllCards} />
									</Link>
								</Box>
							))}
					</Flex>
				</ScrollArea>

				<div className='tables'>
					<Flex style={{ flex: 1, gap: "1rem", overflow: "hidden" }}>
						<Box
							style={{
								flex: 1,
								overflowY: "auto",
							}}
							className='scroll-container'
						>
							{loading ? (
								<div>
									<Box h={"80vh"}>
										<LoaderIcon name='loading-large' alt='loading icon' />
									</Box>
								</div>
							) : (
								<CollapsibleSectionGroup
									sections={[
										{
											title: t("TODAY"),
											count: workItems.length,
											rowData: workItems,
											updateSelectedRowCount: (count) => {
												setSelectedRowCount(count);
											},
										},
										{
											title: t("TOMORROW"),
											count: workItemsTomorrow.length,
											rowData: workItemsTomorrow,
											updateSelectedRowCount: (count) => {
												setSelectedRowCountTomorrow(count);
											},
										},
										{
											title: t("LATER"),
											count: workItemsLater.length,
											rowData: workItemsLater,
											updateSelectedRowCount: (count) => {
												setSelectedRowCountLater(count);
											},
										},
									]}
									handleRowSelection={handleRowSelection}
									handleSelectionChange={handleSelectionChange}
									handleCommentsChange={handleCommentsChange}
								/>
							)}
						</Box>
						<Box
							flex='none'
							mt='1.5rem'
							className={` table-collapsable-sidebar ${opened ? "" : "collapsed"}`}
						>
							<Flex justify='flex-end'>
								<ActionIcon
									variant='transparent'
									color={theme.colors.grey[8]}
									aria-label='Action Form'
									onClick={toggle}
									w='10px'
									h='16px'
									me='-0.75rem'
									mb='sm'
									disabled={isDisabled}
								>
									{opened ? <IconChevronRight /> : <IconChevronLeft />}
								</ActionIcon>
							</Flex>
							<Collapse in={opened}>
								<Flex direction='column' align='center' p='-5rem'>
									<Text c={theme.colors.grey[8]} fw={600} size='0.8rem'>
										{selectedRowCount === 0
											? t("SELECT_FUND_ACTION")
											: `${t("TOTAL")} ${selectedRowCount} ${t("FUNDS_SELECTED")}`}
									</Text>
									<Card bg={theme.colors.grey[1]} h='7.5rem' w='10.375rem' mt='md' mb='md'>
										<input
											style={{
												backgroundColor: theme.colors.grey[1],
												border: "none",
												height: "8rem",
												textAlign: "left",
												paddingBottom: "5rem",
											}}
											className='input-border'
											placeholder={t("COMMENT")}
											onKeyDown={handleKeyDown}
											value={comment}
											onChange={(e) => setComment(e.target.value)}
										/>
									</Card>
									<CustomButton
										variant={workTypeDisable ? "no-issue-disable" : "no-issue"}
										onClick={() => onIssueClick("no-issue")}
										disabled={workTypeDisable}
									>
										{t("NO_ISSUE")}
									</CustomButton>

									<CustomButton
										variant={workTypeDisable ? "fixed-issue-disable" : "fixed-issue"}
										onClick={() => onIssueClick("fixed-issue")}
										disabled={workTypeDisable}
									>
										{t("FIXED_ISSUE")}
									</CustomButton>

									<Divider w='7.5rem' h='0.5rem' mt='xxl' />
								</Flex>
							</Collapse>
						</Box>
					</Flex>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
