import React, { useEffect, useState } from "react";
import { Box, Flex } from "@mantine/core";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import TabsComponent from "../Common/TabsComponent";
import { t } from "i18next";
import axios from "axios";
import { services } from "../../services";
import { EarningCallsTranscript } from "../../types";
import { LoaderIcon } from "../Common/LoaderIcon";
import CustomTable from "../CustomTable/CustomTable";
import { rowHeightLarge } from "../CustomTable/utilsAgTable";

const InfoForMyFundsTab: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState<string>("Earning Calls");
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [earningCallsData, setEarningCallsData] = useState<EarningCallsTranscript[]>([])

	const tabMessages: Record<string, string> = {
		"Earning Calls": "Earning Calls",
		"Regulatory Filings": "Regulatory Filings",
		"Broker Research": "Broker Research",
		"Market News": "Market News",
	};

	const renderContent = () => {
		if (selectedTab === t("EARNING_CALLS")) {
			return (<Box w={"100%"} className="earning-table-container" >
					<CustomTable rowData={earningCallsData}
						columnDefs={[
							{ headerName: "", field: "title", flex: 2 },
							{
								headerName: "In My Fund", valueGetter: (params) => (params.data as any)?.in_my_fund || "-", cellStyle: { textAlign: "center" }
							},
							{ headerName: "Published Date", field: "year", cellStyle: {textAlign:"center"}},
						]}
					rowHeight={rowHeightLarge}
					className="earning-table-container"
					/>	
			</Box>
			);
		} else {
			return (
				<div style={{ textAlign: "center", margin: "1rem", color: "#6c757d" }}>
					No results available for {tabMessages[selectedTab]}
				</div>
			);
		}
	};
	const fetchEarningCallTranscript = async () => {
		try {
			setIsLoading(true);
			const response = await axios.get(services.EARNING_TRANSCRIPT);
			setEarningCallsData(()=>response.data);
		} catch (error) {
			console.log('error fetching earning transcript')
		} finally {
			setIsLoading(false)
		}
	}
	useEffect(() => {
		fetchEarningCallTranscript()
	}, [])

	return (
		<>
			<TabsComponent
				value={selectedTab}
				onChange={(value) => setSelectedTab(value || "Earning Calls")}
				tabs={[
					{ label: t("EARNING_CALLS"), value: "Earning Calls" },
					{ label: t("REGULATORY_FILLINGS"), value: "Regulatory Filings" },
					{ label: t("BROKER_RESEARCH"), value: "Broker Research" },
					{ label: t("MARKET_NEWS"), value: "Market News" },
				]}
			/>

			{isLoading ?
				(<Box h={"80vh"}><LoaderIcon name='loading-large' alt='loading icon' /></Box>)
				:
				(<Flex className='holdingtab-main-container'>{renderContent()}</Flex>)
			}

		</>
	);
};

export default InfoForMyFundsTab;
