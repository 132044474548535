import React, { useEffect, useState } from "react";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, Flex, Text } from "@mantine/core";
import EquitizeTable from "./EquitizeTable";
import { useParams } from "react-router-dom";
import { routes } from "../../routes";
import { t } from "i18next";
import { applyDecorators, calculatePercentage, getTableData } from "../../utils/conversions";
import { ExposureTableTitle, RowDataExposure } from "../../types";
import {LoaderIcon} from "../Common/LoaderIcon";
import { ValueFormatterParams } from "ag-grid-community";

const EquitizePage = () => {
	const [cashExposureData, setCashExposureData] = useState<null | Record<string, number>>(null);
	const [totalPercentage, setTotalPercentage] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [noData, setNoData] = useState<boolean>(false);
	const { fund_id, target_mode, target_date, calc_date } = useParams();

	useEffect(() => {
		axios
			.get(routes.CASHEXPOSURE, {
				params: {
					fund_id,
					calc_date,
					target_date,
					target_mode,
				},
			})
			.then((response) => {
				if (response.data.api_status === "success") {
					const data = response.data.data;
					if (data && data.length > 0) {
						setCashExposureData(data[0].total_cash_exposure);
						setTotalPercentage(data[0].total_cash_exposure?.total);
						setNoData(false);
					} else {
						setNoData(true);
					}
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [fund_id, target_mode, target_date, calc_date]);

	const tables: { title: ExposureTableTitle; keys: string[]; addTotalRow: boolean }[] = [
		{
			title: "ExposureTable",
			keys: [
				"total",
				"equity",
				"total_cash",
				"spendable_cash",
				"margin_cash",
				"accrued_cash",
				"futures",
				"unequitized_cash",
			],
			addTotalRow: false,
		},
		{
			title: "SpendableCashTable",
			keys: [
				"settled_balance",
				"unsettled_cashflows",
				"unsettled_executed_orders",
				"placed_orders",
				"approved_orders",
				"modelled_orders",
				"confirmed_fees",
				"confirmed_coac",
			],
			addTotalRow: true,
		},
		{
			title: "MarginCashTable",
			keys: ["margin_balance", "margin_transfer", "mark_to_market_margin"],
			addTotalRow: true,
		},
		{
			title: "AccuredCashTable",
			keys: [
				"confirmed_dividend",
				"estimated_dividend",
				"est_div_haircut",
				"tax_reclaim",
				"accrued_fees",
				"accrued_coac",
			],
			addTotalRow: true,
		},
	];

	const tableTitleToExposureType: Record<ExposureTableTitle, string> = {
		ExposureTable: t("FUND_EXPOSURE"),
		SpendableCashTable: t("SPENDABLE_CASH"),
		MarginCashTable: t("MARGIN_CASH"),
		AccuredCashTable: t("ACCRUED_CASH"),
	};

	const commonColumnHeaders = {
		ValueUSD: t("VALUE_IN_USD"),
		ValuePercentage: t("VALUE_PERCENTAGE"),
	};

	const generateColumnDefs = (tableTitle: ExposureTableTitle) => [
		{
			headerName: tableTitleToExposureType[tableTitle],
			field: "key",
			width: 354,
			cellStyle: (params: any) => {
				const normalizedKey = params.data.key.toLowerCase().replace(/\s+/g, "_");
				if (["spendable_cash", "margin_cash", "accrued_cash"].includes(normalizedKey)) {
					return { marginLeft: "2.25rem" };
				}
				return null;
			},
		},
		{
			headerName: commonColumnHeaders.ValueUSD,
			field: "value",
			width: 222,
			cellClass: "right-align-header",
			headerClass: "right-align-header",
			valueFormatter: (params: ValueFormatterParams<RowDataExposure, string | number>) => {
				const rawValue = params.value;
				if (rawValue === null || rawValue === undefined || isNaN(Number(rawValue))) {
					return "-";
				}
				return applyDecorators(
					rawValue,
					getTableData("EquitizeTable1").find((col) => col.key === params.colDef.field)?.decorators,
				);
			},
		},
		{
			headerName: commonColumnHeaders.ValuePercentage,
			field: "percentage",
			cellClass: "right-align-header",
			headerClass: "right-align-header",
			width: 222,
		},
	];

	const prepareRowData = (keys: string[], addTotalRow: boolean = false, tableTitle?: string) => {
		const rows: RowDataExposure[] = keys.map((key) => ({
			key: t(`EQUITIZE_HEADER_${key.toUpperCase()}`),
			value:
				cashExposureData?.[key] !== undefined && cashExposureData?.[key] !== null
					? applyDecorators(
							cashExposureData[key],
							getTableData("EquitizeTable1").find((col) => col.key === key)?.decorators,
					  )
					: "-",
			percentage: calculatePercentage(cashExposureData?.[key] ?? 0, totalPercentage),
		}));

		if (addTotalRow && cashExposureData && tableTitle) {
			const tableTitleToKeyMap: Record<string, { key: string; label: string }> = {
				SpendableCashTable: { key: "spendable_cash", label: t("TOTAL_SPENDABLE_CASH") },
				MarginCashTable: { key: "margin_cash", label: t("TOTAL_MARGIN_CASH") },
				AccuredCashTable: { key: "accrued_cash", label: t("TOTAL_ACCURED_CASH") },
			};

			let totalValue: number | null = null;
			let totalLabel = "TOTAL";

			if (tableTitle in tableTitleToKeyMap) {
				const { key, label } = tableTitleToKeyMap[tableTitle];
				totalValue = cashExposureData[key] ?? 0;
				totalLabel = t(label);
			} else {
				totalValue = keys.reduce((sum, key) => sum + (cashExposureData?.[key] || 0), 0);
			}

			rows.push({
				key: totalLabel,
				value: totalValue !== null ? totalValue.toString() : "-",
				percentage: calculatePercentage(totalValue ?? 0, cashExposureData?.total_cash),
			});
		}

		return rows;
	};

	return (
		<Box className='cash-exposure-tables-container'>
			{loading ? (
				<Box h={'80vh'}>
					<LoaderIcon name='loading-large' alt='loading icon' />
				</Box>
			) : noData ? (
				<Flex justify={"center"} align={"center"}>
					<Text>{t("NO_DATA_FOUND")}</Text>
				</Flex>
			) : (
				tables.map((table, index) => (
					<EquitizeTable
						key={index}
						title={table.title}
						rowData={prepareRowData(table.keys, table.addTotalRow, table.title)}
						columnDefs={generateColumnDefs(table.title as ExposureTableTitle)}
						height='auto'
					/>
				))
			)}
		</Box>
	);
};

export default EquitizePage;
