import React, { createContext, useContext, ReactNode, useCallback } from "react";
import { useAppDispatch } from "../store/hooks";
import {
	setInput,
	startNewChat,
	loadChatSession,
	sendChatMessage,
} from "../store/features/chat/chatSlice";

interface ChatContextType {
	selectedItem: string;
	isLeftNavOpen: boolean;
	setSelectedItem: (item: string) => void;
	toggleSidebar: () => void;
	handleSendMessage: () => Promise<void>; 
	handleKeyPress: (event: React.KeyboardEvent) => void;
	handleSetInput: (value: string) => void;
	handleStartNewChat: () => void;
	handleLoadChatSession: (sessionId: string) => void;
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
	children: ReactNode;
	selectedItem: string;
	setSelectedItem: (item: string) => void;
	isLeftNavOpen: boolean;
	setIsLeftNavOpen: (value: boolean) => void;
	input: string;
	symbol?: string;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({
	children,
	selectedItem,
	setSelectedItem,
	isLeftNavOpen,
	setIsLeftNavOpen,
	input,
	symbol,
}) => {
	const dispatch = useAppDispatch();

	const toggleSidebar = useCallback(() => {
		setIsLeftNavOpen(!isLeftNavOpen); 
	}, [setIsLeftNavOpen, isLeftNavOpen]);

	const handleSendMessage = useCallback(async () => {
		if (input.trim()) {
			try {
				await dispatch(sendChatMessage({ input, docId: symbol })).unwrap();
				dispatch(setInput(""));
			} catch (err) {
				console.error("Error sending message:", err);
			}
		}
	}, [dispatch, input, symbol]);

	const handleKeyPress = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === "Enter" && !event.shiftKey) {
				event.preventDefault();
				handleSendMessage();
			}
		},
		[handleSendMessage],
	);

	const handleSetInput = useCallback(
		(value: string) => {
			dispatch(setInput(value));
		},
		[dispatch],
	);

	const handleStartNewChat = useCallback(() => {
		dispatch(startNewChat());
	}, [dispatch]);

	const handleLoadChatSession = useCallback(
		(sessionId: string) => {
			dispatch(loadChatSession(sessionId));
		},
		[dispatch],
	);

	const value = {
		selectedItem,
		isLeftNavOpen,
		setSelectedItem,
		toggleSidebar,
		handleSendMessage,
		handleKeyPress,
		handleSetInput,
		handleStartNewChat,
		handleLoadChatSession,
	};

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("Error");
	}
	return context;
};
