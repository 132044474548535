import React from "react";
import { Flex } from "@mantine/core";
import TabsComponent from "../Common/TabsComponent";
import ErrorMessage from "../Common/ErrorMessage";
import AllFundQueryTable from "../AllFundQuery/AllFundQueryTable";
import { Dispatch, SetStateAction } from "react";
import { TabTypes } from "../../utils/filterOrders";

interface OrdersTabProps {
	t: (key: string) => string;
	selectedOrdersTab: TabTypes;
	setSelectedOrdersTab: Dispatch<SetStateAction<TabTypes>>;
	ordersData: any[];
	filteredOrders: any[];
	ordersError: string | null;
	tabMessages: Record<string, string>;
}
const OrdersTab: React.FC<OrdersTabProps> = ({
	t,
	selectedOrdersTab,
	setSelectedOrdersTab,
	ordersData,
	filteredOrders,
	ordersError,
	tabMessages,
}) => {
	return (
		<>
			<TabsComponent
				value={selectedOrdersTab}
				onChange={(value) => setSelectedOrdersTab((value as TabTypes) || "ALL")}
				tabs={[
					{
						label: `${t("Market on Open")} (${
							ordersData.filter((order) => order.order_type === "ORDER_TYPE_MARKET_ON_OPEN").length
						})`,
						value: "MARKET_ON_OPEN",
					},
					{
						label: `${t("Market")} (${
							ordersData.filter((order) => order.order_type === "ORDER_TYPE_MARKET").length
						})`,
						value: "MARKET",
					},
					{
						label: `${t("Market on Close")} (${
							ordersData.filter((order) => order.order_type === "ORDER_TYPE_MARKET_ON_CLOSE").length
						})`,
						value: "MARKET_ON_CLOSE",
					},
					{
						label: `${t("All")} (${ordersData.length})`,
						value: "ALL",
					},
				]}
			/>

			<Flex className='holdingtab-main-container'>
				{ordersError ? (
					<ErrorMessage errorMessage={ordersError} />
				) : (
					<>
						{filteredOrders.length === 0 ? (
							<div style={{ textAlign: "center", margin: "1rem", color: "#6c757d" }}>
								{t(`No orders available for ${tabMessages[selectedOrdersTab] || "selected funds"}`)}
							</div>
						) : (
							<AllFundQueryTable
								data={filteredOrders}
								isFromHoldingContentTab={true}
								selectedTypeOfData='ORDERS'
								tableConfig={{
									columnOrder: [
										"sr_no",
										"valuation_point",
										"fund_id",
										"fund_name",
										"bloomberg_ticker",
										"asset_id",
										"order_status",
										"asset_name",
										"transaction_type",
										"price_currency",
									],
									pinnedColumns: [
										"sr_no",
										"valuation_point",
										"fund_id",
										"fund_name",
										"bloomberg_ticker",
									],
									hideHeaders: ["sr_no"],
								}}
							/>
						)}
					</>
				)}
			</Flex>
		</>
	);
};

export default OrdersTab;
