import axios from 'axios';

export const formatDueDate = (dueDate: string | number | Date) => {
  const today = new Date();
  const targetDate = new Date(dueDate);
  const timeDiff = targetDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff === 0) return 'Today';
  if (daysDiff === 1) return 'Tomorrow';
  if (daysDiff > 1 && daysDiff <= 3) return `+${daysDiff}`;
  return `Overdue (${targetDate.toLocaleDateString()})`; 
};

export const getCurrentUTCDateformat = (date: Date | null = new Date()): string | null => {
  if (!date) return null;
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const stringToDate = (stringDate: string): string => {
  if (!stringDate) {
    throw new Error('Input date is required.');
  }
  const parts = stringDate.split('/');
  if (parts.length !== 3) {
    throw new Error('Invalid date format. Expected MM/DD/YYYY.');
  }
  const [month, day, year] = parts;
  if (!/^\d{2}$/.test(month) || !/^\d{2}$/.test(day) || !/^\d{4}$/.test(year)) {
    throw new Error('Invalid date components.');
  }
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const dateToString = (isoDate?: string): string => {
  if (!isoDate) {
    return '';
  }

  const parts = isoDate.split('-');
  if (parts.length !== 3) {
    throw new Error('Invalid date format. Expected YYYY-MM-DD.');
  }

  const [year, month, day] = parts;

  return `${month}/${day}/${year}`;
};

export const getCurrentUTCDate = (): string => {
  const systemDate = localStorage.getItem('systemDate');

  if (!systemDate || isNaN(new Date(systemDate).getTime())) {
    console.warn('Invalid or missing systemDate in localStorage. Refreshing with the current date.');
    const currentDate = new Date().toISOString().split('T')[0];
    localStorage.setItem('systemDate', currentDate);
    return currentDate;
  }

  return systemDate;
};

export const formatStartAndEndDates = (startDates: { [key: number]: Date | null }, endDates: { [key: number]: Date | null }) => {
  const startDate = getCurrentUTCDateformat(startDates[0] || null) || "";
  const endDate = getCurrentUTCDateformat(endDates[0] || null) || "";
  return { startDate, endDate };
};

