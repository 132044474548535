import React from 'react';
import '../../../components/Common/ComingSoon/ComingSoon.css';
import PageTitle from '../../../components/Common/PageTitle';
import { Box } from '@mantine/core';
import InvestmentTabs from './InvestmentTab';

const InvestmentPage: React.FC = () => {
    return (
        <Box>
            <PageTitle TITLE='INVESTMENTS' />
            <Box p={'lg'}>
                <InvestmentTabs />
            </Box>
        </Box>
    );
}

export default InvestmentPage;