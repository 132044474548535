import React, { useState } from "react";
import { Flex, Grid } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import SearchSection from "../AllFundQuery/PresetsOptions2/SearchInput";
import { t } from "i18next";
import styles from "./AIAssistant.module.css";
import clsx from "clsx";
import { BaseLayoutProps, CategorizedChats } from "../../types";
import { AIChatNavControls } from "./AIChatNavControls";
import { AIChatHistory } from "./AIChatHistory";
import { loadChatSessionMessages } from "../../store/features/chat/chatSlice";
import { useAppDispatch } from "../../store/hooks";


interface AIChatNavBarProps extends BaseLayoutProps {
	categorizedChats: CategorizedChats; 
	onNewChat: () => void;
	onLoadChat: (sessionId: string) => void;
	isLeftNavOpen: boolean;
	selectedItem: string;
	setSelectedItem: (item: string) => void;
	toggleSidebar: () => void;
}

const AIChatNavBar: React.FC<AIChatNavBarProps> = ({
	isLeftNavOpen,
	toggleSidebar,
	sidebarClassName = "preset-leftNav",
	categorizedChats, 
	onNewChat,
	onLoadChat,
	selectedItem,
	setSelectedItem,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const dispatch = useAppDispatch();

	const handleItemClick = async (itemLabel: string, chatId?: string) => {
		console.log("chatId", chatId);
		const newSelection = chatId || itemLabel.toLowerCase();
		setSelectedItem(newSelection);

		if (chatId) {
			try {
				onLoadChat(chatId);
				await dispatch(loadChatSessionMessages(chatId)).unwrap();
			} catch (error) {
				console.error("Error loading chat session:", error);
			}
		} else if (itemLabel.toLowerCase() === "new chat") {
			onNewChat();
		}
	};

	const sidebarClasses = clsx(
		styles.sidebar,
		isLeftNavOpen ? styles["sidebar--open"] : styles["sidebar--closed"],
	);

	const contentClasses = clsx(
		styles.sidebarContent,
		sidebarClassName,
		isLeftNavOpen ? styles["sidebarContent--visible"] : styles["sidebarContent--hidden"],
	);

	return (
		<Flex className={styles.container}>
			<div className={sidebarClasses}>
				<div className={contentClasses}>
					<Grid className={styles.gridContainer}>
						<Grid.Col className={styles.searchSection}>
							<SearchSection
								totalGrid={12}
								centerGrid={9}
								rightGrid={1.5}
								searchQuery={searchQuery}
								placeholder={`${t("SEARCH")} ${t("PRESETS")}`}
								onChange={setSearchQuery} 
							/>
						</Grid.Col>

						<Grid.Col className={styles.navigationSection}>
							<AIChatNavControls selectedItem={selectedItem} onNavItemClick={handleItemClick} />
						</Grid.Col>

						<Grid.Col>
							<AIChatHistory
								categorizedChats={categorizedChats}
								selectedItem={selectedItem}
								onChatSelect={handleItemClick}
								searchQuery={searchQuery}
							/>
						</Grid.Col>
					</Grid>
				</div>

				<button
					onClick={toggleSidebar}
					className={styles.toggleButton}
					aria-label={isLeftNavOpen ? "Close sidebar" : "Open sidebar"}
				>
					{isLeftNavOpen ? <IconChevronLeft size={24} /> : <IconChevronRight size={24} />}
				</button>
			</div>
		</Flex>
	);
};

export default AIChatNavBar;
