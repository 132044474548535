import React from "react";
import { Flex, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import "./Header.css"; 

interface HeaderProps {
	title: string;
	count: number;
	label?: string;
}

export const Header = React.memo(({ title, count, label }: HeaderProps) => {
	const { t } = useTranslation();

	return (
		<Flex
			align='center'
			justify='center'
			pb='0.8rem'
			role='region'
			aria-labelledby='header-title'
			className='header-container'
		>
			<Text id='header-title' className='header-title'>
				{t(title)}:
			</Text>

			<Text className='header-count'>{count}</Text>

			<Text className='header-label'>{t(label || "funds")}</Text>
		</Flex>
	);
});

export default Header;
