import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AppShell } from "@mantine/core";
import { useAppSelector } from "../../store/hooks";
import { routes } from "../../routes";
import AdminNavbar from "../Leftnavbar/AdminNavbar";
import LeftNavbar from "../Leftnavbar/LeftNavbar";
import { isAdmin } from "../../utils/validations";

interface AuthLayoutProps {
	children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
	const { authenticated, user } = useAppSelector((state) => state.user);

	if (authenticated) {
		return (
			<AppShell padding='md' className='parent-container'>
				<div style={{ display: "flex", width: "100%" }}>
					<nav
						style={{
							flexShrink: 0,
							overflow: "hidden",
						}}
					>
						{isAdmin(user?.roles) ? <AdminNavbar /> : <LeftNavbar />}
					</nav>
					<div
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							height: "100%",
							overflow: "auto",
						}}
					>
						{children}
					</div>
				</div>
			</AppShell>
		);
	} else {
		return <Navigate to={routes.LOGIN} />;
	}
};

export default AuthLayout;
