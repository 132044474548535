import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Flex, Text, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconDownload } from "@tabler/icons-react";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import IconWrapper from "./IconWrapper";
import { ImportModal } from "./ImportModal";
import { CountsType } from "../Leftnavbar/sectionData";
import PageHeader from "./PageHeader";

export type Props = {
	TITLE?: string;
	subtitle?: string;
	subtitle2?: string;
	subtitle3?: string;
	back?: boolean;
	full_report?: string;
	import_asset?: string;
	counts?: CountsType;
	onMarketStateChange?: (marketState: string) => void;
};

const PageTitle = ({
	TITLE,
	subtitle,
	subtitle2,
	subtitle3,
	import_asset,
	counts,
	full_report,
	back = false,
}: Props) => {
	const { t } = useTranslation();
	const theme = useMantineTheme();
	const { fund_id, target_date, target_mode, fund_state } = useParams();

	const [modalOpened, setModalOpened] = useState(false);

	const openImportModal = () => {
		setModalOpened(true);
	};

	const closeImportModal = () => {
		setModalOpened(false);
	};

	return (
		<header>
			<Flex align='center' justify='space-between' p='1rem'>
				<PageHeader
					TITLE={TITLE}
					subtitle={subtitle}
					subtitle2={subtitle2}
					subtitle3={subtitle3}
					back={back}
					fund_id={fund_id}
					target_date={target_date}
					target_mode={target_mode}
					fund_state={fund_state}
				/>

				{full_report && (
					<Flex ml='auto' align='center'>
						<IconDownload height={17} width={17} color={theme.colors.grey[8]} />
						<Text ml='0.4rem' size='md' c={theme.colors.grey[8]} fw={600}>
							{t(full_report)}
						</Text>
					</Flex>
				)}

				{import_asset && (
					<Flex ml='auto' align='center' onClick={openImportModal} mr='md'>
						<IconWrapper name='import_icon' />
						<Text ml='0.4rem' size='md' c={theme.colors.grey[8]} fw={600}>
							{t("IMPORT")}
						</Text>
					</Flex>
				)}
			</Flex>

			<ImportModal opened={modalOpened} onClose={closeImportModal} TITLE='IMPORT_CSV' />
			<Divider />
		</header>
	);
};

export default PageTitle;
