import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FundStatusState {
	selectedMarketState: string;
	fundsOrdersTab: "FUNDS" | "ORDERS";
}

const persistedFundsOrdersTab = localStorage.getItem("fundsOrdersTab") as "FUNDS" | "ORDERS";

const initialState: FundStatusState = {
	selectedMarketState: "MARKET_ON_OPEN",
	fundsOrdersTab: persistedFundsOrdersTab || "FUNDS",
};

const fundStatusSlice = createSlice({
	name: "fundStatus",
	initialState,
	reducers: {
		setMarketState(
			state,
			action: PayloadAction<{ marketState: string; marketOnOpenCount: number }>,
		) {
			const { marketState, marketOnOpenCount } = action.payload;
			state.selectedMarketState = marketOnOpenCount > 0 ? marketState : "MARKET_ON_CLOSE";
		},
		setFundsOrdersTab(state, action: PayloadAction<"FUNDS" | "ORDERS">) {
			state.fundsOrdersTab = action.payload;
			localStorage.setItem("fundsOrdersTab", action.payload);
		},
	},
});

export const { setMarketState, setFundsOrdersTab } = fundStatusSlice.actions;
export default fundStatusSlice.reducer;
