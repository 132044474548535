import axios from "axios";
import { isExpiredToken } from "../utils/validations";
import { fetchNewAccessToken } from "../utils/authHelpers";
import { refreshInterval } from "../utils/authConstants";
import { handleLogout } from "../utils/handleLogOut";

let token = localStorage.getItem("athena_A");
if (token) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
} else {
	delete axios.defaults.headers.common["Authorization"];
}
axios.defaults.baseURL = "/";

let requestQueue: {
	resolve: (token: string) => void;
	reject: (error: any) => void;
}[] = [];
let tokenRefreshPromise: "fetching" | null = null;

setInterval(async () => {
	let refreshToken = localStorage.getItem("athena_R");
	if (refreshToken) {
		try {
			const res = await fetchNewAccessToken(refreshToken);
			localStorage.setItem("athena_A", res.access_token);
			localStorage.setItem("athena_R", res.refresh_token);
			axios.defaults.headers.common["Authorization"] = `Bearer ${res.access_token}`;
		} catch (err) {
			// localStorage.removeItem("athena_A");
			// localStorage.removeItem("athena_R");
			// delete axios.defaults.headers.common["Authorization"];
			// window.location.reload();
			handleLogout();
		}
	}
}, refreshInterval);

axios.interceptors.request.use(
	async (config) => {
		if (config.url === "/auth/token") {
			return config;
		}
		let token = localStorage.getItem("athena_A");
		let refreshToken = localStorage.getItem("athena_R");
		if (token && refreshToken) {
			if (isExpiredToken(token)) {
				if (!tokenRefreshPromise) {
					tokenRefreshPromise = "fetching";
					try {
						const res = await fetchNewAccessToken(refreshToken);
						localStorage.setItem("athena_A", res.access_token);
						localStorage.setItem("athena_R", res.refresh_token);
						axios.defaults.headers.common["Authorization"] = `Bearer ${res.access_token}`;
						config.headers.Authorization = `Bearer ${res.access_token}`;
						requestQueue.forEach((queuedRequest) => {
							queuedRequest.resolve(res.access_token);
						});
						requestQueue = [];
						tokenRefreshPromise = null;
					} catch (err) {
						// localStorage.removeItem("athena_A");
						// localStorage.removeItem("athena_R");
						// delete axios.defaults.headers.common["Authorization"];
						// requestQueue.forEach((queuedRequest) => {
						// 	queuedRequest.reject(err);
						// });
						// requestQueue = [];
						// tokenRefreshPromise = null;
						// window.location.reload();
						handleLogout();
					}
				} else {
					return new Promise((resolve, reject) => {
						requestQueue.push({
							resolve: (token: string) => {
								config.headers.Authorization = `Bearer ${token}`;
								resolve(config);
							},
							reject: (error: any) => {
								reject(error);
							},
						});
					});
				}
			}
		} else {
			console.error("No valid tokens. Logging out.");
			handleLogout();
		}

		return config;
	},
	(error) => Promise.reject(error.response),
);

export default axios;
