import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Flex, Text } from '@mantine/core';
import PageTitle from '../Common/PageTitle';
import { useParams, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import IconWrapper from '../Common/IconWrapper';
import SodReconTableComplete from './SodReconTableComplete';
import { services } from '../../services';
import CustomButton from '../Common/Buttons/CustomButton';
import { MismatchData } from '../../types';
import { ReconResultTypes, WORK_ITEM_STATUS } from '../../constants';
import { routes } from '../../routes';

const POLL_INTERVAL = 2000;
const MAX_POLLS = 5;

const CompleteAdjustment = () => {
    const [matchingRow, setMatchingRow] = useState<MismatchData[]>([]);
    const [inProgressRows, setinProgressRows] = useState<MismatchData[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { asset_id, result_type, fund_group, fund_id } = useParams();
    const navigate = useNavigate();

    const fetchData = async (): Promise<{
        shouldStop: boolean;
        inProgressRowsLength: number;
        matchingRowsLength: number;
    }> => {
        try {
            const response = await axios.get(
                `${services.FUND_HOLDINGS}?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`
            );

            const transformedRows = response.data.data.map((item: MismatchData) => ({
                id: item._id,
                fund_id: item.alert?.alert_detail?.fund_id || '',
                fund_name: item.alert?.alert_detail?.fund_name || '',
                work_item_status: item.work_item_status || 'Unknown Status',
                asset_name: item.alert?.alert_detail?.asset_name || '',
                benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
                asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
                our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
                custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
                explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
                unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
                recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
                start_date_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.start_date || item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.start_date,
                end_date_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs?.end_date || item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs?.end_date,
                apply_to_sod: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.fund_adj_subs
                    ? 'Atlantis'
                    : item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.custodian_adj_subs
                        ? 'Custodian'
                        : ''
            }));

            const completedRows = transformedRows.filter(
                (row: { work_item_status: string; }) => row.work_item_status === WORK_ITEM_STATUS.COMPLETED
            );

            const inProgressRows = transformedRows.filter(
                (row: { work_item_status: string; }) => row.work_item_status !== WORK_ITEM_STATUS.COMPLETED
            );

            const matchingCompletedRows = transformedRows.filter(
                (row: { fund_id: string | undefined; work_item_status: string; }) => row.fund_id === fund_id && row.work_item_status === WORK_ITEM_STATUS.COMPLETED
            );

            const matchingOtherRows = transformedRows.filter(
                (row: { fund_id: string | undefined; work_item_status: string; }) => row.fund_id !== fund_id && row.work_item_status === WORK_ITEM_STATUS.COMPLETED
            );

            setMatchingRow(matchingCompletedRows);
            setinProgressRows(matchingOtherRows);

            return {
                shouldStop: inProgressRows.length === 0,
                inProgressRowsLength: inProgressRows.length,
                matchingRowsLength: matchingCompletedRows.length,
            };
        } catch (error) {
            setError(t('API_ERROR_MESSAGE'));
            return {
                shouldStop: true,
                inProgressRowsLength: 0,
                matchingRowsLength: 0,
            };
        }
    };

    useEffect(() => {
        const fetchDataOnce = async () => {
            setLoadingData(true);
            setError(null);

            const { shouldStop, inProgressRowsLength, matchingRowsLength } = await fetchData();
            setLoadingData(false);

            if (shouldStop && matchingRowsLength === 0 && inProgressRowsLength === 0) {
                setError(t('NO_WORK_ITEMS_ARE_COMPLETED'));
            } else if (matchingRowsLength > 0 && inProgressRowsLength === 0) {
                setError(t('SOME_WORK_ITEMS_COMPLETED'));
            }
        };

        fetchDataOnce();

        return () => {
            setMatchingRow([]);
            setinProgressRows([]);
            setLoadingData(false);
        };
    }, [asset_id, result_type, fund_group, fund_id]);

    const handleNavigation = () => {
        navigate(`${routes.ADJUSTMENT}/${asset_id}/${fund_group}/${result_type}/${fund_id}/update_complete_adjustment`);
    };

    return (
        <Box>
            <PageTitle
                TITLE="SOD_RECON"
                subtitle="HOLDING_CHECK"
                subtitle2="COMPLETED_ADJUSTMENT"
                back
                full_report="FULL_REPORT"
            />
            <Flex mt="lg" ml="auto" justify="flex-end" mr="2rem">
                <CustomButton variant="create-workitems" onClick={handleNavigation}>
                    {t("UPDATE_ADJUSTMENT")}
                </CustomButton>
            </Flex>
            <Flex direction="column" p="2rem">
                {loadingData ? (
                    <Flex justify="center" align="center" h={'70vh'}>
                        <IconWrapper name="loading-large" />
                    </Flex>
                ) : (
                    <>
                        {matchingRow.length > 0 && matchingRow && (
                            <>
                                {result_type === ReconResultTypes.HOLDINGS_COAC_MISMATCH && (
                                    <Text size="lg" fw={600}>
                                        {t("COMPLETED_COAC_ADJUSTMENT")}
                                    </Text>
                                )}
                                <SodReconTableComplete
                                    data={matchingRow}
                                    completeAdjustment={true}
                                    noCheck={true}
                                />
                            </>
                        )}

                        {inProgressRows.length > 0 && result_type === ReconResultTypes.HOLDINGS_COAC_MISMATCH && (
                            <>
                                <Text size='lg' fw={600} mt={'xxl'}>{t("OTHER_FUND_APPLIED_COAC")}</Text>
                                <SodReconTableComplete
                                    data={inProgressRows}
                                    allowMultipleSelection={false}
                                    completeAdjustment={true}
                                    noCheck={true}
                                    withoutAdditionalRows
                                />
                            </>
                        )}
                    </>
                )}
            </Flex>
        </Box>
    );
};

export default CompleteAdjustment;
