export const SECTIONS_DATA = [
	{
		titleKey: "MOO_ORDERS",
		path: "/market-on-open",
		sectionKey: "marketOnOpenCount",
		background: "rgba(0, 255, 0, 0.1)",
		showCondition: (counts: { marketOnOpenCount: number }) => counts.marketOnOpenCount > 0,
	},
	{
		titleKey: "MARKET_ORDERS",
		path: "/market",
		sectionKey: "marketCount",
		background: "rgba(0, 0, 255, 0.1)",
		showCondition: () => true,
	},

	{
		titleKey: "MOC_ORDERS",
		path: "/market-on-close",
		sectionKey: "marketOnCloseCount",
		background: "rgba(255, 0, 0, 0.1)",
		showCondition: () => true,
	},
	{
		titleKey: "FUTURE_ORDERS",
		path: "/market-on-close-plus-days",
		sectionKey: "marketOnClosePlusDaysCount",
		background: "rgba(0, 0, 0, 0.1)",
		showCondition: () => true,
		hideHeaderCount: true,
	},
];

export type CountsType = {
	marketOnOpenCount: number;
	marketOnOpenCountReady?: number;
	marketOnOpenCountNotReady?: number;
	marketOnOpenCountCompleted?: number;
	marketCount?: number;
	marketCountReady?: number;
	marketCountNotReady?: number;
	marketCountCompleted?: number;
	marketOnCloseCount?: number;
	marketOnCloseCountReady?: number;
	marketOnCloseCountNotReady?: number;
	marketOnCloseCountCompleted?: number;
	marketOnClosePlusDaysCount?: number;
	marketOnClosePlusDaysCountReady?: number;
	marketOnClosePlusDaysCountNotReady?: number;
	marketOnClosePlusDaysCountCompleted?: number;
};
