import { CategorizedChats, ChatHistoryItem, ChatSession } from "../types";

// Adjusted formatHistoryItem to handle optional timestamp and metadata
const formatHistoryItem = (session: ChatSession): ChatHistoryItem => {
    const firstUserMessage = session.messages.find((msg) => msg.user === "user");
    return {
        id: session.id,
        label: firstUserMessage?.label || "New Chat",
        timestamp: session.timestamp || new Date(), // Fallback to current date if timestamp is missing
        metadata: session.metadata || {}, // Ensure metadata is always an object
    };
};

// Adjusted categorizeChats to handle undefined timestamp and metadata
export const categorizeChats = (chatHistory: ChatSession[]): CategorizedChats => {
    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    const sevenDaysAgo = new Date(todayStart);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    return {
        today: chatHistory
            .filter((session) => session.timestamp && session.timestamp >= todayStart) // Handle undefined timestamp
            .map((session) => ({
                ...formatHistoryItem(session),
                metadata: session.metadata || {}, // Ensure metadata is always an object
            })),
        yesterday: chatHistory
            .filter(
                (session) =>
                    session.timestamp &&
                    session.timestamp >= yesterdayStart &&
                    session.timestamp < todayStart
            ) // Handle undefined timestamp
            .map((session) => ({
                ...formatHistoryItem(session),
                metadata: session.metadata || {},
            })),
        last7Days: chatHistory
            .filter(
                (session) =>
                    session.timestamp &&
                    session.timestamp >= sevenDaysAgo &&
                    session.timestamp < yesterdayStart
            ) // Handle undefined timestamp
            .map((session) => ({
                ...formatHistoryItem(session),
                metadata: session.metadata || {},
            })),
    };
};
