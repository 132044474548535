import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import IconWrapper from "../Common/IconWrapper";
import CustomBadge from "./CustomBadge";
import SectionHeader from "./SectionHeader";

interface SidebarItemProps {
	title?: string;
	icon?: string;
	label: string;
	count?: number;
	path: string;
	isSelected: boolean;
	onClick: () => void;
	hideHeaderCount?: boolean;
	isNavItem?: boolean;
	className?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
	title,
	icon,
	label,
	count,
	path,
	isSelected,
	onClick,
	hideHeaderCount,
	isNavItem = false,
	className,
}) => {
	return (
		<div>
			{title && <SectionHeader title={title} count={count} hideCount={hideHeaderCount} />}
			<Link to={path} className={`label-link ${isSelected ? "clicked-row" : ""}`} onClick={onClick}>
				<div
					className='label-row 
                '
				>
					{icon && (
						<IconWrapper name={icon} className={`label-icon ${isNavItem ? "nav-item-icon" : ""}`} />
					)}
					<Text size='sm' fw={500} className='label-text'>
						{label}
					</Text>
					{count !== undefined && <CustomBadge count={count} className='section-count-badge' />}
				</div>
			</Link>
		</div>
	);
};

export default SidebarItem;
