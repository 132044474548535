import { Button, useMantineTheme, Flex, Box } from "@mantine/core";
import { useEffect, useState } from "react";
import axios from "axios";
import AllFundQueryTable from "../../components/AllFundQuery/AllFundQueryTable";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/Common/PageTitle";
import "react-resizable/css/styles.css";
import "./AllFundQuery.css";
import { Preset } from "../../components/AllFundQuery/PresetsOptions2/PresetsOption2";
import { services } from "../../services";
import { mapData, mapDatas } from "../../components/AllFundQuery/PresetsOptions2/PresetsUtils";
import { RecentPresets } from "./RecentPresets";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import { getDateFromSpecifier } from "../../utils/calendarUtils";
import IconWrapper from "../../components/Common/IconWrapper";
import { IconReportSearch } from "@tabler/icons-react";
import { LoaderIcon } from "../../components/Common/LoaderIcon";
import ErrorPage from "../../components/Common/Errors/ErrorPage";

export function AllFundQuery() {
	const theme = useMantineTheme();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [gridData, setGridData] = useState<any[]>([]);
	const [recentPresetsLocal, setRecentPresetsLocal] = useLocalStorage("presets", "");
	const [recentPresets, setRecentPresets] = useState((recentPresetsLocal as string).split(","));
	const [selectedPreset, setSelectedPreset] = useState<string>("");
	const [isPresetOpen, setIsPresetOpen] = useState<boolean>(false);
	const [isLeftNavOpen, setIsLeftNavOpen] = useState<boolean>(true);
	const [typeOfData, setTypeOfData] = useState<string>();

	useEffect(() => {
		setRecentPresetsLocal(recentPresets.join(","));
	}, [recentPresets]);

	const addRecentPreset = (preset: string) => {
		setRecentPresets((existingPresets) => {
			return [preset, ...existingPresets.filter((row) => row !== preset)].slice(0, 3);
		});
	};

	const openPreset = () => {
		setIsLeftNavOpen(true);
		setIsPresetOpen(true);
	};

	const handleSubmit = async ({
		fund_group,
		fund_id,
		asset_id,
		asset_id_type,
		start_date,
		end_date,
		settled,
		on_loan,
		total,
		selectedTypeOfData,
	}: any) => {
		setIsLoading(true);

		const formData = {
			fund_group,
			fund_id,
			asset_id,
			asset_id_type,
			start_date: getDateFromSpecifier(start_date),
			end_date: getDateFromSpecifier(end_date),
			settled,
			on_loan,
			total,
		};

		const filteredFormData = Object.fromEntries(
			Object.entries(formData).filter(
				([key, value]) => value !== "" && value !== " " && value !== undefined,
			),
		);

		let endpoint;
		switch (selectedTypeOfData) {
			case "DATA_TYPE_ORDERS":
				endpoint = services.ORDERS;
				break;
			case "DATA_TYPE_BENCHMARK_CHANGES":
				endpoint = services.BENCHMARK;
				break;
			case "DATA_TYPE_TARGETS_AND_TOLERANCES":
				endpoint = services.TARGETS;
				break;
			case "DATA_TYPE_TRADES":
				endpoint = services.TRADES;
				break;
			case "DATA_TYPE_CASH_FLOWS":
				endpoint = services.CASHFLOW;
				break;
			default:
				endpoint = services.ASSETS;
				break;
		}

		setTypeOfData(selectedTypeOfData);

		try {
			const response = await axios.get(endpoint, {
				params: filteredFormData,
			});

			let mappedData;

			if (response.data.data === null) {
				setErrorMessage("NO_DATA");
			} else {
				if (endpoint === services.TARGETS) {
					mappedData = mapDatas(response.data.data, endpoint);
				} else {
					mappedData = mapData(response.data.data, endpoint);
				}

				setGridData(mappedData);
				setErrorMessage(null);
				console.log("API Response:", response.data);
			}
		} catch (error: any) {
			console.error("Error making API request:", error);
			console.error("Error status:", error.response.status);
			setErrorMessage(error.response.status);
		} finally {
			setIsLoading(false);
		}
	};

	const exportToCSV = () => {
		if (gridData.length === 0) {
			console.warn("No data to export.");
			return;
		}

		const headers = Object.keys(gridData[0]);

		const adjustedHeaders = headers.map((header) =>
			header.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
		);

		const csvContent =
			"data:text/csv;charset=utf-8," +
			adjustedHeaders.join(",") +
			"\n" +
			gridData.map((row) => headers.map((header) => row[header]).join(",")).join("\n");

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "exported_data.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const exportlabel = t("EXPORT");

	const handleRefresh = () => {
		setGridData([]);
		setErrorMessage(null);
	};

	const isNoDataError = ["NO_DATA", 400].includes(errorMessage as string);

	return (
		<div className='all-fund-query-container'>
			<PageTitle TITLE={"ALL_FUND_QUERY"} />
			<Flex className='preset-wrapper' direction='row'>
				<Preset
					onSubmit={handleSubmit}
					addRecentPresets={addRecentPreset}
					selectedPreset={selectedPreset}
					handleRefresh={handleRefresh}
					setErrorMessage={setErrorMessage}
					isPresetOpen={isPresetOpen}
					setIsPresetOpen={setIsPresetOpen}
					isLeftNavOpen={isLeftNavOpen}
					setIsLeftNavOpen={setIsLeftNavOpen}
				/>
				<div className='flex grow flex-column h-screen overflow-hidden smart-query-table-section'>
					{isLoading ? (
						<Box h={"95vh"}>
							<LoaderIcon name='loading-large' alt='loading icon' />
						</Box>
					) : (
						<>
							{gridData.length > 0 ? (
								<>
									<Flex justify='right' mr='2rem' p='lg'>
										<Button
											bg={theme.colors.violet[8]}
											w={147}
											h={30}
											className='buttonspace'
											onClick={exportToCSV}
										>
											{exportlabel}
										</Button>
								
									</Flex>

									<div className='table-alignment'>
										<AllFundQueryTable
											data={gridData}
											isFromHoldingContentTab={true}
											selectedTypeOfData={typeOfData}
											tableConfig={{
												pinnedColumns: [
													"sr_no",
													"bloomberg_ticker",
													"asset_name",
													"fund_id",
													"fund_name",
												],
											}}
										/>
									</div>
								</>
							) : (
								<Flex justify='center' align='center' h='100%'>
									{errorMessage && !isNoDataError ? (
										// <Flex direction='column' className='recent-presets' gap='2.5rem'>
										// 	<Flex align='center' direction='column'>
										// 		<IconReportSearch className='recent-presets-icon' />
										// 	</Flex>
										// 	<Flex align='center' direction='column'>
										// 		<h2 className='recent-presets-title'>{t("NO_MATCHING_RESULT")}</h2>
										// 		<div>{t("CHANGE_QUERY")}</div>
										// 	</Flex>
										// </Flex>
										<ErrorPage errorCode={errorMessage}/>
									) : (
										<RecentPresets
											selectPreset={setSelectedPreset}
											openPreset={openPreset}
											showError={isNoDataError}
										/>
									)}
								</Flex>
							)}
						</>
					)}
				</div>
			</Flex>
		</div>
	);
}
