import { useState } from "react";
import { toast } from "react-toastify";
import { renameDocument, renameDocumentFolder } from "../../components/AllFundQuery/PresetsOptions2/PresetsUtils";

interface UseRenameProps {
	data: any[];
	fetchData: () => Promise<void>;
}

export const useRename = ({ data, fetchData }: UseRenameProps) => {
	const [renamingDocId, setRenamingDocId] = useState<string | null>(null);
	const [renamingFolderId, setRenamingFolderId] = useState<string | null>(null);
	const [newName, setNewName] = useState<string>("");
	const [newNameFolder, setNewNameFolder] = useState<string>("");
	const [renameUuids, setRenameUuids] = useState<string>("");

	const handleRenamePreset = (docId: string) => {
		setRenamingDocId(docId);
		const currentName =
			data.flatMap((item) => item.documents).find((doc) => doc.uuid === docId)?.name || "";
		setNewName(currentName);
	};

	const handleRenameFolder = (folderId: string, folderName: string) => {
		setRenamingFolderId(folderId);

		const matchingFolder = data.find((item) => item._id === folderName);

		if (matchingFolder) {
			const uuids = matchingFolder.documents.map((doc: { uuid: any; }) => doc.uuid).join(",");
			setRenameUuids(uuids);
			setNewNameFolder(folderName);
		} else {
			setNewNameFolder("");
		}
	};

	const handleRenamePresetEvent = async (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		docId: string,
	) => {
		if (
			(e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") ||
			newName.trim() === ""
		) {
			return;
		}
		const check = await renameDocument(docId, newName);
		if (!check) return;
		setNewName("");
		setRenamingDocId(null);
		await fetchData();
	};

	const handleRenameFolderEvent = async (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		folderId: string,
	) => {
		if (
			(e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") ||
			newNameFolder.trim() === ""
		) {
			return;
		}

		try {
			const success = await renameDocumentFolder(folderId, newNameFolder, renameUuids);

			if (success) {
				toast.success("Folder renamed successfully");
				await fetchData();
			} else {
				toast.error("Error renaming folder");
			}
		} catch (error) {
			toast.error("Error renaming folder");
		}

		setRenamingFolderId(null);
		setNewNameFolder("");
	};

	return {
		renamingDocId,
		renamingFolderId,
		newName,
		setNewName,
		newNameFolder,
		setNewNameFolder,
		handleRenamePreset,
		handleRenameFolder,
		handleRenamePresetEvent,
		handleRenameFolderEvent,
	};
};
