import React from 'react';
import { Flex } from '@mantine/core';
import IconWrapper from './IconWrapper';

export const LoaderIcon = ({ name, alt }: { name: string, alt: string, }) => {
	return (
		<>
			<Flex h={'100%'} justify={'center'} align={'center'}>
				<Flex>
					<IconWrapper name={name} alt={alt} />
				</Flex>
			</Flex>
		</>
	);
}