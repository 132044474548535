import { useState, useCallback } from "react";
import { v4 as uuid_v4 } from "uuid";
import axios from "axios";
import { services } from "../../services";
import { toast } from "react-toastify";

interface UseFolderCreationProps {
	fetchData: () => Promise<void>;
	fetchFolderOptions: () => Promise<void>;
	DEFAULT_FOLDER_NAME: string;
}
interface PresetItem {
	user_id: string;
}

export const useFolderCreation = ({ fetchData,fetchFolderOptions, DEFAULT_FOLDER_NAME }: UseFolderCreationProps) => {
	const [creatingFolder, setCreatingFolder] = useState(false);
	const [folderTitle, setFolderTitle] = useState("");

	const createNewDocument = useCallback(
		(uuid: string, folderTitle: string, presetItem?: PresetItem) => ({
			uuid,
			folder_name: folderTitle,
			user_id: presetItem ? presetItem.user_id : "sanjay",
		}),
		[],
	);

	const handleInputKeyPressCreateButton = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && folderTitle.trim() !== "") {
			const uuid = uuid_v4();
			const newDocument = createNewDocument(uuid, folderTitle);

			try {
				await axios.post(`${services.GET_PRESETS}`, [newDocument]);
				setFolderTitle("");
				setCreatingFolder(false);

				await fetchData();
				await fetchFolderOptions();
			} catch (error) {
				console.error("Error creating folder:", error);
			}
		} else if (e.key === "Enter" && folderTitle.trim() === "") {
			toast.error("Folder name cannot be empty");
		}
	};

const handleInputBlur = async () => {
	const finalFolderTitle = folderTitle.trim();

	if (!finalFolderTitle) {
		setCreatingFolder(false);
		setFolderTitle(""); 
		return;
	}

	const uuid = uuid_v4();
	const newDocument = createNewDocument(uuid, finalFolderTitle);

	try {
		await axios.post(`${services.GET_PRESETS}`, [newDocument]);
		setCreatingFolder(false);
		setFolderTitle(""); 
		await fetchData();
		await fetchFolderOptions();
	} catch (error) {
		console.error("Error creating folder:", error);
	}
};

	return {
		creatingFolder,
		setCreatingFolder,
		folderTitle,
		setFolderTitle,
		handleInputKeyPressCreateButton,
		handleInputBlur,
	};
};
