import React from "react";
import { Flex, TextInput } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";

interface StoredFoldersListProps {
	storedFolders: string[];
	theme: any; 
	inputRef: React.RefObject<HTMLInputElement>;
	newEmptyFolderNameDelete: boolean;
	newEmptyFolderName: string;
	setNewEmptyFolderName: React.Dispatch<React.SetStateAction<string>>;
	handleKeyDownEmpty: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	handleBlurSaveEmpty: () => void;
	handleFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const StoredFoldersList: React.FC<StoredFoldersListProps> = ({
	storedFolders,
	theme,
	inputRef,
	newEmptyFolderNameDelete,
	newEmptyFolderName,
	setNewEmptyFolderName,
	handleKeyDownEmpty,
	handleBlurSaveEmpty,
	handleFocus,
}) => {
	return (
		<>
			{storedFolders.length > 0 &&
				storedFolders.map((folder, index) => (
					<div key={index}>
						<Flex mt={"md"} align={"center"}>
							<IconWrapper name='fund-query-preset-folder' className='allfundqueryicon' />
							{newEmptyFolderNameDelete ? (
								<TextInput
									ref={inputRef}
									ml={"0.2rem"}
									size={theme.fontSizes.md}
									c={theme.colors.dark[8]}
									defaultValue={folder}
									onChange={(event) => setNewEmptyFolderName(event.target.value)}
									onKeyDown={handleKeyDownEmpty}
									onBlur={handleBlurSaveEmpty}
									className='inputfolder'
									onFocus={handleFocus}
									autoFocus
								/>
							) : (
								<TextInput
									ml={"0.2rem"}
									size={theme.fontSizes.md}
									c={theme.colors.dark[8]}
									disabled
									className='dropfolder'
									placeholder={folder}
									onDragOver={(event) => event.preventDefault()}
									autoFocus
								/>
							)}
						</Flex>
					</div>
				))}
		</>
	);
};

export default StoredFoldersList;
