import { Box } from "@mantine/core";
import IconWrapper from "../Common/IconWrapper";


export const formatInstrument = (instrument: string) => {
    const instrumentMapping: { [key: string]: string } = {
        STOCK: "Stock",
        ETF: "ETF",
        FUTURES: "Futures",
        FUTURES_ASSET_TYPE_UNSPECIFIED: "Futures",
        EQUITY_INDEX_FUTURES: "Futures",
        CURRENCY_FUTURES: "Futures",
        COMMODITY_FUTURES: "Futures",
        STOCK_FUTURES: "Futures",
        INTEREST_RATE_FUTURES: "Futures",
        VOLATILITY_FUTURES: "Futures",
        BOND_FUTURES: "Futures",
        BOND_INDEX_FUTURES: "Futures",
        SPOT: "Spot"
    };
    return instrumentMapping[instrument] || instrument;
};

export const rightSection = <Box mt={'md'} mr={'sm'}><IconWrapper name='select-dropdown-large' /></Box>

export const getInstrumentOptionsBasedOnAssetClass = (assetGroup: string) => {
    switch (assetGroup.toLowerCase()) {
        case 'equity':
            return ['Stock', 'ETF', 'Futures'];
        case 'futures':
            return ['Futures'];
        case 'currency':
            return ['Spot'];
        default:
            return [];
    }
};

