import React from "react";
import { ActionIcon, Collapse, Grid, Text } from "@mantine/core";
import { IconPaperclip } from "@tabler/icons-react";
import IconWrapper from "../../Common/IconWrapper";

interface FolderManagementHeaderProps {
	isPresetOpen: boolean;
	isLeftNavOpen: boolean;
	togglePreset: () => void;
	setCreatingFolder: (value: boolean) => void;
	theme: any;
}

const FolderManagementHeader: React.FC<FolderManagementHeaderProps> = ({
	isPresetOpen,
	isLeftNavOpen,
	togglePreset,
	setCreatingFolder,
	theme,
}) => {
	return (
		
			<Grid
				columns={12}
				style={{
					height: "44px",
					marginLeft: "16px",
					marginTop: "7px",
					width: "90%",
				}}
			>
				<Grid.Col
					span={2}
					style={{
						display: "flex",
						alignItems: "center",
						zIndex:"2"
					}}
				>
					<ActionIcon variant='transparent' color={theme.colors.grey[8]} onClick={togglePreset}>
						<IconPaperclip size='1.2rem' />
					</ActionIcon>
				</Grid.Col>

				<Grid.Col
					span={8}
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "center",
					}}
				>
					<Text
						fw='700'
						size={theme.fontSizes.md}
						c={theme.colors.dark[8]}
						style={{
							visibility: isPresetOpen ? "visible" : "hidden",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							color: "red",
						}}
					>
						PRESETS
					</Text>
				</Grid.Col>

				<Grid.Col
					span={2}
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						marginLeft: "-37px",
						color: "red"
					}}
				>
					<ActionIcon
						variant='transparent'
						color={theme.colors.grey[8]}
						onClick={() => setCreatingFolder(true)}
						style={{ visibility: isPresetOpen ? "visible" : "hidden" }}
					>
						<IconWrapper name='create-folder-preset' className='allfundqueryicon' size="sm"/>
					</ActionIcon>
				</Grid.Col>
			</Grid>
	);
};

export default React.memo(FolderManagementHeader);
