import React, { useEffect, useState } from "react";
import { Modal, Text, Flex, TextInput } from "@mantine/core";
import CustomButton from "../Common/Buttons/CustomButton";
import "./SavePresetModal.css";
import { t } from "i18next";
import {CustomSelectBox} from "../Common/Select/CustomSelectBox";
import IconWrapper from "../Common/IconWrapper";
import { useFolderOptions } from "../../utils/hooks/useFolderOptions";

interface SavePresetModalProps {
	isOpen: boolean;
	onClose: () => void;
	presetName: string;
	setPresetName: (value: string) => void;
	handleSave: () => void;
	handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	selectedFolderName: string;
	setSelectedFolderName: (value: string) => void;
}

const SavePresetModal: React.FC<SavePresetModalProps> = ({
	isOpen,
	onClose,
	presetName,
	setPresetName,
	handleSave,
	handleKeyPress,
	selectedFolderName,
	setSelectedFolderName,
}) => {
	const {
		folderOptions = [],
		isLoading,
		checkDuplicatePreset,
		fetchFolderOptions,
	} = useFolderOptions(); 
	const [isDuplicate, setIsDuplicate] = useState(false);

	useEffect(() => {
		const trimmedPresetName = presetName.trim();
		const trimmedFolderName = selectedFolderName.trim();

		if (trimmedPresetName && trimmedFolderName) {
			const isDuplicatePreset = checkDuplicatePreset(trimmedPresetName, trimmedFolderName);
			setIsDuplicate(isDuplicatePreset || false);
		} else {
			setIsDuplicate(false);
		}
	}, [presetName, selectedFolderName, checkDuplicatePreset]);

	useEffect(() => {
		if (isOpen) {
			fetchFolderOptions();
		}
	}, [isOpen, fetchFolderOptions]);

	useEffect(() => {
		if (isOpen && folderOptions.length > 0) {
			const defaultFolder = folderOptions.find((option) => option.label === "Favorites");
			if (defaultFolder && selectedFolderName === "") {
				setSelectedFolderName(defaultFolder.value);
			}
		}
	}, [isOpen, folderOptions, selectedFolderName, setSelectedFolderName]);

	const handleSaveClick = () => {
		if (!presetName.trim()) {
			console.warn("Preset name cannot be empty.");
			return;
		}

		if (isDuplicate) {
			console.warn("Preset name already exists in the selected folder.");
			return;
		}

		handleSave();
	};

	return (
		<Modal
			opened={isOpen}
			onClose={onClose}
			centered
			withCloseButton={false}
			radius='30px'
			className='modal'
			p='xl'
			size='md'
		>
			<Text ta='center' size='lg' fw={600} mt='md'>
				{t("SAVE_THIS_AS_A_PRESET_TO")} :
			</Text>

			{!isLoading && (
				<div className='custom-folder-icons-select'>
					<CustomSelectBox
						data={folderOptions}
						value={selectedFolderName}
						onChange={(value) => setSelectedFolderName(value || "")}
						variant='transparent'
						mt='xl'
						placeholder=''
						className='presetText'
						w='22.75rem'
						ml='1.5rem'
						leftSection={
							<IconWrapper
								name={
									folderOptions.find((opt) => opt.value === selectedFolderName)?.label ===
									"Favorites"
										? "favourites"
										: "fund-query-preset-folder"
								}
								className={
									folderOptions.find((opt) => opt.value === selectedFolderName)?.label ===
									"Favorites"
										? "favorites-icon"
										: "folder-icon"
								}
							/>
						}
					/>
				</div>
			)}

			<TextInput
				value={presetName}
				onChange={(event) => setPresetName(event.currentTarget.value)}
				ta='left'
				placeholder={t("ENTER_PRESET_NAME")}
				onKeyPress={handleKeyPress}
				mt='lg'
				w='22.75rem'
				className='presetText'
				ml='1.5rem'
			/>
			{isDuplicate && (
				<Flex justify='center' align='center' mt='xs'>
					<Text c='red' size='sm'>
						{t("PRESET_NAME_ALREADY_EXISTS")}
					</Text>
				</Flex>
			)}
			<Flex justify='right' align='center' mt='xl' mb='2.2rem' mr='20px'>
				<CustomButton variant='cancel' onClick={onClose}>
					{t("CANCEL")}
				</CustomButton>
				<CustomButton
					variant='submit'
					className='save-button'
					onClick={handleSaveClick}
					disabled={!presetName.trim() || isDuplicate}
				>
					{t("SAVE")}
				</CustomButton>
			</Flex>
		</Modal>
	);
};

export default SavePresetModal;
