import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextInput } from '@mantine/core';

interface AssetTextInputProps {
  label: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  errorField?: boolean;
  errorMessage?: string;
  className?: string;
  min?: number;
  placeholder?: string;
  autoComplete?: 'CURRENCY' | 'COUNTRY';
}

const AssetTextInput: React.FC<AssetTextInputProps> = ({
  label,
  value = '',
  onChange,
  onBlur,
  errorField = false,
  errorMessage = 'This field is required',
  className,
  min,
  placeholder,
  autoComplete,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get('/api/countries');
        const { countries, currencies } = response.data.data as {
          countries: { country?: string; currency?: string }[];
          currencies: { currency: string }[];
        };
  
        if (autoComplete === 'CURRENCY') {
          const uniqueCurrencies = Array.from(
            new Set(currencies.map((c) => c.currency))
          ).sort();
          setSuggestions(uniqueCurrencies);
        } else if (autoComplete === 'COUNTRY') {
          const uniqueCountries = Array.from(
            new Set(countries.map((c) => c.country).filter((value): value is string => !!value))
          ).sort();
          setSuggestions(uniqueCountries);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
  
    if (autoComplete) {
      fetchSuggestions();
    }
  }, [autoComplete]);

  useEffect(() => {
    const filtered = suggestions.filter((item) =>
      item.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  }, [value, suggestions]);

  // Check if the value exactly matches any suggestion
  const isExactMatch = filteredSuggestions.some(
    (item) => item.toLowerCase() === value.toLowerCase()
  );

//   const dynamicPlaceholder = filteredSuggestions.length > 0 && !isExactMatch ? filteredSuggestions[0] : placeholder;

  return (
    <div style={{ position: 'relative' }}>
      <TextInput
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        min={min}
        placeholder={placeholder}
        autoComplete="off"
      />
      {value.length > 0 && filteredSuggestions.length > 0 && !isExactMatch && (
        <ul
          style={{
            margin: '4px 0',
            padding: '0',
            listStyle: 'none',
            maxHeight: '4rem',
            overflowY: 'auto',
            position: 'absolute',
            top: '100%',
            left: '0',
            right: '0',
            backgroundColor: 'white',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            zIndex: 1000,
          }}
        >
          {filteredSuggestions.map((item) => (
            <li
              key={item}
              style={{
                padding: '4px 8px',
                cursor: 'pointer',
                maxHeight: '4rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={() => onChange({ target: { value: item } } as React.ChangeEvent<HTMLInputElement>)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
      {errorField && <span className="error-message-fields">{errorMessage}</span>}
    </div>
  );
};

export default AssetTextInput;