import React from "react";
import { Box } from "@mantine/core";
import { ChatMessage } from "../../types";
import { ChatBubble } from "./ChatBubble";
import { ChatTextInput } from "./ChatTextInput";

interface ChatContentProps {
	messages: ChatMessage[];
	input: string;
	setInput: (value: string) => void;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	onSendMessage?: () => void;
	isLoading?: boolean;
}

export const ChatContent: React.FC<ChatContentProps> = ({
	messages,
	input,
	setInput,
	handleKeyPress,
	onSendMessage,
	isLoading = false,
}) => {
	return (
		<Box className='chat-screen'>
			<Box className='chat-container'>
				{messages.map((message) => (
					<ChatBubble key={message.id} message={message} />
				))}
			</Box>
			<Box className='chat-scroll-screen'>
				<ChatTextInput
					input={input}
					setInput={setInput}
					handleKeyPress={handleKeyPress}
					onSendMessage={onSendMessage}
					disabled={isLoading}
				/>
			</Box>
		</Box>
	);
};
