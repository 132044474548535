import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FC, Suspense, useEffect, useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "../Agtablehomepage/Agstyles.css";
import { useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import "./ThresoldLeft.css";
import { calculateColorShade } from "../../utils/colorUtils";
import { directPercentage } from "../../utils/conversions";
import { LoaderIcon } from "../Common/LoaderIcon";
import CustomTable from "../CustomTable/CustomTable";
import { rowHeightLarge } from "../CustomTable/utilsAgTable";
import { ThresoldLeftProps } from "../../types";
import IconWrapper from "../Common/IconWrapper";
import { nonPercentageFields } from "../../utils/filterOrders";

export const ThresoldRight: FC<ThresoldLeftProps> = ({ rowData }) => {
	const { t, i18n } = useTranslation();
	const theme = useMantineTheme();
	const [languageKey, setLanguageKey] = useState(i18n.language);

	useEffect(() => {
		setLanguageKey(i18n.language);
	}, [i18n.language]);

	function isNonPercentageField(key: string): boolean {
		return nonPercentageFields.includes(key);
	}

	const generateColumnsFromData = (data: any) => {
		const columns: ColDef[] = [];

		Object.keys(data).forEach((key, index) => {
			const isOutOfTolerance = data[key]?.is_out_of_tolerance ?? false;

			const column: ColDef = {
				field: key,
				headerName: t(key),
				width: 120,
				headerClass: "ag-right-aligned-header custom-header",
				resizable: true,
				initialWidth: 200,
				wrapHeaderText: true,
				autoHeaderHeight: true,
				cellClass: "spendCashCell",
				cellStyle: isOutOfTolerance
					? (params: any) => {
						const fieldValue = parseFloat(params.data[key]?.stats ?? 0);
						const violatedTolerance = parseFloat(params.data[key]?.violated_tolerance ?? 0);

						return {
							background: calculateColorShade(fieldValue, violatedTolerance, theme),
							textAlign: "right",
						};
					}
					: { backgroundColor: theme.colors.yellow[0] },
			};

			column.cellRenderer = (params: any) => {
				const fieldValue = parseFloat(params.data[key]?.stats ?? 0);
				if (isNonPercentageField(key)) {
					return (
						<div className="spendcash">
							{fieldValue}
						</div>
					);
				} else {
					return (
						<div className="spendcash">
							{directPercentage(fieldValue)}
						</div>
					);
				}
			};

			columns.push(column);
		});

		return columns;
	};


	const colDefs = useMemo(() => {
		if (rowData.length === 0) {
			return [];
		}

		const firstRow = rowData[0];
		return generateColumnsFromData(firstRow);
	}, [rowData, t, theme]);

	const maxColumns = colDefs.length;

	const calculateMaxWidth = (numColumns: number): number => {
		return numColumns * 120;
	};

	const tableWidth = useMemo(() => calculateMaxWidth(maxColumns), [maxColumns]);

	const calculateMaxHeight = (numRows: number): { height: number; borderBottom: string } => {
		switch (numRows) {
			case 1:
				return { height: 90, borderBottom: "1px solid #ccc" };
			default:
				return { height: 40 * numRows, borderBottom: "1px solid #ccc" };
		}
	};

	const tableHeight = useMemo(() => calculateMaxHeight(rowData.length), [rowData.length]);

	return (
		<Suspense fallback={<div><IconWrapper name='loading-large' /></div>}>
			{rowData.length > 0 ? (
				<div
					className="ag-theme-quartz"
					style={{
						width: `${tableWidth}px`,
						maxWidth: "33rem",
						overflow:
							tableHeight.height > calculateMaxHeight(8).height
								? "auto"
								: "hidden",
						borderBottom: tableHeight.borderBottom,
						marginTop: theme.spacing.sm,
					}}
				>
					<CustomTable rowData={rowData} columnDefs={colDefs} rowHeight={rowHeightLarge} domLayout="autoHeight"
						maxConcurrentDatasourceRequests={1}
						maxBlocksInCache={1} largeHeader />
				</div>
			) : (
				<div> </div>
			)}
		</Suspense>
	);
};
function isNonPercentageField(key: string) {
	throw new Error("Function not implemented.");
}

