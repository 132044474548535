import { Box, Tabs, useMantineTheme } from "@mantine/core";
import styles from "../../pages/CashExposure/CashTabs.module.css";

const TabsComponent = ({
	value,
	onChange,
	tabs,
}: {
	value: string;
	onChange: (value: string | null) => void;
	tabs: { label: string; value: string; count?: number }[];
}) => {
	const theme = useMantineTheme();

	const handleTabChange = (newValue: string | null) => {
		if (newValue) {
			const tabLabel = tabs.find((tab) => tab.value === newValue)?.value;
			if (tabLabel) {
				const newUrl = `${window.location.pathname}?tab=${encodeURIComponent(tabLabel)}`;
				window.history.pushState({}, "", newUrl);
			}
		}
		onChange(newValue);
	};

	return (
		<Box>
			<Tabs
				value={value}
				onChange={handleTabChange}
				className="fund-order-tabs"
				styles={{
					tab: {
						marginRight: 67,
						"&:last-of-type": {
							marginLeft: 0,
						},
					},
				}}
			>
				<Tabs.List>
					{tabs.map((tab) => (
						<Tabs.Tab
							key={tab.value}
							value={tab.value}
							color={theme.colors.violet[8]}
							className={`fund-order-tabs ${styles.tab} ${
								value === tab.value ? styles.activeTab : ""
							} `}
						>
							<span className={value === tab.value ? styles.activeText : styles.inactiveText}>
								{tab.label}
							</span>
						</Tabs.Tab>
					))}
				</Tabs.List>
			</Tabs>
		</Box>
	);
};

export default TabsComponent;
