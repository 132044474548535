import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { LoginAuthBody, User } from "../types";
import { services } from "../services";
import { NavigateFunction } from "react-router-dom";
import { isAdmin } from "../utils/validations";
import { routes } from "../routes";
import { refreshInterval } from "../utils/authConstants";

interface UserState {
	loading: boolean;
	authenticated: boolean;
	user: User | null;
	error: boolean;
	errorMessage: string;
	loginCounter: number;
	fundGroup: string | null;
	permissions: string[] | null;
	workItemsLength: number;
}

interface LoginPayload {
	username: string;
	password: string;
	navigate: NavigateFunction;
}

const initialState: UserState = {
	loading: false,
	authenticated: false,
	user: null,
	error: false,
	errorMessage: "",
	loginCounter: 0,
	fundGroup: null,
	permissions: null,
	workItemsLength: 0,
};

let tokenRefreshInterval: NodeJS.Timeout | null = null;

export const refreshToken = createAsyncThunk(
	"user/refreshToken",
	async (_, { dispatch }) => {
		try {
			const refreshToken = localStorage.getItem("athena_R");
			if (!refreshToken) throw new Error("No refresh token available");

			const res = await axios.post(
				services.GET_TOKENS,
				{ refresh_token: refreshToken },
				{ headers: { "Content-Type": "application/x-www-form-urlencoded" } }
			);

			const newAccessToken = res.data.access_token;
			const newRefreshToken = res.data.refresh_token;

			localStorage.setItem("athena_A", newAccessToken);
			localStorage.setItem("athena_R", newRefreshToken);

			axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
		} catch (error) {
			console.error("Failed to refresh token:", error);
			dispatch(clearUser());
			localStorage.removeItem("athena_A");
			localStorage.removeItem("athena_R");
			if (tokenRefreshInterval) clearInterval(tokenRefreshInterval);
		}
	}
);

export const login = createAsyncThunk(
	"user/login",
	async (payload: LoginPayload, { dispatch }) => {
		try {
			const { username, password, navigate } = payload;

			dispatch(startLoading());
			const body: LoginAuthBody = {
				client_id: process.env.REACT_APP_CLIENT_ID!,
				client_secret: process.env.REACT_APP_CLIENT_SECRET!,
				grant_type: "password",
				username,
				password,
			};

			const res = await axios.post(services.GET_TOKENS, body, {
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				baseURL: "/",
			});

			const accessToken = res.data.access_token;
			const refreshToken = res.data.refresh_token;

			localStorage.setItem("athena_A", accessToken);
			localStorage.setItem("athena_R", refreshToken);

			axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

			const decodedToken: any = jwtDecode(accessToken);
			dispatch(
				setUser({
					username: decodedToken.preferred_username,
					givenName: decodedToken.given_name,
					fullName: decodedToken.name,
					email: decodedToken.email,
					roles: decodedToken.permissions[0].role,
					order_limit: decodedToken.permissions[0].orders_limit,
				})
			);

			const fundGroupJwt = decodedToken?.permissions.map((perm: any) => ({
				fundGroup: perm.fund_group,
				permissions: perm.fund_group_permissions,
				orderLimit: perm.orders_limit,
			}));

			if (fundGroupJwt) {
				localStorage.setItem("fundGroup", JSON.stringify(fundGroupJwt));
			}

			if (isAdmin(decodedToken.permissions[0].role)) {
				navigate(routes.USERS);
			} else {
				navigate(routes.HOME);
			}

			dispatch(resetLoginCounter());

			if (tokenRefreshInterval) clearInterval(tokenRefreshInterval);
			tokenRefreshInterval = setInterval(() => {
				dispatch(refreshToken());
			}, refreshInterval);
		} catch (error) {
			dispatch(clearUser());
			dispatch(setError("Failed to log in. Please try again."));
		} finally {
			dispatch(stopLoading());
		}
	}
);

export const logout = createAsyncThunk("user/logout", async (_, { dispatch }) => {
	dispatch(clearUser());
	localStorage.removeItem("athena_A");
	localStorage.removeItem("athena_R");
	delete axios.defaults.headers.common.Authorization;

	if (tokenRefreshInterval) {
		clearInterval(tokenRefreshInterval);
		tokenRefreshInterval = null;
	}
});

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.authenticated = true;
		},
		clearUser: (state) => {
			state.user = null;
			state.authenticated = false;
		},
		setError: (state, action) => {
			state.error = true;
			state.errorMessage = action.payload;
		},
		clearError: (state) => {
			state.error = false;
			state.errorMessage = "";
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		resetLoginCounter: (state) => {
			state.loginCounter = 0;
		},
		increaseLoginCounter: (state) => {
			state.loginCounter++;
		},
		setWorkItemsLength: (state, action) => {
			state.workItemsLength = action.payload;
		},

	},
});

export const {
	setUser,
	clearUser,
	setError,
	clearError,
	startLoading,
	stopLoading,
	resetLoginCounter,
	increaseLoginCounter,
	setWorkItemsLength,
} = userSlice.actions;

export default userSlice.reducer;
