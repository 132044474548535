import { Button, Flex, TextInput, Grid, Box } from "@mantine/core";
import SavePresetModal from "../SavePresetModal";
import { t } from "i18next";
import React, { useCallback, useEffect } from "react";
import FormField from "./FormField";
import CheckboxGroup from "./CheckboxGroup";
import SelectInput from "./SelectInput";
import DateSelector from "./DateSelector";
import clsx from "clsx";
import { useFolderOptions } from "../../../utils/hooks/useFolderOptions";

export const QueryForm = (props: any) => {
	const {
		fundGroup,
		setFundGroup,
		fundId,
		setFundId,
		assetIdType,
		setAssetIdType,
		assetId,
		setAssetId,
		selectedTypeOfData,
		setSelectedTypeOfData,
		selectedFromDate,
		setSelectedFromDate,
		selectedToDate,
		setSelectedToDate,
		toggleSavePresetModal,
		handleSubmit,
		presetName,
		setPresetName,
		isSavePresetModalOpen,
		handleSave,
		selectedFolderName,
		setSelectedFolderName,
	} = props;

	const [checkboxState, setCheckboxState] = React.useState<{
		settled: boolean;
		onLoan: boolean;
		total: boolean;
		approved: boolean;
		pending: boolean;
		executed: boolean;
		confirmed: boolean;
		unsettled: boolean;
	}>({
		settled: false,
		onLoan: false,
		total: false,
		approved: false,
		pending: false,
		executed: false,
		confirmed: false,
		unsettled: false,
	});

	const checkboxConfig: Record<string, { key: keyof typeof checkboxState; label: string }[]> = {
		DATA_TYPE_ASSETS: [
			{ key: "settled", label: "SETTLED" },
			{ key: "onLoan", label: "ON_LOAN" },
			{ key: "total", label: "TOTAL" },
		],
		DATA_TYPE_ORDERS: [
			{ key: "approved", label: "APPROVED" },
			{ key: "pending", label: "PENDING" },
		],
		DATA_TYPE_TRADES: [
			{ key: "executed", label: "EXECUTED" },
			{ key: "confirmed", label: "CONFIRMED" },
			{ key: "unsettled", label: "UNSETTLED" },
		],
		INDEX_CHANGE: [],
		TARGETS_TOLS: [],
	};

	const toggleCheckboxState = (key: keyof typeof checkboxState) => {
		setCheckboxState((prevState) => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};
	const { folderOptions, isLoading, checkDuplicatePreset } = useFolderOptions();

	const handleKeyPress = useCallback(
		(event: { key: string }) => {
			if (event.key === "Enter") handleSave();
		},
		[handleSave],
	);

	useEffect(() => {
		console.log("selectedTypeOfData", selectedTypeOfData);
	}, [selectedTypeOfData]);

	const isDisabled = !((fundGroup && selectedTypeOfData) || (fundId && selectedTypeOfData));

	return (
		<div className='fund-query-container'>
			<Grid columns={10} mb='sm'>
				<FormField
					label={t("FUND_GROUP")}
					input={
						<TextInput size='xs' radius='sm' variant='filled' placeholder={t("Ex: ALL-INDEX")} />
					}
					inputProps={{
						value: fundGroup,
						onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
							setFundGroup(e.currentTarget.value),
						disabled: !!fundId,
					}}
					pb={3}
				/>
				<FormField
					label={t("FUND_ID")}
					input={
						<TextInput
							size='xs'
							radius='sm'
							variant='filled'
							placeholder={t("Ex: ACU_001, ACU_R1")}
						/>
					}
					inputProps={{
						value: fundId,
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFundId(e.currentTarget.value),
						disabled: !!fundGroup,
					}}
					pb={27}
				/>
				<FormField
					label={t("ASSET_ID_TYPE")}
					input={
						<SelectInput
							label={t("ASSET_ID_TYPE")}
							data={[
								{ value: "ASSET_KEY_TYPES_SEDOL", label: t("SEDOL") },
								{ value: "ASSET_KEY_TYPES_CUSIP", label: t("CUSIP") },
								{ value: "ASSET_KEY_TYPES_ISIN", label: t("ISIN") },
								{ value: "ASSET_KEY_TYPES_BLOOMBERG_TICKER", label: t("BLOOMBERG_TICKER") },
								{ value: "acumain_asset_id", label: t("ACUMAIN_ASSET_ID") },
							]}
							value={assetIdType}
							onChange={setAssetIdType}
							placeholder={t("Select Asset ID Type")}
							size='xs'
						/>
					}
				/>
				<FormField
					label={t("ASSET_ID")}
					input={<TextInput size='xs' radius='sm' variant='filled' />}
					inputProps={{
						value: assetId,
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => setAssetId(e.currentTarget.value),
					}}
					pb={28}
				/>
				<FormField
					label={t("TYPE_OF_DATA")}
					input={
						<>
							<SelectInput
								label={t("TYPE_OF_DATA")}
								data={[
									{ value: "DATA_TYPE_ASSETS", label: t("HOLDINGS") },
									{ value: "DATA_TYPE_ORDERS", label: t("ORDERS") },
									{ value: "DATA_TYPE_TRADES", label: t("TRADES") },
									{ value: "DATA_TYPE_BENCHMARK_CHANGES", label: t("INDEX_CHANGE") },
									{ value: "DATA_TYPE_TARGETS_AND_TOLERANCES", label: t("TARGETS_TOLS") },
									{ value: "DATA_TYPE_CASH_FLOWS", label: t("CASHFLOWS") },
								]}
								value={selectedTypeOfData}
								onChange={(value) => setSelectedTypeOfData(value || "DATA_TYPE_ASSETS")}
								placeholder={t("Select Type of Data")}
							/>
							<CheckboxGroup
								checkboxConfig={checkboxConfig[selectedTypeOfData] || []}
								checkboxState={checkboxState}
								onCheckboxChange={toggleCheckboxState}
							/>
						</>
					}
					pb={30}
				/>
				<FormField
					label={t("FROM")}
					input={
						<DateSelector
							label='FROM'
							selectedDate={selectedFromDate}
							onSelect={setSelectedFromDate}
							placeholder={t("Select Date")}
						/>
					}
				/>
				<FormField
					label={t("TO")}
					input={
						<DateSelector
							label='TO'
							selectedDate={selectedToDate}
							onSelect={setSelectedToDate}
							placeholder={t("Select Date")}
						/>
					}
				/>
			</Grid>

			<div className='buttonsection'>
				<Flex
					direction='column'
					align='center'
					justify='center'
					mt='lg'
					className='button-container'
				>
					<Button className='get-data-button' onClick={handleSubmit} disabled={isDisabled}>
						{t("GET_DATA")}
					</Button>

					<Button
						className={clsx("save-preset-button", { "custom-disabled": isDisabled })}
						onClick={toggleSavePresetModal}
						disabled={isDisabled}
					>
						{t("SAVE_AS_PRESET")}
					</Button>
				</Flex>
			</div>
			<SavePresetModal
				isOpen={isSavePresetModalOpen}
				onClose={toggleSavePresetModal}
				presetName={presetName}
				setPresetName={setPresetName}
				handleSave={handleSave}
				selectedFolderName={selectedFolderName}
				setSelectedFolderName={setSelectedFolderName}
				handleKeyPress={handleKeyPress}
			/>
		</div>
	);
};
