export const routes = {
	HOME: "/",
	LOGIN: "/login",
	MARKET_ON_OPEN: "/market-on-open",
	MARKET: "/market",
	MARKET_ON_CLOSE: "/market-on-close",
	MARKET_FUTURE_DAYS: "/market-on-close-plus-days",
	FUNDS: "/funds",
	FUNDSDATA: "/fund-data",
	ALLFUNDQUERY: "/fund-query",
	ORDERCOMPLETION: "/order-completion",
	DEMOSTARTER: "/demo-starter",
	ORDERCLAIM: "/order-claim",
	ORDERAPPROVAL: "/order-approval",
	ALLFUNDSTATUS: "/all-fund-status",
	ORDERCLAIMSTATUS: "/order-claim-status",
	COACMANAGEMENT: "/coacmanagement",
	USERS: "/admin-page",
	MANAGE_FUNDS: "/manage-funds",
	PREFERENCES: "/preferences",
	UPDATES: "/updates",
	SUPPORT: "/support",
	DASHBOARD: "/dashboard",
	COMING: "/funds",
	COMINGPREFERENCE: "/preference",
	COMINGUPDATES: "/updates",
	COMINGSUPPORT: "/support",
	ACCOUNT: "/account",
	SODRECON: "/sod-recon",
	COMPLETEDRECON: "/completed-recon",
	CASHEXPOSURE: "api/cash-exposures",
	CREATE_FUND: "/create-fund",
	INVESTMENTS: "/investments",
	CREATE_ASSETS: "/assets",
	AIASSISTANT: "/ai-assistant",
	ADJUSTMENT: "/adjustment"
};
