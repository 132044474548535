import axios from "axios";
import { RefreshTokenAuthBody } from "../types";
import { services } from "../services";

export const fetchNewAccessToken = async (refreshToken: string): Promise<any> => {
	try {
		const newInstance = axios.create({
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			baseURL: "/",
		});
		const body: RefreshTokenAuthBody = {
			client_id: process.env.REACT_APP_CLIENT_ID!,
			client_secret: process.env.REACT_APP_CLIENT_SECRET!,
			grant_type: "refresh_token",
			refresh_token: refreshToken,
		};
		const res = await newInstance.post(services.GET_TOKENS, body);
		return res.data;
	} catch (err) {
		throw new Error(`error in fetchAccessToken ${err}`);
	}
};
