import React from "react";
import { Select } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";

interface SelectInputProps {
	label: string;
	data: { value: string; label: string }[];
	value: string;
	onChange: (value: string | null) => void;
	placeholder?: string;
	style?: React.CSSProperties; 
	size?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ style, data, value, onChange, placeholder }) => {
	const rightSection = (
		<div>
			<IconWrapper name='small-dropdown' className='small-dropdown' />
		</div>
	);

	return (
		<Select
			data={data}
			value={value}
			onChange={onChange}
			rightSection={rightSection}
			size=""
		/>
	);
};

export default SelectInput;
