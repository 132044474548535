import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { t } from "i18next";
import { services } from "../../../services";
import { aiService } from "../../../pages/AIAssistant/aiService";

interface ChatMessage {
	user: string;
	text: string;
	id: string;
	timestamp: Date;
	label: string;
}

interface ChatSession {
	id: string;
	messages: ChatMessage[];
	timestamp: Date;
	metadata?: any;
}

interface ChatState {
	currentSession: ChatSession;
	chatHistory: ChatSession[];
	input: string;
	error: string | null;
	isLoading: boolean;
	docId: string;
	metadata: any;
	isChatMessagesLoaded: boolean;
	isSummaryLoaded: boolean;
	thinkingMessageId?: string; 
}

const initialState: ChatState = {
	currentSession: {
		id: uuidv4(),
		messages: [],
		timestamp: new Date(),
	},
	chatHistory: [],
	input: "",
	error: null,
	isLoading: false,
	docId: "",
	metadata: null,
	isChatMessagesLoaded: false,
	isSummaryLoaded: false,
};

const createMessage = (text: string, user: string): ChatMessage => ({
	user,
	text,
	id: uuidv4(),
	timestamp: new Date(),
	label: text.substring(0, 30) + "...",
});

export const fetchInitialData = createAsyncThunk(
	"chat/fetchInitialData",
	async (
		{ query, createNewSession }: { query: string; createNewSession: boolean },
		{ getState, dispatch },
	) => {
		const state: any = getState();
		if (state.chat.isSummaryLoaded && state.chat.currentRequest === query) {
			return;
		}
		const existingChat = state.chat.chatHistory.find(
			(chat: ChatSession) => chat.metadata?.title === query,
		);

		if (!createNewSession && existingChat) {
			dispatch(loadChatSession(existingChat.id));
			return existingChat;
		}

		try {
			const response = await aiService(query);
			const { data, ticker_symbol } = response;

			const summaryMetaData = data?.metadata || {};
			const summary = Array.isArray(data.summary)
				? data.summary.map((item: { details: string }) => item.details).join(", ")
				: data.summary || "NO_DATA_ERROR";

			const chatHistoryResponse = await axios.post("api/chat-history", {
				user_id: "sanjay",
				user_name: "sanjay",
				chat_date: new Date().toISOString(),
				last_activity_time: new Date().toISOString(),
				metadata: summaryMetaData,
			});

			const chatIdFromHistory = chatHistoryResponse.data.data.chat_id;

			await axios.post(`api/chat-messages/${chatIdFromHistory}`, {
				messages: [
					{
						question: ticker_symbol,
						answer: summary,
					},
				],
			});

			dispatch(loadChatHistoryFromAPI());

			return {
				summary,
				ticker_symbol,
				metadata: summaryMetaData,
				chatIdFromHistory,
			};
		} catch (error) {
			console.error("Error in fetchInitialData:", error);
			throw error;
		}
	},
);

export const loadChatHistoryFromAPI = createAsyncThunk("chat/loadHistoryFromAPI", async () => {
	const response = await axios.get("api/chat-list");
	return response.data?.data || [];
});

export const sendChatMessage = createAsyncThunk(
	"chat/sendMessage",
	async (
		{ input, docId }: { input: string; docId?: string },
		{ getState, dispatch, rejectWithValue },
	) => {
		try {
			const state: any = getState();
			const summaryMetaData = state.chat.metadata;
			const currentSessionId = state.chat.currentSession.id;

			const userMessage = createMessage(input, "user");
			dispatch(chatSlice.actions.addUserMessage(userMessage));
			const thinkingMessage = createMessage("Give me a moment...", "assistant");
			dispatch(chatSlice.actions.addThinkingMessage(thinkingMessage));

			const chatResponse = await axios.post(`${services.CHAT_API}?ticker_symbol=${docId || ""}`, {
				question: input.trim(),
			});

			let answer = "NO_DATA_ERROR";
			if (chatResponse.data && chatResponse.data[0]) {
				if (Array.isArray(chatResponse.data[0]?.answer)) {
					answer = chatResponse.data[0].answer
						.map((item: { details: string }) => item.details)
						.filter(Boolean)
						.join(", ");
				} else if (chatResponse.data[0]?.answer) {
					answer = chatResponse.data[0].answer;
				}
			}

			const assistantMessage = createMessage(answer, "assistant");

			try {
				const chatMessagesResponse = await axios.get(`api/chat-messages/${currentSessionId}`);
				const existingMessages = chatMessagesResponse.data?.data?.messages || [];

				const newMessage = {
					question: input,
					answer: answer,
					error: "",
				};

				const combinedMessages = existingMessages.filter(
					(msg: { question: any; answer: any }) => msg && (msg.question || msg.answer),
				);

				combinedMessages.unshift(newMessage);

				await axios.post(`api/chat-messages/${currentSessionId}`, {
					messages: combinedMessages,
				});

				await axios.post(`api/chat-history`, {
					user_id: "sanjay",
					user_name: "sanjay",
					chat_id: currentSessionId,
					chat_date: state.chat.currentSession.timestamp,
					last_activity_time: new Date().toISOString(),
					metadata: summaryMetaData,
				});
			} catch (error) {
				console.error("Error updating chat messages:", error);
			}

			return {
				assistantMessage,
				thinkingMessageId: thinkingMessage.id, 
				metadata: summaryMetaData,
				chatDate: state.chat.currentSession.timestamp,
				lastActivityTime: new Date().toISOString(),
			};
		} catch (error: any) {
			const errorMessage = error.response?.data?.message || error.message || "ERROR";
			return rejectWithValue(errorMessage);
		}
	},
);
export const loadChatHistory = createAsyncThunk("chat/loadHistory", async () => {
	const savedHistory = localStorage.getItem("chatHistory");
	if (savedHistory) {
		const parsed = JSON.parse(savedHistory);
		return parsed.map((session: any) => ({
			...session,
			timestamp: new Date(session.timestamp),
		}));
	}
	return [];
});
export const loadChatSessionMessages = createAsyncThunk(
	"chat/loadChatSessionMessages",
	async (chatId: string, { rejectWithValue }) => {
		try {
			const chatHistoryResponse = await axios.get(`api/chat-list`);
			const chatHistory = chatHistoryResponse.data?.data || [];
			const selectedChat = chatHistory.find((chat: any) => chat.chat_id === chatId);

			if (!selectedChat) {
				throw new Error("Chat session not found");
			}

			const chatMessagesResponse = await axios.get(`api/chat-messages/${chatId}`);
			const messages = chatMessagesResponse.data?.data?.messages || [];

			const formattedMessages = messages.map((msg: any) => ({
				userMessage: createMessage(msg.question, "user"),
				assistantMessage: createMessage(msg.answer, "assistant"),
			}));

			return {
				messages: formattedMessages.flatMap((msg: { userMessage: any; assistantMessage: any }) => [
					msg.userMessage,
					msg.assistantMessage,
				]),
				metadata: selectedChat.metadata,
				chatId: chatId,
			};
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	},
);
const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		setInput: (state, action) => {
			state.input = action.payload;
		},
		startNewChat: (state) => {
			if (state.currentSession.messages.length > 0) {
				const existingSession = state.chatHistory.find(
					(chat) => chat.id === state.currentSession.id,
				);
				if (!existingSession) {
					state.chatHistory.unshift(state.currentSession);
				}
			}
			state.currentSession = {
				id: uuidv4(),
				messages: [],
				timestamp: new Date(),
				metadata: null,
			};
			state.input = "";
			state.metadata = null;
			state.docId = "";
		},
		loadChatSession: (state, action) => {
			const session = state.chatHistory.find((chat) => chat.id === action.payload);
			if (session) {
				if (
					state.currentSession.messages.length > 0 &&
					!state.chatHistory.some((chat) => chat.id === state.currentSession.id)
				) {
					state.chatHistory.unshift(state.currentSession);
				}
				state.currentSession = session;
				state.metadata = session.metadata;
				state.docId = session.metadata?.ticker_symbol || "";
				state.input = "";
			}
		},
		setDocId: (state, action) => {
			state.docId = action.payload;
		},
		addUserMessage: (state, action) => {
			state.currentSession.messages.push(action.payload);
			state.input = "";
		},
		addThinkingMessage: (state, action) => {
			state.currentSession.messages.push(action.payload);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchInitialData.pending, (state) => {
				state.isLoading = true;
				state.error = null;
				state.currentSession.messages = [];
			})
			.addCase(fetchInitialData.fulfilled, (state, action) => {
				state.isLoading = false;
				state.docId = action.payload.ticker_symbol;
				state.metadata = action.payload.metadata;

				const existingSession = state.chatHistory.find(
					(chat) => chat.metadata?.ticker_symbol === action.payload.ticker_symbol,
				);

				if (existingSession) {
					state.currentSession = existingSession;
				} else {
					const userMessage = createMessage(action.payload.ticker_symbol, "user");
					const assistantMessage = createMessage(action.payload.summary, "assistant");

					state.currentSession = {
						id: action.payload.chatIdFromHistory,
						messages: [userMessage, assistantMessage],
						timestamp: new Date(),
						metadata: action.payload.metadata,
					};
					state.chatHistory.unshift(state.currentSession);
				}
			})
			.addCase(fetchInitialData.rejected, (state) => {
				state.isLoading = false;
				state.error = t("ERROR");
			})
			.addCase(sendChatMessage.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(sendChatMessage.fulfilled, (state, action) => {
				state.isLoading = false;
				state.error = null;
				state.currentSession.messages = state.currentSession.messages.filter(
					(message) => message.id !== action.payload.thinkingMessageId,
				);
				state.currentSession.messages.push(action.payload.assistantMessage);

				const existingSessionIndex = state.chatHistory.findIndex(
					(chat) => chat.id === state.currentSession.id,
				);

				if (existingSessionIndex !== -1) {
					state.chatHistory[existingSessionIndex] = {
						...state.currentSession,
						timestamp: new Date(),
						metadata: {
							...state.currentSession.metadata,
							last_activity_time: action.payload.lastActivityTime,
						},
					};
				} else {
					state.chatHistory.unshift({
						...state.currentSession,
						timestamp: new Date(),
						metadata: {
							...state.currentSession.metadata,
							last_activity_time: action.payload.lastActivityTime,
						},
					});
				}

				localStorage.setItem("currentChatSession", JSON.stringify(state.currentSession));
				localStorage.setItem("chatHistory", JSON.stringify(state.chatHistory));
			})
			.addCase(sendChatMessage.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload as string;
			})
			.addCase(loadChatHistory.fulfilled, (state, action) => {
				state.chatHistory = action.payload;
			});
	},
});

export const { setInput, startNewChat, loadChatSession, setDocId, addUserMessage } =
	chatSlice.actions;

export default chatSlice.reducer;
