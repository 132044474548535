import { WORK_ITEM_NAVIGATION } from "../../constants";
import { ActionItemsRow } from "../../types";


export const isRelevantAlertCategory = (row: ActionItemsRow): boolean => {
  return (
    row.alert_category === WORK_ITEM_NAVIGATION.SOD_COMPLETED ||
    row.alert_category === WORK_ITEM_NAVIGATION.AI_CHAT
  );
};

export const filterRelevantRows = (rows: ActionItemsRow[] = []): ActionItemsRow[] => {
  return rows.filter(isRelevantAlertCategory);
};
