import React from "react";
import { Box, Flex } from "@mantine/core";
import { WelcomeScreen } from "./WelcomeScreen";
import { ChatContent } from "./ChatContent";
import { ChatMessage } from "../../types";

interface MainChatAreaProps {
	messages: ChatMessage[];
	input: string;
	setInput: (value: string) => void;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	onSendMessage: () => Promise<void>;
	isLoading: boolean;
}

export const MainChatArea: React.FC<MainChatAreaProps> = ({
	messages,
	input,
	setInput,
	handleKeyPress,
	onSendMessage,
	isLoading = false,
}) => {
	return (
		<div className='flex grow flex-column h-screen overflow-hidden smart-query-table-section'>
			<Flex justify='center' align='center' h='100%'>
				<Box className='chat-app'>
					{messages.length === 0 ? (
						<WelcomeScreen
							input={input}
							setInput={setInput}
							handleKeyPress={handleKeyPress}
							onSendMessage={onSendMessage}
							isLoading={isLoading}
						/>
					) : (
						<ChatContent
							messages={messages}
							input={input}
							setInput={setInput}
							handleKeyPress={handleKeyPress}
							onSendMessage={onSendMessage}
							isLoading={isLoading}
						/>
					)}
				</Box>
			</Flex>
		</div>
	);
};
