export const ADMIN = "admin";

export const roles = [
    { key: "admin", label: "Administrator" },
    { key: "fund_manager", label: "Fund Manager" },
    { key: "junior_fund_manager", label: "Junior Fund Manager" },
];

export const DATE_SPEC_TODAY = "DATE_SPEC_TODAY";
 export const DATE_SPEC_YESTERDAY = "DATE_SPEC_YESTERDAY";
 export const DATE_SPEC_TOMORROW = "DATE_SPEC_TOMORROW";
 
 export const getSpecialDate = (dateSpec: string): Date => {
     const today = new Date();
     if (dateSpec === DATE_SPEC_TODAY) return today;
     if (dateSpec === DATE_SPEC_YESTERDAY) {
         const yesterday = new Date(today);
         yesterday.setDate(today.getDate() - 1);
         return yesterday;
     }
     if (dateSpec === DATE_SPEC_TOMORROW) {
         const tomorrow = new Date(today);
         tomorrow.setDate(today.getDate() + 1);
         return tomorrow;
     }
     return today; 
 };

 export const tableCategoriesOrder = [
    "total_funds",
    "in_with_orders",
    "out_with_orders",
    "out_with_no_orders",
    "data_missing",
    "benchmark_not_updated",
    "start_of_day_recon_not_done",
    "in_with_no_order",
    "in_after_placing_order",
    "out_approved_with_overrides"
];

export type TableName = 'COACTable' | 'OrderTable' | 'OthersTable';

export const currentDate = new Date();

export const WORK_ITEM_STATUS = {
    COMPLETED: 'WORK_ITEM_STATUS_COMPLETED',
    CLOSED: 'WORK_ITEM_STATUS_CLOSED',
};

export const WORK_ITEM_NAVIGATION = {
    SOD_COMPLETED: 'ALERT_CATEGORY_SOD_FUND_RECON_AGG_VIEW',
    AI_CHAT: 'ALERT_CATEGORY_NEWS_REPORTS_ETC',
};

export const ReconResultTypes = {
    HOLDINGS_COAC_MISMATCH: 'FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH',
    HOLDINGS_ORDERS_MISMATCH: 'FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH',
    HOLDINGS_OTHERS_MISMATCH: 'FUND_RECON_RESULT_TYPE_HOLDINGS_OTHERS_MISMATCH',
} as const;

export const ADJ_SUB_TYPE = "FUND_ADJUSTMENT"

export type ReconResultType = (typeof ReconResultTypes)[keyof typeof ReconResultTypes];

