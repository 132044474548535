import React from "react";
import { Grid, GridCol } from "@mantine/core";
import SidebarItem from "../Leftnavbar/SidebarItem";
import { ChatHistoryItem } from "../../types";
import styles from "./AIAssistant.module.css";

interface ChatSectionProps {
	title: string;
	chats: ChatHistoryItem[];
	selectedItem: string;
	onChatSelect: (label: string, id: string) => void;
}

export const ChatSection: React.FC<ChatSectionProps> = ({
	title,
	chats,
	selectedItem,
	onChatSelect,
}) => {
	if (!chats.length) return null;

	return (
		<div className={styles.chatSection}>
			<h3 className={styles.sectionTitle}>{title}</h3>
			<GridCol className={styles.chatList}>
				{chats.map((chat) => (
					<Grid.Col key={chat.id} className={styles.navigationSection}>
						<SidebarItem
							label={
								chat.metadata?.title?.length > 15
									? `${chat.metadata.title.slice(0, 15)}...`
									: chat.metadata?.title || "Untitled"
							}
							path=''
							isSelected={selectedItem === chat.id}
							onClick={() => onChatSelect(chat.label, chat.id)}
						/>
					</Grid.Col>
				))}
			</GridCol>
		</div>
	);
};
