//@ts-nocheck
import { PositionChartProps } from "../../types";
import {
	BarChart,
	CartesianGrid,
	YAxis,
	XAxis,
	ReferenceLine,
	ReferenceArea,
	Bar,
	LabelList,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { Box, useMantineTheme } from "@mantine/core";
import {
	formatData,
	dataConfig,
	getMinAndMax,
	tooltipFormatter,
	formatDelta,
} from './PositionSummaryUtils';
import Tick from "./Tick";
import './PositionSummary.css'
import { t } from "i18next";

const PositionChart = ({ data, delta: deltaValues, ...rest }: PositionChartProps) => {
	const theme = useMantineTheme();
	data = formatData(data, deltaValues);

	const { minX, maxX, xTickBufferMin, xTickBufferMax, min_limit, max_limit } = getMinAndMax(data, rest.min_limit, rest.max_limit);
	const min_limit_display = xTickBufferMin < min_limit ? xTickBufferMin : min_limit;
	const max_limit_display = xTickBufferMax > max_limit ? xTickBufferMax : max_limit;
	const numTicks = data.length;
	const barHeight = 20;
	const height = numTicks * barHeight + 70;

	const BarLabel = ({ x, y, width, height, index, data }) => {
		const { changedValue, delta, formattedDelta } = data[index];
		if (changedValue) {
			let labelX: number;
			if (changedValue >= 0) {
				labelX = delta > 0 ? x + 10 : x + width + 10;
			} else {
				labelX = delta < 0 ? x - 45 : x + width - 40;
			}
			//          const labelY = delta == 0? Math.abs(y) + Math.abs(height)/2: Math.abs(y) + Math.abs(height) / 5;
			const labelY = Math.abs(y) + Math.abs(height) / 2;
			return (
				<text key={index} x={labelX} y={labelY} textAnchor='start' dominantBaseline='middle' fill={theme.colors.violet[8]} fontWeight={600}>
					{delta !== 0 && `${delta > 0 ? "+" : "-"}${Math.abs(formattedDelta)}`}
				</text>
			);
		} else return null;
	};

	const ReferenceAreaLabel = ({ viewBox, value, extra }) => {
		const { x, y, height } = viewBox;
		return (
			<text x={x} y={y} dy={height + 35} textAnchor='middle' fill={theme.colors.grey[5]}>
				{value}
				<tspan x={x} dy='1.2em' style={{ fontSize: "10px" }}>
					{extra}
				</tspan>
			</text>
		);
	};

	const CustomYAxisTick = ({ x, y, payload, index, ...rest }) => {
		const truncatedText = (payload.value.substring(0, dataConfig.xLabelLimit)).trim();
		const { formattedDelta, value: currentValue } = rest.data[index];

		let impact: string | null = null;
		if (formattedDelta !== 0) {
			impact = "-";
			if (parseFloat(currentValue) <= max_limit && parseFloat(currentValue) >= min_limit) {
				impact = "+";
			};
		}

		const propsForText = {
			x,
			y,
			dy: 4,
			textAnchor: "end",
			fontSize: "0.813rem",
			fill: theme.colors.grey[8]
		}

		const valueToDisplay = `${truncatedText}${payload.value.length > dataConfig.xLabelLimit ? '...' : ''}`;

		const color = {
			text: theme.colors.grey[8],
			backgroundColor: Math.abs(formattedDelta) < max_limit ? 'transparent' : impact === "-" ? theme.colors.green[0] : theme.colors.red[0],
		  }

		return impact ? (
			<Tick x={x} y={y} payload={{ ...payload, value: valueToDisplay }} color={color} />
		) : (
			<text
				{...propsForText}
			>
				{valueToDisplay}
			</text>
		);
	};

	const CustomDot = (props: any) => {
		const {
			x,
			y,
			height,
			value,
		} = props;
		const radius = height / 2;
		const color = value ? theme.colors.violet[8] : theme.colors.grey[5];
		return <circle cx={x} cy={y + height / 2} r={radius} fill={color} />;
	};

	const tooltipTextStyle = { color: theme.colors.grey[5], fontWeight: "bold", textAlign: "center" };

	return (
		<Box pt='md' className='position-summary-chart' w='100%'>
			<ResponsiveContainer width='100%' height={height} className='stat-graph'>
				<BarChart
					data={data}
					layout='vertical'
					margin={{
						top: 5,
						right: 60,
						left: 10,
						bottom: 40,
					}}
				>
					<CartesianGrid horizontal={false} strokeDasharray='3 3' />
					<YAxis
						type='category'
						dataKey='key'
						interval={0}
						width={100}
						tick={<CustomYAxisTick data={data} />}
					/>
					<XAxis
						type='number'
						domain={[-1, 1]}
						tickCount={dataConfig.tickCount || 11}
						tick={{ fill: theme.colors.dark[9] }}
						tickFormatter={(value) => value.toFixed(1)}
                        allowDecimals
					/>
					<ReferenceLine x={0} stroke='black' />
					<ReferenceArea x1={min_limit} x2={max_limit} fill={theme.colors.grey[10]} fillOpacity={0.4} />
					<Tooltip
						formatter={tooltipFormatter}
						separator=""
						filterNull={true}
						cursor={{ fill: theme.colors.grey[10], fillOpacity: 0.1 }}
						itemStyle={tooltipTextStyle}
						labelStyle={tooltipTextStyle}
						contentStyle={{ paddingBottom: 0 }}
					/>
					<Bar dataKey='value' fill={theme.colors.grey[5]} fillOpacity={0} stackId="deltaStack" />
					<Bar dataKey='changedValue' fill={theme.colors.grey[5]} fillOpacity={0} stackId="deltaStack" />
					<Bar dataKey='formattedDelta' fill={theme.colors.violet[0]} radius={[0, 20, 20, 0]} stackId="deltaStack" >
						<LabelList
							dataKey="delta"
							content={<CustomDot />}
						/>
						<LabelList
							dataKey='delta'
							position='right'
							content={(props) => <BarLabel {...props} data={data} />}
						/>
					</Bar>
					<ReferenceLine
						x={min_limit_display}
						stroke='rgba(0,0,0,0)'
						label={<ReferenceAreaLabel value={min_limit} extra={t('OVERALL_LOWER_TOL')} />}
					/>
					<ReferenceLine
						x={max_limit_display}
						stroke='rgba(0,0,0,0)'
						label={<ReferenceAreaLabel value={max_limit} extra={t('OVERALL_UPPER_TOL')}  />}
					/>
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default PositionChart;
