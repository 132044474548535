export function computeOrderSummary(response: any) {
    let total_buy_shares = 0, total_sell_shares = 0, total_buy_amount_in_millions = 0, total_sell_amount_in_millions = 0, total_buy_pct = 0, total_sell_pct = 0;
    let orderListArray: string[] = [];

    for (let eachOrderList of response.data.data) {
        let stats;

        if (eachOrderList['order_asset_type'] === "ORDER_ASSET_TYPE_FUTURES") {
            stats = eachOrderList.futures_stats;
        } else if (eachOrderList['order_asset_type'] === "ORDER_ASSET_TYPE_EQUITY") {
            stats = eachOrderList.equity_stats;
        }

        if (stats) {
            total_buy_shares += stats?.total_buy?.num_shares ?? 0;
            total_sell_shares += stats?.total_sell?.num_shares ?? 0;

            total_buy_amount_in_millions += stats?.total_buy?.amount ?? 0;
            total_sell_amount_in_millions += stats?.total_sell?.amount ?? 0;

            total_buy_pct += stats?.total_buy?.pct_of_nav ?? 0;
            total_sell_pct += stats?.total_sell?.pct_of_nav ?? 0;
        }

        if (!orderListArray.includes(eachOrderList.order_list_id) && eachOrderList.order_list_status == 'ORDER_LIST_STATUS_IN_PROGRESS') {
            orderListArray.push(eachOrderList.order_list_id);
        }
    }
    return { total_buy_shares, total_sell_shares, total_buy_amount_in_millions, total_sell_amount_in_millions, total_buy_pct, total_sell_pct, orderListArray };
}
