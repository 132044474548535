import React from "react";
import {
  Card,
  Container,
  Grid,
  Title,
  Text,
  useMantineTheme,
} from "@mantine/core";
import classes from "./CardComponent.module.css";
import { useTranslation } from "react-i18next";
import { CardData, CardProps } from "../../types";
import ErrorMessage from "../Common/ErrorMessage";
import { getBadgeStyles } from "../../utils/getStyles";

type CardState = 'ready' | 'not_ready' | 'completed';

const orderCriteria: Record<CardState, string[]> = {
  ready: ["in_with_orders", "out_with_orders", "out_with_no_order"],
  not_ready: ["sod_incomplete", "data_missing", "benchmark_not_updated"],
  completed: ["in_with_no_order", "in_after_placing_order", "out_approved_with_overrides"],
};

const convertKeyToLabel = (key: string): string => {
  const lowerKey = key.toLowerCase();
  const prefixLength = lowerKey.startsWith('in') ? 3 : lowerKey.startsWith('out') ? 4 : 0;
  return key.slice(prefixLength).split('').map(word => word.toUpperCase()).join('');
};

const getOrderPriority = (key: string, state: CardState) => {
  const order = orderCriteria[state] || [];
  const index = order.indexOf(key);
  return index === -1 ? order.length : index;
};

export const CardComponent: React.FC<CardProps> = ({
  readyText,
  actionText,
  dataForCards,
  isSelected,
  icon
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const isDataForCardsEmpty = Object.keys(dataForCards).length === 0;
  if (isDataForCardsEmpty) {
    return (
      <Card
        shadow="sm"
        className={`${classes.card} ${isSelected ? classes.selected : ""}`}
        withBorder
        style={{
          backgroundColor: isSelected
            ? theme.colors.violet[0]
            : theme.colors.grey[0],
        }}
      >
        <Container>
          <div>
            <ErrorMessage errorMessage="No Data Found" />
          </div>
        </Container>
      </Card>
    );
  }

  const cardState = readyText.toLowerCase().replace(' ', '_') as CardState;
  const dataKeys = Object.keys(dataForCards)
    .filter(key => key !== 'total_funds')
    .sort((a, b) => getOrderPriority(a, cardState) - getOrderPriority(b, cardState));

  const greenLabels = new Set(["in"]);
  const InOut = new Set(['in', 'out']);
  const { inBadge, outBadge } = getBadgeStyles(theme);

  const renderStatus = (key: string) => {
    const status = key.split('_')[0];
    
    if (!InOut.has(status)) return null;
    const isGreenLabel = greenLabels.has(status);
    const { labelClass, labelColor } = isGreenLabel
      ? { labelClass: "labelInText", labelColor: theme.colors.green[0] }
      : { labelClass: "labelOutText", labelColor: theme.colors.red[0] };

    return (
      <span>
      {labelClass === "labelInText" ? inBadge : outBadge}
      </span>
    );
  };

  return (
    <Card
      shadow={isSelected ? "sm" : "none"}
      className={`${classes.card} ${isSelected ? classes.selected : ""}`}
      withBorder
      style={{
        backgroundColor: isSelected
          ? theme.colors.violet[0]
          : theme.colors.grey[1],
      }}
    >
      <Container>
        <Grid>
          <div>
            <div className={classes.gridStyle}>
              {icon}

              <div className={classes.titleStyle}>
                <Title className={classes.titleReadyStyles} style={{ marginRight: readyText === t("NOT_READY") ? '7rem' : '0' }}>
                  {t(readyText)}
                </Title>
                <Text
                  c={theme.colors.grey[8]}
                  className={classes.actionTextStyle}
                >
                  {t(actionText)}
                </Text>
              </div>
              <div className={classes.fundspace}>
                <Text className={classes.fundfigure} style={{ paddingRight: readyText === t('COMPLETED') || readyText === t('READY') ? '0.2rem' : '0' }}>
                  <span className={classes.fontfigure}>{dataForCards.total_funds}</span>{" "}
                  {t("FUNDS")}
                </Text>
              </div>
            </div>
            <div className={classes.dataForCardsStyle}>
              {dataKeys.map((item, index) => (
                <div key={item} className={`${classes.dataForCardsMap} ${dataKeys.length === 2 ? classes.borderRightTwoValues : classes.borderRight}`}>
                  <Text c={theme.colors.grey[8]} className={classes.textNum}>
                    <span style={{ marginLeft: dataKeys.length === 2 ? '6rem' : ' ' }}>
                      {dataForCards[item as keyof CardData] ?? ""}
                    </span>
                  </Text>
                  {renderStatus(item)}
                  <div className={classes.labeltextstyle} style={{ marginLeft: dataKeys.length === 2 ? '6rem' : 'left' }}>
                    <div className={classes.aligncardtext}>
                      <Text c={theme.colors.grey[8]} className={classes.label2text}>
                        {t(convertKeyToLabel(item))}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
    </Card>
  );
};
