import React, { useState, useEffect, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios, { AxiosResponse } from 'axios';
import { Box, useMantineTheme } from '@mantine/core';
import EditToggleButton from '../../../components/FundConfig/EditToggleButton';
import CustomModal from '../../../components/FundConfig/CustomModal';
import { services } from '../../../services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FundConfig.css'
import CustomTable from '../../../components/CustomTable/CustomTable';
import { defaultColDef, rowHeight } from '../../../components/CustomTable/utilsAgTable';
import { getColumnDefs } from '../../../components/CustomTable/columnDefs';
import { LoaderIcon } from '../../../components/Common/LoaderIcon';
import { CellClassParams, CellClickedEvent, CellValueChangedEvent, RowClassParams } from 'ag-grid-community';

interface RowData {
  ref_template_name: string;
  fund_id: string;
  original: {
    lower_threshold: number;
    upper_threshold: number;
    target_value: number;
    type: string;
    target_category: string;
  };
  lower_threshold: number;
  upper_threshold: number;
  target_value: number;
  type: string;
  target_category: string;
  disabled?: boolean;
}

const TargetsTolerances: React.FC = () => {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCells, setEditedCells] = useState<Map<string, string>>(new Map());
  const [originalValues, setOriginalValues] = useState<Map<string, any>>(new Map());
  const theme = useMantineTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});

  const handleToggle = async () => {
    await fetchPermissions();
    setIsEditing((prev) => !prev);
  };

  const fetchPermissions = async () => {
    try {
      const response: AxiosResponse<{ data: { fund_id: string; fund_group_permissions: string[] }[] }> = await axios.get(services.FUND_PERMISSION);
      const fundPermissions = response.data.data;

      const permissionsMap = fundPermissions.reduce(
        (acc, { fund_id, fund_group_permissions }) => {
          acc[fund_id] = fund_group_permissions.includes('write');
          return acc;
        },
        {} as { [key: string]: boolean }
      );

      const newRowData = rowData.map((row) => {
        const fundPermission = fundPermissions.find((f) => f.fund_id === row.fund_id);
        const canEdit = fundPermission ? fundPermission.fund_group_permissions.includes('write') : false;
        return { ...row, disabled: !canEdit };
      });

      setRowData(newRowData);
      setPermissions(permissionsMap);
    } catch (error) {
      toast.error('Failed to fetch permissions.');
      console.error('Error fetching permissions:', error);
    }
  };


  const fetchData = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<{ data: { fund_id: string; fund_name: string; ref_template_name: string; targets: { lower_threshold: number; upper_threshold: number; target_value: number; type: string; target_category: string; }[] }[] }> = await axios.get(services.TARGETS);
      const fundsData = response.data.data;
      const targetRows = fundsData.flatMap((fund: any) =>
        fund.targets.map((target: any) => ({
          fund_id: fund.fund_id,
          fund_name: fund.fund_name,
          ref_template_name: fund.ref_template_name,
          original: {
            lower_threshold: target.lower_threshold,
            upper_threshold: target.upper_threshold,
            target_value: target.target_value,
            type: target.type,
            target_category: target.target_category,
          },
          lower_threshold: target.lower_threshold,
          upper_threshold: target.upper_threshold,
          target_value: target.target_value,
          type: target.type,
          target_category: target.target_category,
        }))
      );
      setRowData(targetRows);
      setLoading(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching data:', error.message);
      } else {
        console.error('Unknown error fetching data:', error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);


  const handleCellClicked = (event: CellClickedEvent) => {
    const { colDef, value } = event;
    if (colDef.field === 'ref_template_name') {
      setSelectedTemplate(value);
      setIsModalOpen(true);
    }
  };

  const onCellValueChanged = (event: CellValueChangedEvent<RowData, any>) => {
    if (!isEditing) return;
    const { data, colDef, rowIndex } = event;

    if (!colDef.field) return;
    const cellKey = `${rowIndex}-${colDef.field}`;
    setEditedCells((prev) => new Map(prev).set(cellKey, theme.colors.violet[1]));

    if (!originalValues.has(cellKey)) {
      originalValues.set(cellKey, data[colDef.field as keyof RowData]);
    }
  };
  const handleSave = async () => {
    const groupedPayload: Record<string, any> = {};
    const allTargetsMap: Record<string, any[]> = {};

    rowData.forEach((row) => {
      if (!allTargetsMap[row.fund_id]) {
        allTargetsMap[row.fund_id] = [];
      }
      allTargetsMap[row.fund_id].push({
        type: row.type,
        target_category: row.target_category,
        lower_threshold: row.original.lower_threshold,
        upper_threshold: row.original.upper_threshold,
        target_name: '',
        target_value: row.original.target_value,
      });
    });

    rowData.forEach((row) => {
      const fundId = row.fund_id;
      const reference_template_name = row.ref_template_name;
      const targets = allTargetsMap[fundId];

      const targetIndex = targets.findIndex((target) => target.type === row.type);
      if (targetIndex !== -1) {
        let hasChanges = false;

        if (row.lower_threshold !== row.original.lower_threshold) {
          targets[targetIndex].lower_threshold = row.lower_threshold;
          hasChanges = true;
        }
        if (row.upper_threshold !== row.original.upper_threshold) {
          targets[targetIndex].upper_threshold = row.upper_threshold;
          hasChanges = true;
        }
        if (row.target_value !== row.original.target_value) {
          targets[targetIndex].target_value = row.target_value;
          hasChanges = true;
        }

        if (hasChanges) {
          if (!groupedPayload[fundId]) {
            groupedPayload[fundId] = { fund_id: fundId, ref_template_name: reference_template_name, targets: [] };
          }
          groupedPayload[fundId].targets = targets;
        }
      }
    });

    const payload = Object.values(groupedPayload);

    if (payload.length > 0) {
      try {
        const response = await axios.post(services.TARGETS, payload);
        console.log('Data saved successfully:', response.data);
        setEditedCells(new Map());
        setOriginalValues(new Map());
        await fetchData();
        setIsEditing(false)
      } catch (error: unknown) {
        let errorMessage = 'An unknown error occurred';
        if (axios.isAxiosError(error) && error.response?.data?.detail) {
          errorMessage = error.response.data.detail;
        }
        toast.error(errorMessage);
        console.error('Error updating:', errorMessage);
      }

    } else {
      console.log('No changes to save.');
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (isEditing) {
      setEditedCells(new Map());
      setOriginalValues(new Map());
      fetchData();
    } else {
      fetchPermissions();
    }
  };

  const getCellStyle = (params: CellClassParams<RowData, any>) => {
    const cellKey = `${params.rowIndex}-${params.colDef.field}`;
    const bgColor = editedCells.get(cellKey) || '';

    if (params.colDef.field === 'target_value' && isEditing) {
      const { lower_threshold, upper_threshold, target_value } = params.data!;
      if (target_value < lower_threshold || target_value > upper_threshold) {
        return { className: 'cell-edited', backgroundColor: bgColor };
      }
    }

    return { className: 'cell-normal', backgroundColor: bgColor };
  };

  const getRowStyle = (params: { data: RowData }) => {
    return params.data.disabled ? { className: 'row-disabled' } : { className: 'row-normal' };
  };


  const columnDefs = useMemo(() => getColumnDefs('TARGETS_AND_TOLS', {
    isEditing,
    getCellStyle
  }), [isEditing, editedCells]);

  const gridOptions: { columnDefs: any; getRowStyle: (params: { data: RowData }) => { className: string } } = {
    columnDefs,
    getRowStyle,
  };

  const getRowClass = (params: RowClassParams) => {
    return params.data.disabled ? 'disabled-row' : '';
  };

  return (
    <>{loading ? (
      <Box h='90vh'>
        <LoaderIcon name='loading-large' alt='loading icon' />
      </Box>
    ) : (
      <Box mah={'85vh'} className='fund-table'>

        <EditToggleButton
          isEditing={isEditing}
          onToggle={handleToggle}
          onUpdate={handleSave}
          onDiscard={handleEditToggle}
        />
        <CustomTable rowData={rowData} columnDefs={columnDefs} getRowClass={getRowClass} onCellClicked={handleCellClicked} rowHeight={rowHeight} defaultColDef={defaultColDef} onCellValueChanged={onCellValueChanged} className='fund-query' emptyMsg='NO_FUND_TARGETS' />
        <ToastContainer />
        {isModalOpen && (
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}

      </Box>
    )}
    </>
  );
};

export default TargetsTolerances;
