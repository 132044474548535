import React from 'react';
import { Modal, Text, Flex, Box } from '@mantine/core';
import { t } from 'i18next';
import CustomButton from '../Buttons/CustomButton';
import { DeleteModalProps } from '../../../types';

export const DeleteModal: React.FC<DeleteModalProps> = ({
    opened,
    onClose,
    TITLE,
    message,
    onSubmit,
    centered = true,
    withCloseButton = false,
    radius = '1.875rem',
    className = 'delete-modal'
}) => {
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            centered={centered}
            withCloseButton={withCloseButton}
            radius={radius}
            className={className}
            size={'29.375rem'}
        >
            <Box pr={'xxs'}>
                <Text ta={'center'} size='1rem' fw={'bold'} mt={"2rem"}>{t(TITLE)}</Text>
                <Text ta={'center'} mt={"2rem"} size={'0.8125rem'}>{t(message)}</Text>
                <Flex justify="center" align="center" mt="2rem" mb={"2.5rem"}>
                    <CustomButton variant={'cancel'} onClick={onClose} children={t("CANCEL")} />
                    <CustomButton variant='submit' onClick={onSubmit} children={t("DELETE")} />
                </Flex>
            </Box>
        </Modal>
    );
};
