import axios from 'axios';
import { services } from '../../services';
import { SummaryData } from '../../types';

// Create session API
export const createSession = async (userName: string): Promise<void> => {
  try {
    const response = await axios.post(`${services.CREATE_SESSION}/${userName}`);
    if (response.status !== 200) {
      throw new Error('Failed to create session');
    }
  } catch (err) {
    console.error('Error creating session:', err);
    throw err;
  }
};

// Fetch AI data for ticker
export const aiService = async (symbol: string): Promise<{ data: SummaryData; ticker_symbol: string }> => {
  try {
    const response = await axios.get(`${services.AI_ASSISTANT}?ticker_symbol=${symbol}`);
    if (response.data) {
    const ticker_symbol = response.data.metadata.ticker_symbol;
      return { data: response.data, ticker_symbol };
    }
    throw new Error('No data found');
  } catch (err) {
    console.error(err);
    throw err;
  }
};


export const chatService = async (
  question: string
): Promise<{ question: string; answer: string; error: string | null }[]> => {
  try {
    const response = await axios.post(services.CHAT_API, { question });

    if (response.data) {
      return response.data;
    }

    // No data returned from the API
    return [
      {
        question,
        answer: '',
        error: 'No data found in the response',
      },
    ];
  } catch (error: unknown) {
    console.error('Error in chatService:', error);

    // Handle different error types gracefully
    if (axios.isAxiosError(error)) {
      return [
        {
          question,
          answer: '',
          error: error.response?.data?.message || 'API call failed',
        },
      ];
    }

    // Generic fallback error
    return [
      {
        question,
        answer: '',
        error: 'An unexpected error occurred',
      },
    ];
  }
};
