import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box } from '@mantine/core';
import "../../../pages/AdminPages/Users/AdminPage.css";
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import { services } from '../../../services';
import { FundTableProps, FundGroups } from '../../../types';
import FundGroupModalUser from './FundGroupModalUser';
import AdminTableHeader from '../AdminTableHeader';
import { t } from 'i18next';
import { useDeselectAllOnRowUnselect } from '../../../utils/useDeselectAllOnRowUnselect';
import CustomTable from '../../CustomTable/CustomTable';
import { getTableData } from '../../../utils/conversions';
import { rowHeight, rowSelectionTypeSingle } from '../../CustomTable/utilsAgTable';
import { DeleteModal } from '../../Common/Modals/DeleteModal';

const FundGroup: React.FC<FundTableProps> = ({ fundGroupCounts, roleAndFundGroupCounts, onUniqueFundGroup }) => {
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<FundGroups[]>([]);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [modalType, setModalType] = useState<null | 'create' | 'update'>(null);
    const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const [gridApi, setGridApi] = useState<any>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleModalToggle = (type: 'create' | 'update') => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            closeModal();
        } else {
            setModalType(type);
            if (!modalOpened) openModal();
        }
    };

    const handleDeleteUser = () => {
        setIsDeleteModalOpen(true)
        console.log("Delete API coming soon");
    };

    const fetchFundGroup = () => {
        axios.get(services.GET_FUND_GROUPS)
            .then(response => {
                const fundGroups = response.data;
                setGroup(fundGroups.map((fundGroupObj: { fundGroup: string, uniqueUserCount: number, uniqueRoleCount: number }) => ({
                    fund_group: fundGroupObj.fundGroup,
                    userCounts: fundGroupObj.uniqueUserCount,
                    roleCount: fundGroupObj.uniqueRoleCount
                })));
                setLoading(false);
    
                const uniqueFundGroup = Array.from(new Set(fundGroups.map((fg: { fundGroup: string }) => fg.fundGroup)));
                console.log("uniqueDescriptions", uniqueFundGroup);
    
                onUniqueFundGroup(uniqueFundGroup);
            })
            .catch(error => {
                console.error('Error fetching fund groups:', error);
                setLoading(false);
            });
    };
    
    useEffect(() => {
        fetchFundGroup();
    }, []);

      const columnDefs: ColDef[] = useMemo(() => {
            const tableData = getTableData("FUND_GROUP");
            const columns = [
                ...tableData.map(({ colHeader, key, decorators }) => ({
                    headerName: t(colHeader),
                    field: key,
                    cellRenderer: null,
                })),
                {
                    headerName: t('ROLES_COUNT'),
                    field: 'roleCount',
                    valueGetter: (params: { data: any; }) => {
                        const fundGroup = params.data.fund_group;
                        const uniqueRoles = roleAndFundGroupCounts[fundGroup];
                        return uniqueRoles ? uniqueRoles.size : 0;
                    }
                },
                {
                    headerName: t('USER_COUNT'), field: 'userCounts',
                    valueGetter: (params: { data: any; }) => fundGroupCounts[params.data.fund_group] || 0
                }
            ];
            return columns;
        }, []);

    const defaultColDef = {
        flex: 1,
        minWidth: 150,
    };

    const gridApiRef = useRef<any>(null);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api; // Store gridApi in ref
        setGridApi(params.api);
    };

    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    return (
        <Box w={'80rem'} onClick={() => setIsRowSelected(false)}>
            <Box maw={"57.2rem"}>
                <Box mr={'8.5rem'}>
                    <AdminTableHeader
                        isRowSelected={isRowSelected}
                        handleEditUser={() => handleModalToggle('update')}
                        handleDeleteClick={() => setIsDeleteModalOpen(true)}
                        resetCreateUser={() => handleModalToggle('create')}
                        headerTitle='FUND_GROUPS'
                        createTitle='CREATE_FUND_GROUP'
                    />
                </Box>

                <CustomTable rowData={group} columnDefs={columnDefs} defaultColDef={defaultColDef} rowHeight={rowHeight}  onSelectionChanged={handleSelectionChanged} onGridReady={onGridReady} rowSelection={rowSelectionTypeSingle} className='action-item' emptyMsg='NO_FUND_GROUP_HERE'/>

                <FundGroupModalUser opened={modalOpened && modalType === 'create'} onClose={() => handleModalToggle('create')} TITLE='CREATE_NEW_FUND_GROUP' buttonName="CREATE" />
                <FundGroupModalUser opened={modalOpened && modalType === 'update'} onClose={() => handleModalToggle('update')} TITLE='UPDATE_FUND_GROUP' buttonName="SET" />
                <DeleteModal
                    opened={isDeleteModalOpen}
                    onClose={()=>setIsDeleteModalOpen(false)}
                    TITLE='DELETE_FUND_GROUP'
                    message='DELETE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />
            </Box>
        </Box>
    );
};

export default FundGroup;
