import React from "react";
import { TextInput, Stack, Text, Grid } from "@mantine/core";
import { t } from "i18next";
import { useAppSelector } from "../../store/hooks";
import "../../components/Account/AccountInfo.css";
import { LanguageDropdown } from "../../components/Common/LanguageDropdown";

const DisabledTextInput: React.FC<{ label: string; value: string }> = ({ label, value }) => {
	return (
		<Grid align={"center"} mt='0.08rem'>
			<Grid.Col span={1.5} p={3}>
				<Text mr={"xl"}>{t(label)}</Text>
			</Grid.Col>

			<Grid.Col span={10.5} p={3}>
				<TextInput value={t(value)} disabled w='20rem' size='1.5625rem' className='account' />
			</Grid.Col>
		</Grid>
	);
};

const ActionInfo: React.FC = () => {
	const { user } = useAppSelector((state) => state.user);

	const fields = [
		{ label: "NAME", value: user?.fullName || "" },
		{ label: "USER_ID", value: user?.username || "" },
		{ label: "EMAIL", value: user?.email || "" },
		{ label: "PASSWORD", value: "" },
		{ label: "COMPANY", value: "" },
		{
			label: "ROLE",
			value: user?.roles ? (Array.isArray(user.roles) ? user.roles[0] : user.roles) : "",
		},
		{
			label: "Language",
			value: "",
		},
	];

	return (
		<Stack m='md' p={"md"} pl={"1.9rem"}>
			{fields.map((field, index) =>
				field.label === "Language" ? (
					<Grid key={field.label} align='center' mt='0.08rem'>
						<Grid.Col span={1.5} p={3}>
							<Text>{t(field.label)}</Text>
						</Grid.Col>
						<Grid.Col span={10.5} p={3}>
							<LanguageDropdown/>
						</Grid.Col>
						<Grid.Col span={4}>{/* Leave the Decorator column blank */}</Grid.Col>
					</Grid>
				) : (
					<DisabledTextInput key={index} label={field.label} value={field.value} />
				),
			)}
		</Stack>
	);
};

export default ActionInfo;
