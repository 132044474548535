import React, { useMemo, useRef, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../Agtablehomepage/Agstyles.css";
import { Box } from "@mantine/core";
import { AllFundQueryTableProps } from "../../types";
import { AgGridEvent, ColDef } from "ag-grid-community";
import { formatHeaderUtil } from "../../utils/formatHeaderUtil";
import CustomTable from "../CustomTable/CustomTable";

interface TableConfig {
	pinnedColumns?: string[];
	columnOrder?: string[];
	hideHeaders?: string[];
}
interface AllFundQueryTablePropsExtended extends AllFundQueryTableProps {
	isDisplayedFromHoldingContentTab?: boolean;
}

interface AllFundQueryTablePropsExtended extends AllFundQueryTableProps {
	isFromHoldingContentTab?: boolean;
	tableConfig?: TableConfig;
}

const AllFundQueryTable: React.FC<AllFundQueryTablePropsExtended> = ({
	data,
	isFromHoldingContentTab = true,
	tableConfig = {},
}) => {
	const tableWrapperRef = useRef<HTMLDivElement>(null);

	const columnDefs = useMemo(() => {
		if (data.length === 0) return [];

		const keys = tableConfig.columnOrder || Object.keys(data[0]);
		const pinnedColumns = tableConfig.pinnedColumns || [];

		const columns = keys.map((key, index) => {
			const isBooleanField = typeof data[0][key] === "boolean";

			const columnDefinition: ColDef = {
				headerName: tableConfig?.hideHeaders?.includes(key) ? "" : formatHeaderUtil(key),
				field: key,
				headerClass: "custom-header",
				filter: "agTextColumnFilter",
				width: key === "sr_no" ? 90 : undefined,
				valueGetter: key === "sr_no" ? "node.rowIndex + 1" : undefined,
				pinned: pinnedColumns.includes(key) ? "left" : undefined,
				minWidth: 100,
				maxWidth: pinnedColumns.includes(key) ? 300 : undefined,

				cellRenderer: isBooleanField
					? (params: { value: any }) => (params.value ? "true" : "false")
					: undefined,
				flex: pinnedColumns.includes(key) ? 1 : 2,
			};

			return columnDefinition;
		});

		return columns;
	}, [data, tableConfig]);

	useEffect(() => {
		if (tableWrapperRef.current) {
			const cells = tableWrapperRef.current.querySelectorAll(".ag-cell-value");
			let maxContentWidth = 0;
			cells.forEach((cell) => {
				maxContentWidth = Math.max(maxContentWidth, cell.clientWidth);
			});
			tableWrapperRef.current.style.minWidth = `${maxContentWidth}px`;
		}
	}, [data]);

	const maxHeight = isFromHoldingContentTab ? true : false;

	return (
		<Box
			ref={tableWrapperRef}
			w='100%'
			style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
		>
			{data.length > 0 ? (
				<CustomTable
					rowData={data}
					columnDefs={columnDefs}
					fullLength={maxHeight ? true : false}
					onSortChanged={(e: AgGridEvent) => {
						e.api.refreshCells();
					}}
					className='fund-query'
				/>
			) : (
				<div> </div>
			)}
		</Box>
	);
};

export default AllFundQueryTable;
