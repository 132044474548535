import React, { useEffect, useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, ICellRendererParams, ValueFormatterParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { DateInput } from '@mantine/dates';
import { Box, Select, TextInput } from '@mantine/core';
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import { applyDecorators, getTableData } from "../../utils/conversions";
import { t } from 'i18next';
import { MismatchData, SodReconTableProps } from '../../types';
import './SodReconTable.css';
import { ReconResultTypes } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTable from '../CustomTable/CustomTable';
import { rowHeight } from '../CustomTable/utilsAgTable';
import { AgGridReact } from 'ag-grid-react';

const SodReconTableComplete: React.FC<SodReconTableProps> = ({
  data,
  onSelectionChange,
  allowMultipleSelection = true,
  isAdjustmentPage = false,
  startDates: initialStartDates = {},
  endDates: initialEndDates = {},
  onDateChange,
  onApplyToChange,
  applyToValues,
  completeAdjustment = false,
  disableEdit = false,
  noCheck = false,
  withoutAdditionalRows = false,
  resultType
}) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { result_type, fund_group } = useParams();
  const tableData = getTableData(
    withoutAdditionalRows ? "SodReconTable" : (result_type === ReconResultTypes.HOLDINGS_COAC_MISMATCH ? "SodReconTableCompleted" : "SodReconTableNonCOAC")
  );

  const rowSelection = noCheck ? undefined : allowMultipleSelection ? 'multiple' : 'single';
  const defaultDate = new Date();

  const convertToStringDates = (dates: { [key: string]: Date | null }): { [key: string]: string } => {
    return Object.fromEntries(
      Object.entries(dates).map(([key, value]) => [key, value ? value.toISOString().split('T')[0] : ''])
    );
  };

  // Convert initial dates to string format
  const initialStartDatesString = convertToStringDates(initialStartDates);
  const initialEndDatesString = convertToStringDates(initialEndDates);

  const [startDates, setStartDates] = useState<{ [key: string]: string }>(
    Object.keys(initialStartDates).length ? initialStartDatesString : { default: '' }
  );

  const [endDates, setEndDates] = useState<{ [key: string]: string }>(
    Object.keys(initialEndDates).length ? initialEndDatesString : { default: '' }
  );

  const [applyTo, setApplyTo] = useState<{ [key: string]: string }>({
    ...applyToValues,
  });

  const handleDateChange = (rowId: string, value: string, isStartDate: boolean) => {
    // Update the state with the string value
    if (isStartDate) {
      setStartDates((prev) => ({ ...prev, [rowId]: value }));
    } else {
      setEndDates((prev) => ({ ...prev, [rowId]: value }));
    }

    // Convert the string to a Date object if onDateChange expects a Date
    if (onDateChange) {
      const dateValue = value ? new Date(value) : null; // Convert string to Date or null
      onDateChange(rowId, dateValue, isStartDate); // Pass Date to onDateChange
    }
  };

  const navigate = useNavigate();

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const { data } = event.node;
    if (data.work_item_status === 'WORK_ITEM_STATUS_COMPLETED') {
      const { asset_name, asset_id, fund_id } = data;
      navigate(`/${asset_name}/${asset_id}/${fund_group}/${resultType}/${fund_id}/completed`);
    }
  };

  const handleApplyToChange = (rowId: string, value: string) => {
    setApplyTo((prev) => {
      const updatedApplyTo = { ...prev, [rowId]: value };

      if (onApplyToChange) {
        onApplyToChange(updatedApplyTo);
      }

      return updatedApplyTo;
    });
  };

  const renderEditableDateField = (rowId: string, value: string, isStartDate: boolean) => (
    <div
      contentEditable={!disableEdit}
      suppressContentEditableWarning
      onBlur={(e) => handleDateChange(rowId, e.target.textContent || '', isStartDate)}
      className="calendar-recon-edit"
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
    >
      {value || defaultDate.toISOString().split('T')[0]}
    </div>
  );

  const renderApplyToSelect = (rowId: string, value: string) => (
    <Select
      value={value || 'Custodian'}
      onChange={(selectedValue) => handleApplyToChange(rowId, selectedValue || 'Custodian')}
      data={[
        { value: 'Custodian', label: 'Custodian' },
        { value: 'Atlantis', label: 'Atlantis' },
      ]}
      className="calendar-recon"
      styles={{ dropdown: { backgroundColor: disableEdit ? 'white' : undefined } }}
      disabled={disableEdit}
    />
  );

  const columnDefs: ColDef[] = [
    ...(noCheck
      ? []
      : [{
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      }]),
    ...tableData.map((row) => {
      const decorators = row.decorators;
      if (isAdjustmentPage) {
        if (row.key === 'start_date_sod') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0, },
            cellRenderer: (params: ICellRendererParams) => {
              const index = params.rowIndex;
              return renderEditableDateField(rowData[index].id || '', startDates[rowData[index].id], true);
            },
            suppressRowClickSelection: true,
            filter: 'agTextColumnFilter',
          };
        }

        if (row.key === 'end_date_sod') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0 },
            cellRenderer: (params: ICellRendererParams) => {
              const index = params.rowIndex;
              return renderEditableDateField(rowData[index].id || '', endDates[rowData[index].id], false);
            },
            suppressRowClickSelection: true,
            filter: 'agTextColumnFilter',
          };
        }

        if (row.key === 'apply_to_sod') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0 },
            cellRenderer: (params: ICellRendererParams) =>
              renderApplyToSelect(params.node?.id || '', applyTo[params.node?.id || '']),
            suppressRowClickSelection: true,
            filter: 'agTextColumnFilter',
          };
        }
      }

      return {
        headerName: t(row.colHeader),
        field: t(row.key),
        valueFormatter: ({ value }: ValueFormatterParams) => applyDecorators(t(value), decorators),
        ...(row.key === 'work_item_status' ? { sort: 'asc' as 'asc' } : {}),
        filter: 'agTextColumnFilter',
      };
    }),
  ];


  useEffect(() => {
    setRowData(Array.isArray(data) ? data : []);
  }, [data]);

  const onSelectionChanged = (event: any) => {
    if (noCheck) return;
    const selectedRows = event.api.getSelectedRows();

    if (onSelectionChange) {
      if (!allowMultipleSelection && selectedRows.length > 1) {
        event.api.deselectAll();
        event.api.getRowNode(selectedRows[selectedRows.length - 1].id).setSelected(true);
      }
      onSelectionChange(selectedRows);
    }
  };

  const noRowsOverlayComponentParams = useMemo(
    () => ({
      noRowsMessageFunc: () => `- ${t('NO_WORK_ITEMS_TO_SHOW')} -`,
    }),
    []
  );

  return (
    <Box>
      <Box
        className="action-item ag-theme-quartz"
        style={{
          height: rowData.length > 1 ? `${1.876 + rowData.length * 1.9 + 0.7}rem ` : '4.5rem',
          width: '100%',
          maxHeight: '23.125rem',
          minHeight: '4rem',
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          rowSelection={rowSelection}
          rowHeight={30}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={handleRowDoubleClick}
          noRowsOverlayComponent={CustomNoRowsOverlay}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          suppressRowHoverHighlight={true}
        />

      </Box>
    </Box>
  );
};

export default SodReconTableComplete;