import React from "react";
import { Box, Flex, useMantineTheme } from "@mantine/core";
import { EquitizeTableProps } from "../../types";
import CustomTable from "../CustomTable/CustomTable";

const EquitizeTable: React.FC<EquitizeTableProps> = ({ title, rowData, columnDefs }) => {
	const theme = useMantineTheme();
	const getRowStyle = (params: { node: { rowIndex: number } }) => {
		const isLastRow = params.node.rowIndex === rowData.length - 1;
		if (
			(title === "SpendableCashTable" ||
				title === "MarginCashTable" ||
				title === "AccuredCashTable") &&
			isLastRow
		) {
			return { backgroundColor: theme.colors.grey[0] };
		}
		return undefined;
	};

	return (
		<Flex justify={"center"}>
			<Box className='equitize-table'>
				<CustomTable noHeight rowData={rowData} columnDefs={columnDefs} rowHeight={38} domLayout='autoHeight' getRowStyle={getRowStyle} className="fund-query"/>
			</Box>
		</Flex>
	);
};

export default EquitizeTable;
