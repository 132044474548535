import React, { useEffect } from "react";
import { Box, Flex } from "@mantine/core";
// import { notifications } from "@mantine/notifications";
import { MainChatArea } from "./MainChatArea";
import { CategorizedChats, ChatHistoryItem, ChatSession } from "../../types";
import ExploreTab from "./ExploreTab";
import PageTitle from "../Common/PageTitle";
import AIChatNavBar from "./AIChatNavBar";
import { t } from "i18next";
import { useChatContext } from "../../contexts/ChatContext";

interface ChatLayoutProps {
	currentSession: ChatSession;
	chatHistory: ChatSession[];
	categorizedChats: CategorizedChats;

	input: string;
	// categorizedChats: {
	// 	today: ChatHistoryItem[];
	// 	yesterday: ChatHistoryItem[];
	// 	last7Days: ChatHistoryItem[];
	// };
	isLoading: boolean;
	error: string | null;
}

export const ChatLayout: React.FC<ChatLayoutProps> = ({
	currentSession,
	chatHistory,
	input,
	categorizedChats,
	isLoading,
	error,
}) => {
	const {
		selectedItem,
		isLeftNavOpen,
		toggleSidebar,
		handleSendMessage,
		handleKeyPress,
		handleSetInput,
		handleStartNewChat,
		handleLoadChatSession,
		setSelectedItem,
	} = useChatContext();

	useEffect(() => {
		if (error) {
			// notifications.show({
			// 	color: "red",
			// 	title: "Error",
			// 	message: error,
			// 	autoClose: 3000,
			// });
		}
	}, [error]);

	const renderContent = () => {
		if (selectedItem === "explore") {
			return <ExploreTab />;
		}

		if (selectedItem === "new chat" || chatHistory.find((chat) => chat.id === selectedItem)) {
			return (
				<MainChatArea
					messages={currentSession.messages}
					input={input}
					setInput={handleSetInput}
					handleKeyPress={handleKeyPress}
					onSendMessage={handleSendMessage}
					isLoading={isLoading}
				/>
			);
		}

		return <div>No content available</div>;
	};

	return (
		<Box h={100}>
			<PageTitle TITLE={t("AI_ASSISTANT")} />
			<Flex className='preset-wrapper' direction='row'>
				<AIChatNavBar
					categorizedChats={categorizedChats} 
					onNewChat={handleStartNewChat}
					onLoadChat={handleLoadChatSession}
					isLeftNavOpen={isLeftNavOpen}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					toggleSidebar={toggleSidebar}
				/>
				<div className='flex grow flex-column h-screen overflow-hidden main-content'>
					{renderContent()}
				</div>
			</Flex>
		</Box>
	);
};
