// LanguageDropdown.tsx
import React from "react";
import { CustomSelectBox } from "./Select/CustomSelectBox";
import i18n from "../../i18n";

export const LanguageDropdown: React.FC = () => {

	const handleLanguageChange = (lang: string) => {
		sessionStorage.setItem('lang', lang)
		i18n.changeLanguage(lang);
	};

	return (
		<>
			<CustomSelectBox
				data={[
					{ value: "en", label: "English" },
					{ value: "jp", label: "Japanese" },
					{ value: "fr", label: "French" },
					{ value: "de", label: "German" },
				]}
				placeholder={"Select Language"}
				onChange={(value) => handleLanguageChange(value as string)}
				className='language-select'
			/>
		</>
	);
};

