import { FC, useEffect, useRef, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "./Orderstyles.css";
import { useMantineTheme, Text, Box, Tooltip } from "@mantine/core";
// import "../Agtablehomepage/Agstyles.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { OrderTableProps, OrderTableRowProps } from "../../types";
import { routes } from "../../routes";
import ErrorMessage from "../Common/ErrorMessage";
import IconWrapper from "../Common/IconWrapper";
import { services } from "../../services";
import { hasWritePermission } from "../../utils/permissionUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ordersDecorator, columnWidth } from "./OrderTableUtils";
import { applyDecorators, directPercentage, getConfig, getTableData } from "../../utils/conversions";
import CustomAlertModal from "../Common/CustomAlertModal";
import CustomTable from "../CustomTable/CustomTable";
import { rowHeightLarge } from "../CustomTable/utilsAgTable";
import { LoaderIcon } from "../Common/LoaderIcon";
import OrderButton from "../Common/Buttons/OrderButton";
import { ConstantUtils } from "../../utils/constantUtils";

export const OrderTable: FC<OrderTableProps> = ({
	orders,
	TITLE,
	initialTotalBuyShares,
	initialTotalSellShares,
	initialTotalBuyAmountInMillions,
	initialTotalSellAmountInMillions,
	initialTotalBuyPct,
	initialTotalSellPct,
	initialOrderListID,
	shouldDisplayButton,
}) => {
	const { ordersList, countBuy, countSell } = ordersDecorator({
		orders,
		initialTotalSellAmountInMillions: initialTotalSellAmountInMillions || 0,
		initialTotalBuyAmountInMillions: initialTotalBuyAmountInMillions || 0,
	});
	orders = ordersList;
	const { t, i18n } = useTranslation();
	const theme = useMantineTheme();
	const [languageKey, setLanguageKey] = useState(i18n.language);
	const [orderData, setOrderData] = useState<any>(null);
	const [searchParams] = useSearchParams();
	const disabled = searchParams.get("disabled") == "true" ? true : false;
	const { fund_id, transaction_id, target_mode, target_date, fund_state, fund_group, calc_date } =
		useParams();
	const navigate = useNavigate();
	const fundGroupData = JSON.parse(localStorage.getItem("fundGroup") || "0");
	const orderLimit = fundGroupData?.[0]?.orderLimit ?? "0";
	const orderLimitCheck =
		Math.abs(
			Math.abs(initialTotalBuyAmountInMillions || 0) -
			Math.abs(initialTotalSellAmountInMillions || 0),
		) <= orderLimit;
	const check_fund_group = fund_group || " ";
	const hasPermission = hasWritePermission(check_fund_group, "approve");
	const checkPermission = hasPermission && orderLimitCheck;
	const hasPermissionWrite = hasWritePermission(check_fund_group, "write");
	const systemConfig = getConfig("system");
	const orderConfig = getConfig("order");
	const selected = "Completed";
	const [loading, setLoading] = useState(false);
	const [buttonText, setButtonText] = useState("I_M_STARTING");
	const POLLING_INTERVAL = Number(process.env.POLLING_INTERVAL) || 5000;
	const MAX_ATTEMPTS = Number(process.env.MAX_POLLING_ATTEMPTS) || 10;
	const [selectedRow, setSelectedRow] = useState<OrderTableRowProps[]>([]);
	const rowData = useMemo(() => orders || [], [orders]);

	const isOption1 = +(orderConfig?.DISPLAY_OPTION?.value || 1) === 1;

	useEffect(() => {
		if ((disabled === false || disabled === true) && !checkPermission) {
			setButtonText("CANNOT_APPROVE");
		} else if (disabled === false) {
			setButtonText("APPROVE_ORDERS");
		}
	}, [disabled]);

	const onGridReady = (params: any) => {
		gridApiRef.current = params.api;
	};
	const gridApiRef = useRef<any>(null);

	const onSelectionChanged = () => {
		const selectedRows = gridApiRef.current?.getSelectedRows() || [];
		setSelectedRow(selectedRows);
	};

	useEffect(() => {
		if (gridApiRef.current) {
			gridApiRef.current.forEachNode(
				(node: { data: { asset_name: any }; setSelected: (arg0: boolean) => void }) => {
					const isSelected = selectedRow.some((row) => row.asset_name === node.data.asset_name);
					node.setSelected(isSelected);
				},
			);
		}
	}, [orderData, selectedRow]);
	const fetchOrder = async (attempt = 1, transaction_id?: string): Promise<boolean> => {
		try {
			const uuid = localStorage.getItem("uuid");

			let params: any = {
				fund_id: fund_id,
				target_date: target_date,
				target_mode: target_mode,
				calc_date: calc_date,
			};

			if (transaction_id) {
				params['transaction_id'] = transaction_id;
			}
			
			const response = await axios.get(services.ORDER_LIST, {
				params: params,
			});
			
			
			if (response.data.data) {
				if (transaction_id && response.data.message == 'Data Found') {
					return true;
				}
				const filteredOrders = response.data.data.orders.filter(
					(order: any) => !["Approved", "Executed"].includes(order.order_status),
				);
				setOrderData({ ...response.data.data, orders: filteredOrders });
				return true;
			}
		} catch (error: any) {
			console.error("Error making API request:", error);
		}
		if (attempt < MAX_ATTEMPTS) {
			return new Promise((resolve) => {
				setTimeout(async () => {
					const success = await fetchOrder(attempt + 1, transaction_id);
					resolve(success);
				}, POLLING_INTERVAL);
			});
		} else {
			console.log("Max attempts reached. No Data Found");
			return false;
		}
	};

	const executeOrderClaimAPI = async () => {
		setLoading(true);
		const url = `${services.ORDERCLAIM}?fund_id=${fund_id}`;
		const requestData = {
			fund_id: fund_id,
			target_date: target_date,
			target_mode: target_mode,
			event_type: "ORDER_CLAIM",
			calc_date: calc_date,
			transaction_id,
		};

		const makeApiRequest = async () => {
			try {
				const response = await axios.post(url, requestData);
				return response.data;
			} catch (error) {
				console.error("Error making API request:", error);
				return null;
			}
		};

		const checkOrderClaimStatus = async (uuid: string, retryCount = 0) => {
			try {
				const response = await axios.get(
					`${services.ORDERCLAIMSTATUS}?fund_id=${fund_id}&target_date=${target_date}&transaction_id=${transaction_id}&target_mode=${target_mode}`,
				);
				const orderClaimStatus = response.data.data.response_type;

				if (orderClaimStatus === "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS") {
					handleSuccess();
					return;
				}

				if (orderClaimStatus === "PENDING") {
					handlePending(uuid, retryCount);
					return;
				}

				handleFailure(orderClaimStatus);
			} catch (error) {
				handleError(error);
			}
		};

		const handleSuccess = () => {
			console.log("Order claim successful.");
			setLoading(false);
			setButtonText("APPROVE_ORDERS");
		};

		const handlePending = (uuid: string, retryCount: number) => {
			console.log("Order claim is pending, retrying...");
			if (retryCount < MAX_ATTEMPTS) {
				setTimeout(() => checkOrderClaimStatus(uuid, retryCount + 1), POLLING_INTERVAL);
			} else {
				console.error("Max retry attempts reached for order claim status check.");
				toast.error(t("REFRESH_NEEDED"));
				setLoading(false);
				setButtonText("I_M_STARTING");
			}
		};

		const handleFailure = (orderClaimStatus: string) => {
			console.error("Order update failed:", orderClaimStatus);
			toast.error(t("ORDER_UPDATE_MESSAGE"));
			setLoading(false);
			setButtonText("I_M_STARTING");
		};

		const handleError = (error: any) => {
			console.error("Error checking order claim status:", error);
			toast.error(t("ORDER_UPDATE_MESSAGE"));
			setLoading(false);
			setButtonText("I_M_STARTING");
		};

		const data = await makeApiRequest();
		if (data && data.api_status === "success") {
			const uuid = data.uuid_str;
			localStorage.setItem("uuid", uuid);
			checkOrderClaimStatus(uuid);
		} else {
			console.error("Failed to initiate order claim.");
			setLoading(false);
			toast.error("Max attempts reached. Refresh needed.");
			setButtonText("I_M_STARTING");
		}
	};

	const fetchOrderApprovalStatus = async (uuid: string, attempt = 1): Promise<boolean> => {
		try {
			
			let params = {
				fund_id: fund_id,
				target_mode: target_mode,
				target_date: target_date,
				calc_date: calc_date,
				transaction_id: uuid,
			}

			const response = await axios.get(services.ORDER_APPROVAL_STATUS, {
				params: params,
			});

			const response_type = response.data.response_type;

			if (response_type === ConstantUtils.orderApprovalStatus.orderApprovalSuccess) {
				return true;
			}
			if (response.data.response_type === ConstantUtils.orderApprovalStatus.orderApprovalFail) {
				return false;
			}

		} catch (error: any) {
			console.error("Error making API request:", error);
		}
		if (attempt < MAX_ATTEMPTS) {
			return new Promise((resolve) => {
				setTimeout(async () => {
					const success = await fetchOrderApprovalStatus(uuid, attempt + 1);
					resolve(success);
				}, POLLING_INTERVAL);
			});
		} else {
			console.log("Max attempts reached. No Data Found");
			return false;
		}
	};

	const handleApproveOrders = async () => {
		try {
			setLoading(true);
			const url = `${services.ORDERAPPROVAL}?fund_id=${fund_id}`;
			const response = await axios.post(url, {
				fund_id: fund_id,
				target_date: target_date,
				asset_type: "Fund",
				asset: "Fund",
				target_mode: target_mode,
				transaction_id,
				order_list_ids: initialOrderListID,
				calc_date: calc_date,
			});

			const uuid = response.data.uuid;
			localStorage.setItem("uuid", uuid);



			if (response.data.data === "PENDING") {
				const successOfOrderApproval = await fetchOrderApprovalStatus(uuid);
				const successOfOrderList = await fetchOrder(1, uuid);
				if (!successOfOrderApproval || !successOfOrderList) {
					setLoading(false);
					toast.error("Max attempts reached. No Data Found");
					return;
				}
				const navigateUrl = `${routes.FUNDSDATA}/${fund_id}/${response.data.uuid}/${target_mode}/${selected}/${target_date}/${fund_group}/${calc_date}?disabled=true`;
				navigate(navigateUrl);
				window.location.reload();
			} else {
				console.log("Fail");
				toast.error(t("FAIL"));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleButtonClick = () => {
		const approvePermission = hasWritePermission(check_fund_group, "approve");
		if (buttonText === "APPROVE_ORDERS" && approvePermission) {
			setIsModalOpen(true);
		} else if (disabled) {
			const hasPermission = hasWritePermission(check_fund_group, "write");
			if (hasPermission) {
				executeOrderClaimAPI();
			}
		} else {
			setButtonText(approvePermission ? "APPROVE_ORDERS" : "CANNOT_APPROVE");
		}
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleSubmit = () => {
		handleApproveOrders();
		setIsModalOpen(false);
	};

	useEffect(() => {
		setLanguageKey(i18n.language);
	}, [i18n.language]);


	if (!orders || orders.length === 0) {
		return (
			<div>
				<ErrorMessage errorMessage='NO_DATA_AVAILABLE' />
			</div>
		);
	}

	const orderTableColumnsInSortedOrder = getTableData("order");

	const colDefs: ColDef[] = [
		{
			checkboxSelection: (params) => shouldDisplayButton,
			headerName: "",
			width: 40,
			suppressSizeToFit: true,
			headerClass: "custom-header-style",
		},

		...orderTableColumnsInSortedOrder.map(({ key, colHeader, decorators }) => {
			const isRightAligned = [
				"price",
				"quantity",
				"amount",
				"amount_percentage",
				"average_daily_volume",
				"order_as_adv_percentage",
			].includes(key);

			const columnHeaders: ColDef = {
				headerName: t(colHeader),
				wrapHeaderText: true,
				headerClass: `custom-header-style ${isRightAligned ? "ag-right-aligned-header" : ""}`,
				field: key,
				cellClass: isRightAligned ? "right-aligned" : "",
				width: columnWidth[key] || columnWidth.default,
				filter: "agTextColumnFilter",
			};

			columnHeaders.valueFormatter = ({ value }): string => applyDecorators(value, decorators);
			return columnHeaders;
		}),
	];

	const tableContainerStyle = {
		width: "100%",
	};

	const isButtonDisabled =
		buttonText === "CANNOT_APPROVE" || (!hasPermissionWrite && !checkPermission);

	return (
		<I18nextProvider i18n={i18n}>
			{loading ? (
				<LoaderIcon name='loading-large' alt='loading icon' />
			) : (
				<>
					{fund_state !== "Completed" && (
						<div className='flextableheaders'>
							{isOption1 && (
								<div className='orderheader'>
									<Text size={theme.fontSizes.xl} fw={700} pt={"1.2rem"} pb={"1rem"}>
										{t(TITLE)}
									</Text>
								</div>
							)}
							{TITLE !== "List of completed orders" && (
								<div className='order-actions analysis-order'>
									{
										shouldDisplayButton ? (
											selectedRow.length <= 0 ? (
												<>
													<IconWrapper name='edit-icon-disabled' />
													<IconWrapper name='delete-icon-disabled' />
													<IconWrapper name='back-icon-disabled' />
												</>
											) : (
												<>
													<IconWrapper name='edit-icon-enabled' />
													<IconWrapper name='delete-icon-enabled' />
													<IconWrapper name='back-icon-enabled' />
												</>
											)
										) : null
									}

									{shouldDisplayButton && (
										<OrderButton
											buttonText={buttonText}
											isButtonDisabled={isButtonDisabled}
											handleButtonClick={handleButtonClick}
											t={t}
										/>
									)}

									<CustomAlertModal
										opened={isModalOpen}
										onClose={handleCloseModal}
										TITLE='APPROVE_ORDERS'
										message='Would you like to approve all the orders?'
										onSubmit={handleSubmit}
										buttonName='Approve'
									/>
								</div>
							)}
						</div>
					)}

					<Box className={fund_state === "Completed" ? 'container full-width' : 'container'} mt={fund_state === "Completed" ? "1.15rem" : ""}>
						<div
							className='orderlabel'
							style={{ backgroundColor: isOption1 ? theme.colors.violet[0] : theme.colors.grey[1] }}
						>
							<div className='buylabel'>
								{isOption1 ? (
									<>
										<IconPlus color={theme.colors.grey[8]} />
										<Text style={{ color: theme.colors.grey[8] }}>{t("TOTAL_BUY")}</Text>
									</>
								) : (
									<>
										<Text style={{ color: theme.colors.grey[8] }} fw={700} mr={"xxs"}>
											{countBuy}
										</Text>
										<Text style={{ color: theme.colors.grey[8] }} fw={700}>
											{t("BUYS")}
										</Text>
									</>
								)}
							</div>
							<div className='shareflex'>
								<Text className='shareLabel'>
									{applyDecorators(initialTotalBuyShares, systemConfig?.QUANTITY?.decorators)}
								</Text>
								<Text className='lowerLabel' style={{ color: theme.colors.grey[8] }}>
									{` ${t("SHARE")}`}
								</Text>
							</div>
							<div className='shareflex'>
								<Tooltip
									label={applyDecorators(
										initialTotalBuyAmountInMillions,
										systemConfig?.CURRENCY?.decorators,
									)}
								>
									<Text className='shareLabel'>
										{applyDecorators(
											initialTotalBuyAmountInMillions,
											systemConfig?.CURRENCY_2?.decorators,
										)}
									</Text>
								</Tooltip>
								<Text className='lowerLabel' style={{ color: theme.colors.grey[8] }}>
									{` ${t("IN_USD")}`}
								</Text>
							</div>
							<div className='shareflex'>
								<Text className='shareLabel'>
									{directPercentage(Number(initialTotalBuyPct))}
								</Text>
								<Text className='lowerLabel' style={{ color: theme.colors.grey[8] }}>
									{` ${t("AMOUNT")} %`}
								</Text>
							</div>
						</div>

						<div
							className='orderlabel'
							style={isOption1 ? {} : { backgroundColor: theme.colors.grey[1] }}
						>
							<div className='buylabel'>
								{isOption1 ? (
									<>
										<IconMinus />
										<Text style={{ color: theme.colors.grey[8] }}>{t("TOTAL_SELL")}</Text>
									</>
								) : (
									<>
										<Text style={{ color: theme.colors.grey[8] }} fw={700} mr={"xxs"}>
											{countSell}
										</Text>
										<Text style={{ color: theme.colors.grey[8] }} fw={700}>
											{t("SELLS")}
										</Text>
									</>
								)}
							</div>
							<div className='shareflex'>
								<Text className='shareLabel'>
									{applyDecorators(initialTotalSellShares, systemConfig?.QUANTITY?.decorators)}
								</Text>
								<Text className='lowerLabel'>{` ${t("SHARE")}`}</Text>
							</div>
							<div className='shareflex'>
								<Tooltip
									label={applyDecorators(
										initialTotalSellAmountInMillions,
										systemConfig?.CURRENCY?.decorators,
									)}
								>
									<Text className='shareLabel'>
										{initialTotalSellAmountInMillions && initialTotalSellAmountInMillions > 0
											? `-${applyDecorators(
												initialTotalSellAmountInMillions,
												systemConfig?.CURRENCY_2?.decorators,
											)}%`
											: applyDecorators(
												initialTotalSellAmountInMillions ?? 0,
												systemConfig?.CURRENCY_2?.decorators,
											)}
									</Text>
								</Tooltip>
								<Text className='lowerLabel'>{` ${t("IN_USD")}`}</Text>
							</div>
							<div className='shareflex'>
								<Text className='shareLabel'>
									{directPercentage(Number(initialTotalSellPct))}
								</Text>
								<Text className='lowerLabel'>{` ${t("AMOUNT")} %`}</Text>
							</div>
						</div>
					</Box>

					<div style={tableContainerStyle}>
						{orders.length > 0 ? (
							<Box>
								<CustomTable rowData={rowData} columnDefs={colDefs} rowHeight={rowHeightLarge} maxConcurrentDatasourceRequests={1}
									maxBlocksInCache={1} onSelectionChanged={onSelectionChanged}
									onGridReady={onGridReady} getRowId={(params: { data: { asset_name: string; }; }) => params.data.asset_name}
									className="test" largeHeader maxHeight="67vh" />
							</Box>
						) : (
							<div> </div>
						)}
					</div>
				</>
			)}
			<ToastContainer autoClose={5000} />
		</I18nextProvider>
	);

};
