import { useState, useEffect } from "react";

interface Document {
	name: string;
}

interface FolderOption {
	value: string;
	label: string;
	documents: Document[];
}

export const useFolderOptions = () => {
	const [folderOptions, setFolderOptions] = useState<FolderOption[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFolderName, setSelectedFolderName] = useState<string>("");

	const fetchFolderOptions = async (): Promise<void> => {
		setIsLoading(true);
		try {
			const storedPresets = JSON.parse(localStorage.getItem("presetName") || "[]");
			const storedFolders = localStorage.getItem("folderName");
			const hasFavorites = storedPresets.some(
				(folder: { _id: string }) => folder._id === "Favorites",
			);
			if (!hasFavorites) {
				storedPresets.unshift({
					_id: "Favorites",
					documents: [],
					message: "Default Favorites folder",
				});
			}

			const presetOptions = storedPresets
				.map((preset: { _id: string; documents?: Document[] }) => {
					const id = String(preset._id);

					return {
						value: id,
						label: id === "Untitled Folder" ? "Favorites" : id,
						documents: preset.documents || [],
					};
				})
				.filter((option: { value: string }) => option.value !== "null");

			if (storedFolders) {
				const folderName = String(storedFolders);
				presetOptions.push({
					value: folderName,
					label: folderName,
					documents: [],
				});
			}

			setFolderOptions(presetOptions);

			const favoritesOption = presetOptions.find(
				(option: { value: string }) => option.value === "Favorites",
			);
			setSelectedFolderName(favoritesOption?.value || "");
		} catch (error) {
			console.error("Failed to fetch presets or folders:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchFolderOptions();
	}, []);

	const checkDuplicatePreset = (presetName?: string, folderName?: string): boolean => {
		if (!presetName || !folderName) return false;

		const folder = folderOptions.find((option) => option.value === folderName.trim());
		if (!folder || !folder.documents) return false;

		return folder.documents.some((doc) => {
			const docName = doc.name?.trim()?.toLowerCase();
			const presetNameLower = presetName?.trim()?.toLowerCase();
			return docName === presetNameLower;
		});
	};

	return {
		folderOptions,
		isLoading,
		selectedFolderName,
		setSelectedFolderName,
		fetchFolderOptions,
		checkDuplicatePreset,
	};
};
