import React from "react";
import { Text } from "@mantine/core";
import CustomBadge from "./CustomBadge";

interface SectionHeaderProps {
	title: string;
	count?: number;
	hideCount?: boolean;
	id?: string; 
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, count, hideCount, id }) => {
	return (
		<div className='section-header'>
			<Text fw={700} size='sm'>
				{title}
			</Text>
			{!hideCount && count !== undefined && (
				<CustomBadge
					count={count}
					className='header-count-badge'
					data-testid={id ? `count-${id}` : undefined} 
				/>
			)}
		</div>
	);
};

export default SectionHeader;
