import { useEffect, useRef } from "react";
import { useAppDispatch } from "../../store/hooks";

type Props = {
	callback: any;
	type: "thunk" | "func";
};

export const useInterval = ({ callback, type }: Props) => {
	const savedCallback = useRef<any | null>(null);
	const fetchCount = useRef(1);
	const dispatch = useAppDispatch();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		//silently fetch data after every 6s
		const intervalId = setInterval(() => {
			// console.log('isVisible ', !document.hidden);
			if (!document.hidden) {
				// console.log('fetch ', fetchCount.current)
				fetchCount.current++;
				if (savedCallback.current) {
					if (type === "thunk") dispatch(savedCallback.current);
					else savedCallback.current();
				}
			}
		}, POLLING_INTERVAL);

		return () => {
			clearInterval(intervalId);
		};
	}, [document.hidden, callback, POLLING_INTERVAL]);
};

export const POLLING_INTERVAL = Number(process.env.POLLING_INTERVAL) || 5000;
export const ORDER_POLLING_INTERVAL = Number(process.env.POLLING_INTERVAL) || 60000;
export const MAX_ATTEMPTS = Number(process.env.MAX_POLLING_ATTEMPTS) || 10;
