import React, { ReactElement } from "react";
import { Grid, Text, Box, Flex } from "@mantine/core";

interface FormFieldProps {
	label: string;
	input: ReactElement | ReactElement[];
	inputProps?: Record<string, any> | Record<string, any>[];
	labelColSpan?: number;
	inputColSpan?: number;
	mt?: string | number;
	mb?: string | number;
	pt?: string | number;
	pb?: string | number;
}

const FormField: React.FC<FormFieldProps> = ({
	label,
	input,
	inputProps = {},
	labelColSpan = 10,
	inputColSpan = 10,
	mt = "0",
	pb = "0",
	mb = "0",
	pt = "0",
}) => {
	const isMultipleInputs = Array.isArray(input);
	const inputPropsArray = Array.isArray(inputProps) ? inputProps : [inputProps];

	return (
		<>
			<Grid.Col
				span={labelColSpan}
				mt={0}
				mb={0}
				pt={10}
				pb={6}
				style={{ paddingTop: pt, paddingBottom: pb, marginTop: mt, marginBottom: mb}}
			>
				<Text className='labelfont' size='sm'>
					{label}
				</Text>
			</Grid.Col>

			<Grid.Col
				span={inputColSpan}
				style={{ paddingTop: pt, paddingBottom: pb, marginTop: mt, marginBottom: mb, width: "100" }}
			>
				{isMultipleInputs ? (
					<Flex className='flex-inputs-container'>
						{input.map((singleInput, index) => (
							<Box key={index} style={{ flex: 1 }}>
								{React.cloneElement(singleInput, inputPropsArray[index] || {})}
							</Box>
						))}
					</Flex>
				) : (
					React.cloneElement(input as ReactElement, inputProps as Record<string, any>)
				)}
			</Grid.Col>
		</>
	);
};

export default FormField;
