import React from "react";
import { Select, SelectProps } from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import '../../AllFundQuery/SavePresetModal.css'

export const CustomSelectBox = (props: SelectProps) => {
  const rightSection = props.rightSection ?? (
    <IconSelector color="black" />
  );

  const { rightSectionPointerEvents, ...restProps } = props;

  return (
    <Select
      data-testid ="custom-select"
      size="xs"
      className="savepreset-modal"
      variant="transparent"
      rightSection={rightSection}
      comboboxProps={{ offset: 0 }}
      checkIconPosition="right"
      rightSectionPointerEvents="none"
      {...restProps}
    />
  );
};