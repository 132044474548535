import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Data } from "../../types";
import { services } from "../../services";

interface Document {
	id: string;
	name: string;
}
export const useFetchData = () => {
	const [data, setData] = useState<Data[]>([]);
	const [storedFolders, setStoredFolders] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		localStorage.removeItem("folderName");
		const storedFolderNames = Object.keys(localStorage)
			.filter((key) => key.startsWith("folderName"))
			.map((key) => localStorage.getItem(key) || "");
		setStoredFolders(storedFolderNames);

		try {
			const response = await axios.get(services.GET_PRESETS);
			const { data } = response.data;

			if (response.data.api_status === "success") {
				let processedData = data.map((item: { documents: Document[] }) => ({
					...item,
					documents: item.documents || [],
				}));

				const favoritesFolder = processedData.find(
					(folder: { _id: string }) => folder._id === "Favorites",
				);
				if (favoritesFolder) {
					processedData = processedData.filter(
						(folder: { _id: string }) => folder._id !== "Favorites",
					);
					processedData.sort((a: { _id: string }, b: { _id: string }) =>
						a._id.localeCompare(b._id),
					);
					processedData.unshift(favoritesFolder);
				} else {
					processedData.unshift({
						_id: "Favorites",
						documents: [],
						message: "Default Favorites folder",
					});
				}

				setData(processedData);
				localStorage.setItem("presetName", JSON.stringify(processedData)); // Persist the data
			} else {
				console.error("API error:", data.message);
			}
		} catch (error) {
			console.error("Fetch error:", error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return { data, setData, storedFolders, fetchData, isLoading };
};
