import { useEffect, useState } from "react";
import axios from "axios";
import { services } from "../../services";
import { getCurrentUTCDate } from "../dateUtils";

export interface Order {
	sr_no: number;
	valuation_point: string;
	fund_id: string;
	fund_name: string;
	bloomberg_ticker: string;
	asset_id: string;
	asset_name: string;
	order_status: string;
	transaction_type: string;
	price_currency: string;
	quantity: number;
	price: number;
	trade_date: string;
	sett_date: string;
	order_type: string;
}

export const useOrders = (fundGroup: string) => {
	const [ordersData, setOrdersData] = useState<Order[]>([]);
	const [ordersError, setOrdersError] = useState<string | null>(null);

	const fetchOrders = async () => {
		try {
			const response = await axios.get(services.ORDERS, {
				params: {
					fund_group: fundGroup,
					type: "Orders",
					start_date: getCurrentUTCDate(),
					end_date: getCurrentUTCDate(),
					settled: false,
					on_loan: false,
					total: false,
				},
			});
			const flattenedData: Order[] = response.data?.data.flatMap((fund: any, index:number) =>
				fund.orders
					? {
							...fund.orders,
							sr_no: index + 1,
							fund_name: fund.fund_name,
							bloomberg_ticker: fund.orders.asset_key?.bloomberg_ticker,
							asset_id: fund.orders.asset_key?.asset_id,
							// valuation_point: fund.orders.order_motivation,
							price_currency: "USD",
					  }
					: [],
			);
			setOrdersData(flattenedData);
			setOrdersError(null);
		} catch (error: any) {
			console.error("Error fetching orders:", error);
			setOrdersError(error.response?.status || "Error fetching orders");
		}
	};

	useEffect(() => {
		fetchOrders();
	}, [fundGroup]);

	return { ordersData, ordersError, fetchOrders };
};
