import React, { useEffect, useState } from "react";
import { Grid, Button, useMantineTheme, Flex, TextInput } from "@mantine/core";
import classes from "./LoginPage.module.css";
import "./LoginPage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { login } from "../../store/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import IconWrapper from "../../components/Common/IconWrapper";

const LoginPage: React.FC = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);
	const theme = useMantineTheme();
	const { authenticated, loading, errorMessage, error, loginCounter } = useAppSelector(
		(state) => state.user,
	);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [userIdPlaceholder, setUserIdPlaceholder] = useState("User ID");
	const [passwordPlaceholder, setPasswordPlaceholder] = useState("Password");

	useEffect(() => {
		if (authenticated) {
			navigate(location.state?.from || routes.HOME, { replace: true });
		}
	}, []);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleLogin();
		}
	};

	const handleLogin = () => {
		if (loginCounter >= 3) return; // return in case user tries to bypass login by enabling the button from local

		const isValid = username.trim() !== "" && password.trim() !== "";
		if (!isValid) {
			setLocalErrorMessage("Please enter a valid user id and password");
		} else {
			dispatch(login({ username, password, navigate }));
			setLocalErrorMessage(null);
		}
	};

	return (
		<Grid className={classes.gridstyles}>
			<div className={classes.carddiv} style={{ background: theme.colors.yellow[1] }}>
				<IconWrapper name='Logo-text' className='login-logo' />
				<Flex
					align='center'
					justify='space-between'
					direction='column'
					gap='14px'
					className='login-form'
				>
					<div className={classes.errormessagestyle}>
						{localErrorMessage ? localErrorMessage : error && errorMessage}
					</div>
					<TextInput
						placeholder={userIdPlaceholder}
						value={username}
						onChange={(event) => setUsername(event.currentTarget.value)}
						onKeyDown={handleKeyDown}
						onFocus={() => setUserIdPlaceholder("")}
						onBlur={() => setUserIdPlaceholder("User ID")}
						className='center-placeholder'
					/>

					<TextInput
						type='password'
						placeholder={passwordPlaceholder}
						value={password}
						onChange={(event) => setPassword(event.currentTarget.value)}
						onKeyDown={handleKeyDown}
						onFocus={() => setPasswordPlaceholder("")}
						onBlur={() => setPasswordPlaceholder("Password")}
						className='center-placeholder'
					/>
					<Button
						bg={theme.colors.violet[8]}
						c={theme.colors.yellow[0]}
						opacity={loginCounter >= 3 ? "0.4" : "unset"}
						radius='md'
						fullWidth
						h={"3.5rem"}
						size='1.25rem'
						fw={700}
						disabled={loading || loginCounter >= 3}
						onClick={handleLogin}
					>
						{loading ? (
							<Button
								loading
								loaderProps={{ type: "dots" }}
								bg={theme.colors.violet[8]}
								border-color={theme.colors.violet[8]}
							/>
						) : (
							"Log in"
						)}
					</Button>
				</Flex>
			</div>
		</Grid>
	);
};

export default LoginPage;
