import React from 'react';
import './ErrorPage.css';
import { Flex, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconReportSearch } from '@tabler/icons-react';
import CustomButton from '../Buttons/CustomButton';
import IconWrapper from '../IconWrapper';

interface ErrorMessageProps {
    errorCode?: number | string;
    errorMessage?: string;
}

const ErrorPage: React.FC<ErrorMessageProps> = ({ errorCode, errorMessage }) => {
    const theme = useMantineTheme();
    const { t } = useTranslation();

    let errorTitleKey = 'ERROR_DEFAULT';
    const errorMessageKeys: string[] = [];

    const errorMappings: Record<number, string[]> = {
        400: ['_1', '_2', '_3', '_4'],
        401: ['_1', '_2'],
        403: ['_1', '_2', '_3'],
        404: ['_1', '_2', '_3'],
        500: ['_1', '_2', '_3', '_4'],
    };

    const errorText =t("ERROR")

    if (errorCode && errorMappings[Number(errorCode)]) {
        errorTitleKey = `ERROR_${errorCode}`;
        errorMessageKeys.push(...errorMappings[Number(errorCode)].map(key => `ERROR_${errorCode}_MESSAGE${key}`));
    }

    return (
        <Flex direction="column" justify="center" align="center" gap="2.5rem">
            <Flex align="center" direction="column">
               <IconWrapper name='error-icon' />
            </Flex>
            <Flex align="center" direction="column" mb="3.5rem">
                <Text className="error-title">{t(errorMessage || errorTitleKey)}</Text>
                {errorMessageKeys.map((key, index) => (
                    <Text key={index} size="lg">
                        {t(key)}
                    </Text>
                ))}
                <Text size="lg">{t(`(${errorText} ${errorCode})`)}</Text>
            </Flex>
            <Flex direction="column" gap="xxl" justify="center" align="center">
                <CustomButton variant="refresh">REFRESH</CustomButton>
                <CustomButton variant="contact-support">CONTACT_SUPPORT</CustomButton>
            </Flex>
        </Flex>
    );
};

export default ErrorPage;
