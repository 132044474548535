import { useMemo } from "react";
import { CountsType } from "../../components/Leftnavbar/sectionData";

export const useComputedCounts = (counts: CountsType | undefined, counters: any): CountsType => {
	return useMemo(
		() =>
			counts || {
				marketOnOpenCount: counters?.market_on_open_counters?.total_funds_in_aggregate_count || 0,
				marketOnCloseCount: counters?.market_on_close_counters?.total_funds_in_aggregate_count || 0,
			},
		[counts, counters],
	);
};
